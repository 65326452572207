import React from 'react'

import Layout from '../Layout/Layout'
import SubMenu from './Components/SubMenu'

function UsersIndex () {
  return (
    <Layout>
      <SubMenu />

    </Layout>
  )
}

export default UsersIndex