import React, { useState } from 'react'

import { useGlobalContext } from '../Contexts/GlobalContext';
import { Spinner } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

function DeleteConfirmation ({ itemName, deleteHandler, dialogKey }) {
    const { disableBtn, setDisableBtn, deleteDialog, deleteDialogHandler } = useGlobalContext()

    const confirmDelete = () => {
        setDisableBtn(true)
        deleteHandler();
    }

    const footer = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" disabled={ disableBtn } onClick={ () => deleteDialogHandler(dialogKey) } />
            <Button label={ disableBtn ?
                <Spinner variant="white" as="span" animation="border" size="sm" role="status" className='mx-3' aria-hidden="true" />
                :
                "Yes"
            }
                icon="pi pi-checks" className="p-button-text p-component p-confirm-dialog-accept p-button-danger"
                disabled={ disableBtn ? true : false } onClick={ confirmDelete } />
        </>
    );

    return (
        <>
            <Dialog visible={ deleteDialog[dialogKey] }
                style={ { width: '450px' } }
                breakpoints={ { '960px': '95vw' } }
                header="Confirm" modal
                footer={ footer }
                onHide={ () => deleteDialogHandler(dialogKey) }>
                <div className="confirmation-content my-5">
                    <div className='text-center'>
                        <i className="pi pi-exclamation-triangle mr-3 d-block" style={ { fontSize: '2rem' } } />
                        <br />
                        <span>Are you sure you want to delete </span> <br /> <b>{ itemName } </b>?
                    </div>
                </div>
            </Dialog>
        </>


    )
}

export default DeleteConfirmation