import React, { useState, useEffect, useRef } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { Tooltip } from "primereact/tooltip";
import { Card } from "react-bootstrap";

import { useGlobalContext } from "../../../Contexts/GlobalContext";
import { useDeviceContext } from "../../../Contexts/DeviceContext";
import { useCustomToast } from "../../../Contexts/ToastContext";
import axiosInstance from "../../../../config/Axios";

import AssignCampaignsDropDown from "../CampaignAssignment/AssignCampaignsDropDown";
import ShowScheduleCampaign from "../CampaignAssignment/ShowScheduleCampaign";
import DeleteConfirmation from "../../../SharedComponents/DeleteConfirmation";
import GroupHeader from "./GroupHeader";
import DeviceCard from "./DeviceCard";
import Update from "../Update";

function Group ({ devicesGroup, setSelectedGroup }) {

    const { openDialog, dialogHandler, deleteDialog, deleteDialogHandler, setDisableBtn, setRerenderSubscription } = useGlobalContext();
    const { rerender, setLoading, setRerender, device, setCompanyCampaigns } = useDeviceContext();
    const { showToast } = useCustomToast();

    const didMountRef = useRef(false);

    // Assign to device dropdown option "campaigns"
    const [options, setoptions] = useState([]);
    const [columns, setColumns] = useState(devicesGroup);

    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
        } else {
        }
    }, [columns])

    useEffect(() => {
        if (!rerender)
            setColumns(devicesGroup)
    }, [rerender])

    //Fetch company campaigns  
    useEffect(() => {
        axiosInstance.get(`/company/campaigns`)
            .then((result) => {
                result.data.data.map(campaign => {
                    let option = {
                        name: campaign.name,
                        code: campaign.id,
                        duration: campaign.campaign_duration
                    }
                    setoptions(current => [...current, option])
                    setCompanyCampaigns(prev => ({ ...prev, [campaign.id]: campaign.campaign_duration }))
                })
            })
    }, [])

    const onDragEnd = (result, columns, setColumns) => {
        if (!result.destination) return;
        const { source, destination } = result;
        if (source.droppableId !== destination.droppableId) {

            const sourceColumn = columns[source.droppableId];
            const destColumn = columns[destination.droppableId];
            const sourceItems = [...sourceColumn.items];
            const destItems = [...destColumn.items];
            const [removed] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns,
                [source.droppableId]: { ...sourceColumn, items: sourceItems, },
                [destination.droppableId]: { ...destColumn, items: destItems, },
            });

            updateDeviceGroup(result.destination.droppableId, result.draggableId)

        } else {
            const column = columns[source.droppableId];
            const copiedItems = [...column.items];
            const [removed] = copiedItems.splice(source.index, 1);
            copiedItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns, [source.droppableId]: { ...column, items: copiedItems, },
            });
        }
    };

    const updateDeviceGroup = (groupID, deviceID) => {
        axiosInstance.put(
            `/device/update/group`,
            {
                "group_id": groupID == "undefined" ? "" : groupID,
                "device_id": deviceID
            }
        ).then((result) => {
            showToast('success', 'Group Updating', result?.data?.data?.msg);
        }).catch((errors) => {
            if (errors?.response?.data?.errors)
                Object.values(errors?.response?.data?.errors).forEach(error => {
                    showToast('error', 'Group Updating', error[0]);
                });
        });
    }

    const playPause = (groupID, action) => {

        axiosInstance.post(
            `/groups/play/pause`,
            {
                "group_id": groupID,
                "action": action,
            }
        ).then((result) => {
            showToast('success', 'Play-Pause Action', result?.data?.data?.msg);
        }).catch((error) => {
            showToast('error', 'Play-Pause Action', error.response?.data?.message);
        });
    }

    const assignSchedule = (groupID) => {
        setSelectedGroup(groupID);
        dialogHandler("assignDialog")
    }

    const deleteDevice = () => {
        axiosInstance.delete(`/device/${ device.id }`)
            .then(response => {
                setLoading(true)
                deleteDialogHandler("device");
                setRerenderSubscription(true)
                setRerender(true)
                showToast('success', 'Delete Device', response?.data?.data?.msg);
            });
        setDisableBtn(false)
    }

    return (
        <>
            <div className="d-flex flex-column justify-content-center w-100"  >
                <DragDropContext onDragEnd={ (result) => onDragEnd(result, columns, setColumns) } className="w-100" >
                    {
                        Object.entries(columns).map(([columnId, column], index) => {
                            return (
                                <div className="d-flex flex-column align-items-start " key={ columnId }>
                                    {/* Group name */ }
                                    {
                                        column.name == "default" ?
                                            <div className="d-flex w_20  px-3 pt-2 bg-white group_title justify-content-between align-items-center">
                                                <h2 className="fs-5 fw-bold ">{ column.name }</h2>
                                                <div>
                                                    {/* Play  */ }
                                                    <Tooltip target={ `.play-${ columnId }` } showDelay={ 100 } className="fs-8" />
                                                    <button className={ `btn btn-sm btn-icon play-${ columnId }` } data-pr-position="bottom" data-pr-tooltip={ `Play ` }
                                                        disabled={ column.items.length == 0 ? true : false }
                                                        onClick={ () => playPause(column.group_id, "play") }>
                                                        <i className='bx bx-play-circle text-light' ></i>
                                                    </button>
                                                    <box-icon name='image-add' ></box-icon>

                                                    {/* Pause  */ }
                                                    <Tooltip target={ `.pause-${ columnId }` } showDelay={ 100 } className="fs-8" />
                                                    <button className={ `btn btn-sm btn-icon pause-${ columnId }` } data-pr-position="bottom" data-pr-tooltip={ `Pause ` }
                                                        disabled={ column.items.length == 0 ? true : false }
                                                        onClick={ () => playPause(column.group_id, "pause") }>
                                                        <i className='bx bx-pause-circle text-light' ></i>
                                                    </button>

                                                    {/*assign schedule campaign  */ }
                                                    <Tooltip target={ `.assign-${ columnId }` } showDelay={ 100 } className="fs-8" />
                                                    <button className={ `btn btn-sm btn-icon assign-${ columnId }` } data-pr-position="bottom" data-pr-tooltip={ `assign schedule campaign ` }
                                                        disabled={ column.items.length == 0 ? true : false }
                                                        onClick={ () => assignSchedule(column.group_id) }>
                                                        <i className='bx bx-time-five text-light' ></i>
                                                    </button>

                                                </div>
                                            </div>
                                            :
                                            <GroupHeader
                                                deviceCount={ column.items.length }
                                                setLoading={ setLoading }
                                                assignSchedule={ assignSchedule }
                                                setRerender={ setRerender }
                                                groupName={ column.name }
                                                columnId={ columnId }
                                                groupID={ column.group_id }
                                                playPause={ playPause } />
                                    }

                                    <Droppable droppableId={ columnId } key={ columnId } group_id={ column.group_id } >
                                        {
                                            (provided, snapshot) => {
                                                return (
                                                    <Card { ...provided.droppableProps }
                                                        ref={ provided.innerRef }
                                                        className="p-3 bg-white w-100 mb-3 group_card"
                                                    >
                                                        {
                                                            column.items.length != 0 ?
                                                                column.items.map((device, index) => {
                                                                    return (
                                                                        <Draggable key={ `${ device.id }` } draggableId={ `${ device.id }` } index={ index } >
                                                                            { (provided, snapshot) => {
                                                                                return (
                                                                                    <div
                                                                                        ref={ provided.innerRef }
                                                                                        { ...provided.draggableProps }
                                                                                        { ...provided.dragHandleProps }
                                                                                        style={ {
                                                                                            userSelect: "none",
                                                                                            ...provided.draggableProps.style,
                                                                                        } }
                                                                                        className=" px-0 px-sm-2  ">

                                                                                        <DeviceCard
                                                                                            options={ options }
                                                                                            data={ device }
                                                                                            setRerender={ setRerender }
                                                                                            setLoading={ setLoading }
                                                                                        />

                                                                                    </div>
                                                                                );
                                                                            } }
                                                                        </Draggable>
                                                                    );
                                                                })

                                                                :
                                                                <div>No Devices add to this group</div>
                                                        }
                                                        { provided.placeholder }
                                                    </Card>
                                                );
                                            } }
                                    </Droppable>
                                </div>
                            );
                        }) }
                </DragDropContext>
            </div>

            {
                deleteDialog.device &&
                <DeleteConfirmation
                    deleteHandler={ deleteDevice }
                    itemName={ device.name ?? device.serial_name }
                    dialogKey="device"
                />
            }

            {
                openDialog.updateDevice
                && <Update
                    setLoading={ setLoading }
                    setRerender={ setRerender }
                    device={ device } />
            }

            {
                openDialog.assignCampaignsDropDown &&
                <AssignCampaignsDropDown
                    deviceID={ device.id }
                    options={ options } />
            }

            {
                openDialog.assignScheduleCampaigns &&
                <ShowScheduleCampaign
                    device={ device }
                    deviceID={ device.id }
                    options={ options }
                />
            }
        </>
    )
}

export default Group