import React from 'react'
import { useNavigate } from 'react-router-dom';

import sec1_img from "../../../assets/img/Landing/laptop_screen.png";
import screen1_img from "../../../assets/img/Landing/screen_1.png";
import screen2_img from "../../../assets/img/Landing/screen_2.png";
import screen3_img from "../../../assets/img/Landing/screen_3.png";
import circle from "../../../assets/img/Landing/circle_logo.png";

function Header () {
    const navigate = useNavigate()
    return (
        <section className="sec1">
            <div className="container h-100">
                <div className="row justify-content-center h-100 sec1-content">
                    <div className="col-md-5">
                        <div>
                            <h2 className="sec1-title">
                                Turn Any Screen to a Digital Signage!
                            </h2>
                            <p className="sec1-paragraph">
                                A scalable cloud-based digital signage and content management
                                software that puts you in control of your displays.
                            </p>
                            <button className="sec1-button" onClick={() => navigate("/login")}>Start Today!</button>
                        </div>
                    </div>
                    <div className="col-md-7 d-flex justify-content-center align-items-center position-relative">
                        <img src={ sec1_img } alt="image" className="sec1-img " />
                        <img src={ circle } alt="image" className="circle position-absolute" />
                        <img src={ screen1_img } alt="image" className="screen1_img position-absolute" />
                        <img src={ screen2_img } alt="image" className="screen2_img position-absolute" />
                        <img src={ screen3_img } alt="image" className="screen3_img position-absolute" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Header