import React from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { useForm, Controller } from "react-hook-form";

import { useTitle, getFormErrorMessage } from '../../config/global';

import '../../assets/vendor/css/pages/page-auth.css';
import landing from '../../assets/img/illustrations/boy-with-rocket-light.png';
import logo from '../../assets/img/logo.png';

import { useCustomToast } from '../Contexts/ToastContext';
import { InputText } from 'primereact/inputtext';
import axiosInstance from '../../config/Axios';

import { classNames } from 'primereact/utils';
import { Password } from 'primereact/password';

const Signup = () => {
    useTitle('Sign-up Page');

    const navigate = useNavigate();
    const { formState: { errors }, watch, handleSubmit, control } = useForm({ mode: 'all' });
    const { showToast } = useCustomToast();
    
    const onSubmit = (data) => {
        axiosInstance.post("/users/signup", data)
            .then((result) => {
                localStorage.setItem("email", result.data?.data?.email);
                navigate("/verify/email")
            }).catch((error) => {
                showToast('error', 'Sign-up Credential', error.response?.data?.message);
            });
    };

    return (
        <>
            <div className="authentication-wrapper authentication-cover">
                <div className="authentication-inner row m-0">
                    <div className="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center p-5">
                        <div className="w-100 d-flex justify-content-center">
                            <img src={ landing } className="img-fluid" width={ 700 } alt="landing" />
                        </div>
                    </div>
                    <div className="d-flex col-12 col-lg-5 col-xl-4 align-items-center authentication-bg p-sm-5 p-4">
                        <div className="w-px-400 mx-auto">
                            {/* Logo */ }
                            <div className="app-brand mb-5">
                                <Link to="/" className="app-brand-link gap-2" style={ { margin: "auto" } } >
                                    <img src={ logo } className="img-fluid" width={ 150 } alt="logo" />
                                </Link>
                            </div>

                            <form onSubmit={ handleSubmit(onSubmit) } className="mb-2">
                                {/* Name */ }
                                <div className="d-flex justify-content-between">
                                    <div className="mb-2 col-6 me-2 ">
                                        <div className="field">
                                            <label className="form-label">first Name</label>
                                            <span className="p-float-label">
                                                <Controller name="first_name" control={ control }
                                                    rules={ {
                                                        required: 'First name is required.',
                                                        pattern: {
                                                            value: /^[A-Za-z]+$/,
                                                            message: "Invalid name!"
                                                        }
                                                    } }
                                                    render={ ({ field, fieldState }) => (
                                                        <InputText
                                                            id={ field.first_name }
                                                            { ...field }
                                                            inputRef={ field.ref }
                                                            className={ `w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
                                                    ) } />
                                            </span>
                                            { getFormErrorMessage('first_name', errors) }
                                        </div>
                                    </div>
                                    <div className="mb-2  col-6">
                                        <div className="field">
                                            <label className="form-label">last Name</label>
                                            <span className="p-float-label">
                                                <Controller name="last_name" control={ control }
                                                    rules={ {
                                                        required: 'Last name is required.',
                                                        pattern: {
                                                            value: /^[A-Za-z]+$/,
                                                            message: "Invalid name!"

                                                        }
                                                    } }
                                                    render={ ({ field, fieldState }) => (
                                                        <InputText id={ field.last_name } { ...field } inputRef={ field.ref }
                                                            className={ `w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
                                                    ) } />
                                            </span>
                                            { getFormErrorMessage('last_name', errors) }
                                        </div>
                                    </div>

                                </div>
                                {/* Company Name */ }
                                <div className="mb-2">
                                    <div className="field">
                                        <label className="form-label">company name</label>
                                        <span className="p-float-label">
                                            <Controller name="company_name" control={ control }
                                                rules={ {
                                                    required: 'company name  is required.',
                                                } }
                                                render={ ({ field, fieldState }) => (
                                                    <InputText id={ field.company_name } { ...field } inputRef={ field.ref }
                                                        className={ `w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
                                                ) } />
                                        </span>
                                        { getFormErrorMessage('company_name', errors) }
                                    </div>
                                </div>

                                {/* email */ }
                                <div className="mb-2">
                                    <div className="field">
                                        <label className="form-label">Email</label>
                                        <span className="p-float-label">
                                            <Controller name="email" control={ control }
                                                rules={ {
                                                    required: 'Email Address is required.',
                                                    pattern: {
                                                        value: /^\w+([\.-]?\w+)*\+?\w*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                                        message: "Invalid email address",
                                                    }
                                                } }
                                                render={ ({ field, fieldState }) => (
                                                    <InputText id={ field.email } { ...field } inputRef={ field.ref }
                                                        className={ `w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
                                                ) } />
                                        </span>
                                        { getFormErrorMessage('email', errors) }
                                    </div>
                                </div>

                                <div className="d-flex justify-content-between">

                                    {/* password */ }
                                    <div className="mb-2 form-password-toggle col-6">
                                        <div className="field me-2">
                                            <label className="form-label">Password</label>
                                            <span className="p-float-label">
                                                <Controller name="password" control={ control }
                                                    rules={ { required: 'Password is required.', } }
                                                    render={ ({ field, fieldState }) => (
                                                        <Password id={ field.password } { ...field } inputRef={ field.ref }
                                                            className={ `w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                                            toggleMask />
                                                    ) } />
                                            </span>
                                            { getFormErrorMessage('password', errors) }
                                        </div>
                                    </div>

                                    {/* Password Confirmation */ }
                                    <div className="mb-2 form-password-toggle col-6">
                                        <div className="field">
                                            <label className="form-label">Confirm Password </label>
                                            <span className="p-float-label">
                                                <Controller name="password_confirmation" control={ control }
                                                    rules={ {
                                                        required: 'Password confirmation is required!',
                                                        validate: (val) => {
                                                            if (watch('password') != val) {
                                                                return "Your passwords do no match";
                                                            }
                                                        },
                                                    } }
                                                    render={ ({ field, fieldState }) => (
                                                        <Password id={ field.password_confirmation } { ...field } inputRef={ field.ref }
                                                            className={ `w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                                            feedback={ false } toggleMask />
                                                    ) } />
                                            </span>
                                            { getFormErrorMessage('password_confirmation', errors) }
                                        </div>
                                    </div>
                                </div>

                                <button className="btn btn-primary d-grid w-100 mt-5">Sign up</button>
                            </form>

                            <p className='text-center mt-3 fs-8'>Already have an account!
                                <Link to='/login'>
                                    <span className='mx-2'>login</span>
                                </Link>

                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Signup;