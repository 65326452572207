import React, { useEffect } from "react";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Message } from 'primereact/message';

import { useGalleryContext } from "../../../Contexts/GalleryContext";
import { useGlobalContext } from "../../../Contexts/GlobalContext";
import axiosInstance from "../../../../config/Axios";
import UploadMedia from "./UploadMedia";
import Link from "./Link";

const uploadIcon = <i className="fas fa-upload text-secondary  fs-1  fs-4 p-1"></i>

function UploadPopup ({ content = "media", setRerender }) {

  const { capacityAvailability, setCapacityAvailability } = useGalleryContext();
  const { openDialog, dialogHandler } = useGlobalContext();

  useEffect(() => {
    axiosInstance.post(`/company/check/media/capacity`, { size: 0 })
      .then((result) => {
        setCapacityAvailability(true);
      }).catch((errors) => {
        setCapacityAvailability(false);
      })
  }, [])

  return (
    <>
      <div className="row">
        <div className="col-12 d-flex justify-content-end ">
          <Button
            icon={ uploadIcon }
            tooltip="Upload"
            tooltipOptions={ { position: "bottom" } }
            className=" border-0 p-1 mx-1 bg-transparent"
            onClick={ () => dialogHandler("uploadMedia") }
          />
        </div>
      </div>

      <Dialog
        header={ content == "media" ? "Media Upload" : "Link Upload" }
        visible={ openDialog.uploadMedia }
        onHide={ () => { dialogHandler("uploadMedia") } }
        breakpoints={ { "960px": "95vw" } }
        style={ { width: content == "media" ? "55vw" : "40vw" } }
      >
        {
          !capacityAvailability ?
            <div className="p-5 text-center ">
              <Message className="fw-bold fs-3 p-4 w-100" severity="warn" text="You've reached the maximum allowed media storage!" />
            </div>
            :
            content == "media" ?
              <UploadMedia setRerender={ setRerender } />
              :
              <Link  />
        }
      </Dialog>
    </>
  );
}

export default UploadPopup;
