
import React from 'react'
import { isEmpty } from 'lodash';

import { IoNewspaperOutline } from 'react-icons/io5'
import white3DIcon from "../../../../assets/img/3d_white.png"

import { useGlobalContext } from '../../../Contexts/GlobalContext'
import MediaList from '../../../Apps/Multi3dViwer/MediaList';
import RssList from './RssList';
import { Image } from 'react-bootstrap';

function Apps () {
    const { selectedApp, setSelectedApp, layer, companyDetails } = useGlobalContext();

    const apps = [
        {
            featureKey: "rss_feed",
            title: "RSS Feeds ",
            icon: <i className="fa-solid fa-rss fs-3"></i>,
            component: <RssList />,
            visible: layer === "BANNER" && window.location.pathname.includes("campaign"),
        },
        {
            featureKey: "banner",
            title: "Custom RSS",
            icon: <IoNewspaperOutline className='fs-3' />,
            component: <RssList mediaType={ "RSS_CUSTOM" } />,
            visible: layer === "BANNER" && window.location.pathname.includes("campaign"),
        },
        {
            featureKey: "3d",
            title: "Multi-3D Media",
            icon:  <Image src={ white3DIcon } width="30" /> ,
            component: <MediaList  path={ "/media/multi_3d" } />,
            visible: companyDetails?.package?.features?.["3d"] && layer == "MAIN",
        }
    ]

    return (
        <>
            {
                isEmpty(selectedApp) ?
                    apps.map((app, index) => {
                        return app?.visible ? (
                            <div className={ `co1-12 d-flex  align-items-center text-dark  bg-label-secondary px-3 py-2 cursor-pointer ${ index + 1 == apps.length ? "" : "border-bottom" }` }
                                onClick={ () => { setSelectedApp(app) } }
                                key={ index }>
                                <div className='col-1 p-3 bg-dark text-white me-2 rounded-3 text-center'  >{ app?.icon }</div>
                                <div class="fw-bold">{ app?.title }</div>
                            </div>
                        )
                            :
                            <></>
                    })
                    :
                    selectedApp.component
            }
        </>
    )
}

export default Apps