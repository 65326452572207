import React, {  useState } from 'react'

import { Spinner } from 'react-bootstrap';
import "../css/apps.css"

import AddRssDialog from './AddRssDialog';
import RssDataTable from './RssDataTable';
import Layout from '../../Layout/Layout';

import { useGlobalContext } from '../../Contexts/GlobalContext';
import { useAppsContext } from '../../Contexts/AppsContext';

function RssIndex () {
    const { openDialog, dialogHandler } = useGlobalContext();
    const { isLoading } = useAppsContext();
 
    return (
        <Layout>
            <div className="card">
                <div className="card-header d-flex align-items-center justify-content-between">
                    <h5 className="card-title m-0 me-2">RSS List</h5>
                    <div className=' d-flex align-items-center  mb-4'>
                        <button className="btn btn-primary mx-1" disabled={ isLoading ? true : false } onClick={ () => window.history.go(-1) } > Back</button>
                        <button className="btn btn-primary mx-1" disabled={ isLoading ? true : false } onClick={ () => dialogHandler("rssFeedAddition") } > Add New RSS</button>
                    </div>
                </div>

                <div className="col-12 d-flex flex-column  ">
                    {
                        isLoading ?
                            <div className='d-flex justify-content-center align-items-center my-5'>
                                <Spinner variant="primary" as="span" animation="border" role="status" className='mx-3' aria-hidden="true" />
                            </div>
                            :
                            <>
                                <RssDataTable />
                            </>
                    }
                </div>
            </div>
            { openDialog.rssFeedAddition ? <AddRssDialog /> : <></> }

        </Layout >
    )
}

export default RssIndex