import React, { useEffect} from 'react';

import { useForm, Controller } from "react-hook-form";
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';

import { Dropdown } from 'primereact/dropdown';
import { Spinner } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';

import { getFormErrorMessage } from '../../../config/global';
import { useGlobalContext } from '../../Contexts/GlobalContext';
import { useCustomToast } from "../../Contexts/ToastContext";
import axiosInstance from '../../../config/Axios';

function Update ({ PlayList, setReload }) {
    const { formState: { errors }, control, handleSubmit, reset } = useForm();
    const { dialogHandler, openDialog, disableBtn, setDisableBtn } = useGlobalContext();
    const { showToast } = useCustomToast();

    const status = [
        { name: 'Active', code: 'ACTIVE' },
        { name: 'Inactive', code: 'INACTIVE' },
    ];

    useEffect(() => {
        reset(PlayList)
    }, [PlayList])

    const hideDialog = () => {
        dialogHandler("updatePlaylist");
    }

    const onSubmit = (data) => {
        setDisableBtn(true)
        let formData = {
            "name": data.name,
            "status": data.status,
        }

        axiosInstance.put(`/playlist/${ PlayList.id }`, formData)
            .then((result) => {
                setDisableBtn(false)
                showToast('success', 'Update PlayList', "Playlist Updated Successfully!");
                hideDialog()
                setReload(true)
            }).catch((error) => {
                setDisableBtn(false);
                if (error?.response?.data?.errors)
                Object.values(error?.response?.data?.errors).forEach(error => {
                    showToast('error', 'Update PlayList', error[0]);
                });
            });

    };

    return (
        <>
            <Dialog visible={ openDialog.updatePlaylist }
                style={ { width: '500px' } }
                header="Update Playlist" modal
                className="p-fluid" onHide={ hideDialog }
                breakpoints={ { '960px': '95vw' } }>
                <form onSubmit={ handleSubmit(onSubmit) }>
                    <div className='px-5 my-5'>
                        <div className={ ` col-12 mb-5` }>
                            <div className="field">
                                <label className="form-label"> Name  </label>
                                <span className="p-float-label">
                                    <Controller name="name" control={ control }
                                        rules={ { required: 'Name is required.' } }
                                        render={ ({ field, fieldState }) => (
                                            <InputText id={ field.name } { ...field } autoFocus
                                                className={ `w-100  ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                            />
                                        ) }
                                    />
                                </span>
                                { getFormErrorMessage('name', errors) }
                            </div>
                        </div>

                        {/* Status input */ }
                        <div className={ ` col-12` }>
                            <div className="field">
                                <label className="form-label" htmlFor="basic-default-company">  Status </label>
                                <span className="p-float-label">
                                    <Controller name="status" control={ control }
                                        rules={ { required: 'Status is required.' } }
                                        render={ ({ field, fieldState }) =>
                                        (<Dropdown id={ field.name } { ...field }
                                            onChange={ (e) => field.onChange(e.value) }
                                            options={ status }
                                            optionLabel="name"
                                            optionValue="code"
                                            className={ `w-100  ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                        />)
                                        } />
                                    { getFormErrorMessage('status', errors) }
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 text-center d-flex  align-items-end  justify-content-center py-4 border-top border-gray">
                            <button type="reset" className="btn btn-label-secondary me-3 "
                                data-bs-dismiss="modal" aria-label="Close"
                                disabled={ disableBtn ? true : false }
                                onClick={ hideDialog }>Cancel</button>
                        <button href='/campaigns/list' type="submit" className="btn btn-primary me-2" disabled={ disableBtn ? true : false } >
                            { disableBtn ?
                                <Spinner variant="primary" as="span" animation="border" size="sm" role="status" className='mx-3' aria-hidden="true" />
                                :
                                "Update"
                            }
                        </button>
                    </div>

                </form>
            </Dialog>
        </>
    )
}

export default Update