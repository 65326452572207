import React, { useState, useEffect } from 'react';

import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import { Column } from 'primereact/column';

import { useDataTableContext } from '../../Contexts/DataTableContext';
import { useTitle } from '../../../config/global';

const InvitationsDataTable = () => {
    useTitle('Users');
    const { totalRecords, setReload, loading, lazyParams, setLazyParams, data, onPage, onSort, onFilter, } = useDataTableContext();

    useEffect(() => {
        setLazyParams(prev => ({
            ...prev,
            modelName: "invitations",
            filters: {
                'email': { value: '', matchMode: 'contains' },
                'status': { value: '', matchMode: 'contains' },
            }
        }))
    }, [])


    const actionBodyTemplate = (rowData) => {
        return <>
            <div className="d-inline-block text-nowrap">
                {/* Delete playlist */ }
                <Tooltip target={ `.delete-button-${ rowData.id }` } showDelay={ 100 } className="fs-8" />
                <button className={ `btn btn-sm btn-icon delete-record delete-button-${ rowData.id }` }
                    data-pr-position="bottom"
                    data-pr-tooltip="Delete"
                >
                    <i className="bx bx-trash"></i>
                </button>
            </div>
        </>

    }

    const statusBodyTemplate = (rowData) => {

        return (
            <div className=''>
                <span className={ ` badge ${ rowData.status == "ACCEPTED" ?
                    "bg-label-success"
                    : rowData.status == "PENDING" ? "bg-label-warning" :
                        "bg-label-danger" } ` }>
                    { rowData.status.toLowerCase() }
                </span>
            </div>
        );
    }

    const dateBodyTemplate = (rowData) => {
        return (rowData.created_at);
    }

    return (
        <div className="card">
            <div className="card-header d-flex align-items-center justify-content-end">
                <h5 className="card-title m-0 me-auto">Invitation List</h5>
            </div>

            <div className='table-responsive text-nowrap'>
                <DataTable value={ data.invitations } lazy filterDisplay="row" responsiveLayout="scroll" dataKey="id"
                    paginator first={ lazyParams.first } rows={ lazyParams.rows } rowsPerPageOptions={ [15, 25, 50, 100] }
                    totalRecords={ totalRecords } onPage={ onPage }
                    onSort={ onSort } sortField={ lazyParams.sortField } sortOrder={ lazyParams.sortOrder }
                    onFilter={ onFilter } filters={ lazyParams.filters } loading={ loading } className="table"
                >
                    <Column
                        field="email"
                        header="Email"
                        sortable
                        filter
                        className='text-center' />
                    <Column header="status" filterField="status"
                        body={ statusBodyTemplate }
                        showFilterMatchModes={ false } filterMenuStyle={ { width: '14rem' } }
                        style={ { minWidth: '14rem' } }
                        filter sortable
                        className='text-center' />
                    <Column
                        header="Created Date"
                        filterField="created_at"
                        dataType="date"
                        style={ { minWidth: '10rem' } }
                        body={ dateBodyTemplate }
                        sortable />
                </DataTable>
            </div>
        </div>
    );
}
export default InvitationsDataTable;
