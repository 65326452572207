import React, { useEffect } from "react";

import { onValue, ref } from "firebase/database";
import { db } from "../../config/firebase";
import { useParams } from "react-router-dom";

function FirebaseMediaListener({ path, getData }) {

  let fullPath = process.env.REACT_APP_APP_ENV + "/" + path;
  const { id } = useParams();

  useEffect(() => {
    const query = ref(db, fullPath);
    return onValue(query, (snapshot) => {
      const data = snapshot.val();
       if (snapshot.exists()) {
        getData(false);
      }
    });

  }, [id]);

  return (
    <></>
  )
}

export default FirebaseMediaListener