import React from 'react'
import moment from 'moment'

import { Spinner } from 'react-bootstrap';

import { useGlobalContext } from '../../../Contexts/GlobalContext';
import { useProfileContext } from '../../../Contexts/ProfileContext';

import FeaturesList from '../Payment/FeaturesList';
import UpgradeDialog from '../Payment/UpgradeDialog';
import { useState } from 'react';
import { useEffect } from 'react';

function PlanDetailsCard () {
    const { openDialog, dialogHandler, subLoaders, disableBtn, subscriptionInfo, companyDetails, packages } = useGlobalContext();
    const { setAction, setSelectedPricing, selectedPackage, setSelectedPackage, showUpgrade, setShowUpgrade } = useProfileContext();

    const renewalHandler = () => {
        setSelectedPackage(companyDetails?.package)
        setSelectedPricing(companyDetails?.subscription_pricing)
        dialogHandler("paymentDialog");
    }

    const upgrade = () => {
        setAction("upgrade")
        dialogHandler("upgrade");
    }

    useEffect(() => {

        setShowUpgrade(false)
        packages?.map(p => {
            if (p.basic_price > companyDetails?.basic_price) {
                setShowUpgrade(true)
            }
        });

    }, [subLoaders.companyDetails]);

    return (
        <>
            <div className="card p-3 d-flex flex-column align-items-center current_plan_card">
                {
                    subLoaders.companyDetails ?
                        <Spinner variant="primary" as="span" size='lg' animation="border" role="status" className='mx-3 my-5 ' aria-hidden="true" />
                        :
                        <>
                            <div className='col-12  fs-8'>
                                <span className='fw-bold'>{ companyDetails?.package?.title } -</span>
                                { subscriptionInfo.isFinished ?
                                    <span className='text-danger'> Ended </span>
                                    :
                                    <span className='text-light'> Ends on { moment(companyDetails?.subscription?.end_date).format("DD-MM-YYYY") } </span>
                                }
                            </div>

                            <div className='my-3 position-relative'>
                                {
                                    subscriptionInfo.isFinished &&
                                    <div className='position-absolute w-100 h-100 top-0 left-0 bg-white-opacity'></div>
                                }
                                {
                                    <FeaturesList selectedPackage={ companyDetails?.package } />
                                }
                            </div>

                            <div className='d-flex align-items-center mt-auto'>
                                {
                                    (showUpgrade || subscriptionInfo.isFree || (companyDetails?.subscription?.status == "INACTIVE" && companyDetails?.subscription_pricing?.numeric_period != 12)) &&
                                    <button className='btn btn-primary me-3 mx-auto w-auto px-2' onClick={ () => { upgrade() } } disabled={ disableBtn }>
                                        Upgrade
                                    </button>
                                }
                                {/* {
                                    (!subscriptionInfo.isFree && !subscriptionInfo.isYearly) &&
                                    <button className='btn btn-primary me-3 mx-auto w-auto px-2' onClick={ () => { switchToYearly() } } disabled={ disableBtn }>
                                        switch To Yearly
                                    </button>
                                } */}
                                {
                                    ((subscriptionInfo.isFinished && !subscriptionInfo.isFree)) &&
                                    <button type="submit" className="btn btn-primary px-2" onClick={ () => { renewalHandler() } }  >
                                        Plan Renewal
                                    </button>
                                }
                            </div>

                        </>
                }
            </div>

            { openDialog.upgrade && <UpgradeDialog /> }
        </>
    )
}

export default PlanDetailsCard