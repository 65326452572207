import React from "react";
import ReactDOM from "react-dom/client";

import "primereact/resources/themes/lara-light-indigo/theme.css";
import "./pages/SharedComponents/css/data-scroller.css"
import './assets/vendor/css/pages/page-auth.css';
import "primereact/resources/primereact.css";
import 'react-phone-input-2/lib/style.css'
import "primeicons/primeicons.css";
import "./index.css";
import "./App.css";

import { ScrollLoadingProvider } from "./pages/Contexts/ScrollLoadingContext";
import { DataTableProvider } from "./pages/Contexts/DataTableContext";
import { GlobalToastProvider } from "./pages/Contexts/ToastContext";
import { GalleryProvider } from "./pages/Contexts/GalleryContext";
import { DeviceProvider } from "./pages/Contexts/DeviceContext";
import { GlobalProvider } from "./pages/Contexts/GlobalContext";
import { AppsProvider } from "./pages/Contexts/AppsContext";
import RoutesContainer from "./routes/RoutesContainer";

export default function App () {

  return (
    <GlobalToastProvider>
      <GlobalProvider>
        <AppsProvider>
          <ScrollLoadingProvider >
            <DataTableProvider >
              <DeviceProvider>
                <GalleryProvider>
                  <RoutesContainer />
                </GalleryProvider>
              </DeviceProvider>
            </DataTableProvider>
          </ScrollLoadingProvider>
        </AppsProvider>
      </GlobalProvider>
    </GlobalToastProvider >
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
