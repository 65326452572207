import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import "../css/apps.css"

import { useAppsContext } from '../../Contexts/AppsContext';
import { prayersNames } from '../../../config/global'
import PrayerCountdown from './PrayerCountdown';
import axiosInstance from '../../../config/Axios';
import { Spinner } from 'react-bootstrap';
import Layout from '../../Layout/Layout';


function PrayerCard () {
    const { prayerTimes, setPrayerTimes, data, setData, isLoading, setIsLoading, nextPrayerIndex, setNextPrayerIndex } = useAppsContext();
    const [timeLeft, setTimeLeft] = useState(""); // Time left to next prayer

    const navigate = useNavigate();

    // Fetch prayer times 
    useEffect(() => {
        setIsLoading(true)

        fetchPrayersTime();
    }, [])

    useEffect(() => {
        if (isLoading)
            fetchPrayersTime();
    }, [isLoading])

    const fetchPrayersTime = () => {
        axiosInstance.get(`/device/prayer`)
            .then((response) => {
                let result = response?.data?.data;
                setPrayerTimes(result?.prayer_times)

                const nextPrayerTime = moment(result?.prayer_times[result?.next], 'H:mm');

                let info = {
                    "location": {
                        "city": result?.city,
                        "country_name": result?.country_name,
                    }
                };

                if (nextPrayerTime.isBefore(moment()) && result?.next == 4) {
                    info.next = 0
                    setNextPrayerIndex(0)
                } else {
                    info.next = result?.next
                    setNextPrayerIndex(result?.next)
                }

                setData(info)
                setIsLoading(false)
            })
    }

    return (
        <Layout>
            <div className="card">
                <div className="card-body">
                    <div className="card-title d-flex align-items-center justify-content-between   flex-shrink-0 ">
                        <div className='d-flex align-items-center '>
                            <span className="avatar-initial rounded bg-label-primary p-2 me-2">
                                <i className="fas fa-mosque"></i>
                            </span>
                            <div className='d-flex flex-column'>

                                <h5 className="fw-semibold my-0  ">Prayer App</h5>
                                <small className=' text-capitalize'>
                                    {
                                        data.location &&
                                        `${ data.location.city } / ${ data.location.country_name }  `
                                    }
                                </small>
                            </div>
                        </div>
                        <div className='d-flex align-items-center '>
                            <button className="btn btn-primary mx-1" disabled={ isLoading ? true : false } onClick={ () => window.history.go(-1) } > Back</button>
                            <button className="btn btn-primary " disabled={ isLoading ? true : false } onClick={ () => navigate("/apps/prayer/campaigns") } > Campaigns</button>
                        </div>
                    </div>
                    <div className="d-flex flex-column">
                        {
                            isLoading ?
                                <div className='d-flex justify-content-center align-items-center my-5'>
                                    <Spinner variant="primary" as="span" animation="border" role="status" className='mx-3' aria-hidden="true" />
                                </div>
                                :
                                prayerTimes.map((prayerTime, index) => {
                                    return (
                                        <div className={ `col-12 d-flex my-1 p-2 rounded  ${ index == nextPrayerIndex ? "next_prayer" : "" }` } key={ index }>
                                            {
                                                index == nextPrayerIndex ?
                                                    <>
                                                        <div className='d-flex flex-column align-items-center '>
                                                            <div className='fs-6 fw-bold text-dark'>Upcoming Prayer</div>
                                                            <div className='fs-6 fw-bold text-dark mt-2'>{ prayersNames[index] }  <span className='ms-3 fw-normal'>{ prayerTimes[index] }</span> </div>
                                                            <div className='my-2 fs-2 fw-bolder'>
                                                                <PrayerCountdown
                                                                    timeLeft={ timeLeft }
                                                                    setTimeLeft={ setTimeLeft } />
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className='me-3 col-3 fw-bold'>{ prayersNames[index] }</div>
                                                        <div className={ `${ index == nextPrayerIndex ? "fw-bold" : "" }` }>{ prayerTime }</div>
                                                    </>
                                            }
                                        </div>
                                    )
                                })
                        }
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default PrayerCard