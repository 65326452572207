import React, { useEffect, useState } from 'react'

import { Dialog } from 'primereact/dialog';
import { useForm } from "react-hook-form";

import AssignScheduleCampaign from './AssignScheduleCampaign';
import { useGlobalContext } from '../../../Contexts/GlobalContext';
import axiosInstance from '../../../../config/Axios';
import { useDeviceContext } from '../../../Contexts/DeviceContext';

const AssignCampaignToGroup = ({ selectedGroup, setSelectedGroup }) => {
  const { reset } = useForm();
  const { openDialog, dialogHandler } = useGlobalContext();
  const { setRerender, setLoading, } = useDeviceContext();

  // Assign to device dropdown option "campaigns"
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState();
  const [groupOptions, setGroupOptions] = useState([]);

  useEffect(() => {
    axiosInstance.get(`/company/campaigns`)
      .then((result) => {
        result.data.data.map(campaign => {
          let option = {
            name: campaign.name,
            code: campaign.id
          }
          setCampaignOptions(current => [...current, option])
        })
      })

    axiosInstance.get(`/groups/company`)
      .then((response) => {
        Object.values(response?.data?.data).forEach(group => {
          let option = {
            name: group.name,
            code: group.id
          }
          setGroupOptions(current => [...current, option])
        })
        setRerender(false)
        setLoading(false)
      })

    reset();
  }, [reset]);

  return (
    <Dialog visible={ openDialog.assignDialog }
      style={ { width: '550px' } }
      breakpoints={ { '960px': '95vw' } }
      header="Assign campaign to Group"
      modal className="p-fluid"
      onHide={ () => dialogHandler("assignDialog")
      }>

      <AssignScheduleCampaign
        options={ campaignOptions }
        selectedCampaign={ selectedCampaign }
        setSelectedCampaign={ setSelectedCampaign }
        groupOptions={ groupOptions }
        setGroupOptions={ setGroupOptions }
        selectedGroup={ selectedGroup }
        setSelectedGroup={ setSelectedGroup }
      />

    </Dialog>
  )
}

export default AssignCampaignToGroup