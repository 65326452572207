import React from 'react';

import { useGlobalContext } from '../../Contexts/GlobalContext';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Spinner } from 'react-bootstrap';
import { AiOutlineAppstoreAdd } from "react-icons/ai";

import ChannelsList from './ChannelsList';
import PlayLists from './PlayLists';
import MediaList from './MediaList';
import LinksList from './LinksList';
 import AppsList from './AppsList';
import { useState } from 'react';

function AddContentPopup ({ updateStatus }) {
    const { hideTabs, layer, setAssign, setType, disableBtn, openDialog, dialogHandler } = useGlobalContext();

    const menuItems = [
        {
            key: "playlist",
            title: "PlayList",
            child: <PlayLists />,
            visible: layer === "MAIN" && window.location.pathname.includes("campaign") && !hideTabs,
            icon: <i className="bx bxs-playlist menu-icon"></i>,
        },
        {
            key: "media",
            title: "Media",
            child: <MediaList />,
            visible: true,
            icon: <i className="menu-icon tf-icons bx bx-images"></i>,
        },
        {
            key: "links",
            title: "Links",
            child: <LinksList />,
            visible: true,
            icon: <i className="menu-icon tf-icons bx bx-link"></i>,
        },
        {
            key: "channels",
            title: "Channels",
            child: <ChannelsList />,
            visible: !hideTabs && layer !== "BANNER",
            icon: <i className='menu-icon bx bx-tv me-2'></i>,
        },
        {
            key: "apps",
            title: "Apps",
            child: <AppsList />,
            visible: true ,
            icon: <AiOutlineAppstoreAdd className='menu-icon me-2' />,
        }

    ]
    const [activeTab, setActiveTap] = useState(menuItems[0]?.visible ? menuItems[0] : menuItems[1]);

    const assignMedia = () => {
        setAssign(true);

        if (window.location.pathname.includes("playlist")) {
            updateStatus("INACTIVE")
        } else {
            updateStatus("DRAFT")
        }
    }

    const renderFooter = (name) => {
        return (
            <div>
                <Button icon={ disableBtn ? "" : "pi pi-plus" }
                    label={ disableBtn ?
                        <Spinner variant="white" as="span" animation="border" size="sm" role="status" className='mx-3' aria-hidden="true" />
                        :
                        "ADD"
                    }
                    className='btn btn-primary text-center fs-6 p-2'
                    onClick={ assignMedia }
                    disabled={ disableBtn } />
            </div>
        );
    }

    return (
        <>
            <Dialog header="Add Content" visible={ openDialog.addContent }
                onHide={ () => dialogHandler("addContent") }
                footer={ renderFooter } breakpoints={ { '960px': '95vw' } } style={ { width: '60vw' } }
            >
                <div className="d-flex flex-nowrap overflow-hidden">
                    <div className="col-3 col-sm-2 col-lg-2 px-0 bg-white media_options_container border-end" >
                        {
                            menuItems.map((item, index) => {
                                return item?.visible ?
                                    <button key={ index } className={ `py-2 px-3 border-0 w-100 text-start d-flex align-items-center ${ item?.key == activeTab?.key ? "active_upload_button" : "bg-transparent" }` }
                                        onClick={ () => {setType(item?.key); setActiveTap(item) } }>
                                        { item?.icon }
                                        <span>{ item?.title }</span>
                                    </button>
                                    :
                                    <></>
                            })
                        }
                    </div>
                    <div className="col-10 d-flex justify-content-center align-items-start">
                        { activeTab?.child }
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default AddContentPopup