import React, { useState, useEffect } from 'react'
import { useForm, Controller } from "react-hook-form";

import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';

import { getFormErrorMessage } from '../../../../config/global';
import { useCustomToast } from '../../../Contexts/ToastContext';
import axiosInstance from '../../../../config/Axios';

function GroupHeader ({ deviceCount, groupName, columnId, groupID, playPause, setRerender, setLoading, assignSchedule }) {
    const { formState: { errors }, control, handleSubmit, reset } = useForm();
    const { showToast } = useCustomToast();

    let defaultName = {
        "name": groupName
    }
    const [editName, setEditName] = useState(false);
    const [name, setName] = useState(defaultName.name);
    const [isEmpty, setIsEmpty] = useState(deviceCount == 0 ? true : false)

    useEffect(() => {
        reset({ "name": name })
    }, [editName])

    const onSubmit = (data) => {
        setEditName(false)
        setName(data.name)

        if (groupName != data.name) {
            axiosInstance.put(`/groups/${ groupID }/update`, data
            ).then((result) => {
                showToast('success', 'Update Group', result?.data?.data?.msg);
            }).catch((error) => {
                showToast('error', 'Update Group', error?.response?.data?.message);
            });
        }
    }

    const deleteGroup = () => {
        setLoading(true)
        axiosInstance.delete(
            `/groups/${ groupID }/delete`
        ).then((result) => {
            setRerender(true)
        }).catch((error) => {
            showToast('error', 'Delete Group', error?.response?.data?.message);
        });
    }

    return (
        <>
            <div className='bg-white px-3 pt-2 group_title w_20'>
                <div className='d-flex justify-content-between align-items-center'>
                    {
                        editName ?
                            <form onSubmit={ handleSubmit(onSubmit) } className="d-flex align-items-center">
                                <div className={ `field  ${ errors.name ? "mx-2 mt-2" : "m-2" }` }>
                                    <span className="p-float-label">
                                        <Controller name="name" control={ control }
                                            rules={ { required: 'Name is required!' } }
                                            render={ ({ field, fieldState }) => {
                                                return (
                                                    <InputText
                                                        id={ field.name } { ...field } autoFocus
                                                        className={ `w-100 p-1 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
                                                )
                                            } } />
                                    </span>
                                </div>

                                {/* Save changes */ }
                                <Tooltip target={ `.save-button-${ columnId }` } showDelay={ 100 } className="fs-8" />
                                <button className={ `btn btn-sm btn-icon save-button-${ columnId }` } data-pr-position="bottom" type='submit' data-pr-tooltip="Update" >
                                    <i className="far fa-check-circle  text-light fs-6" ></i>
                                </button>

                            </form>
                            :
                            <h2 className=" me-3 fs-5 fw-bold">{ name }</h2>
                    }

                    <div className='d-flex align-items-center'>
                        {
                            !editName &&
                            <>
                                {/* Edit */ }
                                <Tooltip target={ `.edit-button-${ columnId }` } showDelay={ 100 } className="fs-8" />
                                <button className={ `btn btn-sm btn-icon edit-button-${ columnId }` } data-pr-position="bottom" data-pr-tooltip="Update" onClick={ () => { setEditName(true) } }>
                                    <i className="far fa-edit text-light fs-6"></i>
                                </button>
                            </>
                        }
                        {/* Delete */ }
                        <Tooltip target={ `.delete-button-${ columnId }` } showDelay={ 100 } className="fs-8" />
                        <button className={ `btn btn-sm btn-icon delete-button-${ columnId }` } data-pr-position="bottom" data-pr-tooltip="Delete" onClick={ deleteGroup } >
                            <i className="bx bx-trash text-light"></i>
                        </button>

                        {/* Play  */ }
                        <Tooltip target={ `.play-${ columnId }` } showDelay={ 100 } className="fs-8" />
                        <button className={ `btn btn-sm btn-icon play-${ columnId }` } disabled={ isEmpty } data-pr-position="bottom" data-pr-tooltip={ `Play ` } onClick={ () => playPause(groupID, "play") }>
                            <i className='bx bx-play-circle text-light' ></i>
                        </button>

                        {/* Pause  */ }
                        <Tooltip target={ `.pause-${ columnId }` } showDelay={ 100 } className="fs-8" />
                        <button className={ `btn btn-sm btn-icon pause-${ columnId }` } disabled={ isEmpty } data-pr-position="bottom" data-pr-tooltip={ `Pause ` } onClick={ () => playPause(groupID, "pause") }>
                            <i className='bx bx-pause-circle text-light' ></i>
                        </button>

                        {/*assign schedule campaign  */ }
                        <Tooltip target={ `.assign-${ columnId }` } showDelay={ 100 } className="fs-8" />
                        <button className={ `btn btn-sm btn-icon assign-${ columnId }` } disabled={ isEmpty } data-pr-position="bottom" data-pr-tooltip={ `assign schedule campaign` } onClick={ () => assignSchedule(groupID) }>
                            <i className='bx bx-time-five text-light' ></i>
                        </button>

                    </div>
                </div>
                <div className='mx-2'>{ getFormErrorMessage('name', errors) }</div>

            </div>
        </>
    )
}

export default GroupHeader