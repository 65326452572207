import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';

import { useTitle, formatDuration } from '../../config/global';
import DeleteConfirmation from '../SharedComponents/DeleteConfirmation';
import { NetworkManager } from '../../config/NetworkManager';
import UpdateCampaign from './Components/Update';
import AssignPopup from './Components/AssignToDevicePopup';
import Layout from "../Layout/Layout";
import Create from './Components/Create';

import { useDataTableContext } from '../Contexts/DataTableContext';
import { useGlobalContext } from '../Contexts/GlobalContext';
import { useCustomToast } from '../Contexts/ToastContext';

let emptyCampaign = {
    name: "",
    type: "",
    start_time: "",
    end_time: "",
    date: ""
};

const CampaignDatatable = () => {
    useTitle('Campaigns');

    const { setDisableBtn, setIsPrayerCampaign, deleteDialog, deleteDialogHandler, openDialog, dialogHandler, setStepsBack } = useGlobalContext();
    const { totalRecords, setReload, loading, lazyParams, setLazyParams, data, onPage, onSort, onFilter, setIsInteractiveCampaign } = useDataTableContext();
    const { showToast } = useCustomToast();

    const [campaign, setCampaign] = useState({ name: "" });
    const [campaignId, setCampaignId] = useState();

    const navigate = useNavigate();
    const Service = new NetworkManager();

    useEffect(() => {
        setStepsBack(-1)
        setLazyParams(prev => ({
            ...prev,
            modelName: "campaign",
            filters: {
                'name': { value: '', matchMode: 'contains' },
                'status': { value: '', matchMode: 'contains' },
            }
        }))
    }, [])

    //Delete Campaign Handler
    const confirmDeleteUser = (data) => {
        setCampaign(data);
        deleteDialogHandler("campaign")
    }

    const deleteCampaign = () => {
        Service.deleteRow('campaign', campaign);
        setReload(true);
        deleteDialogHandler("campaign")
        setDisableBtn(false);
        showToast('success', 'Delete Campaign', 'Campaign Deleted Successfully');
    }

    const assignHandler = (id) => {
        setCampaignId(id)
        dialogHandler("assignDeviceGroup")
    }

    // Data Table Body Template
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="d-inline-block text-nowrap">
                {/* Assign to device */ }
                <Tooltip target={ `.assign-button-${ rowData.id }` } showDelay={ 100 } className="fs-8" />
                <button className={ `btn btn-sm btn-icon assign-button-${ rowData.id }` } data-pr-position="bottom" data-pr-tooltip={ `Assign ${ rowData.name }  to  device` } onClick={ () => { assignHandler(rowData.id) } } >
                    <i className='bx bx-up-arrow-alt'></i>
                </button>

                {/* Link to setup page */ }
                <Tooltip target={ `.setup-button-${ rowData.id }` } showDelay={ 100 } className="fs-8" />
                <button className={ `btn btn-sm btn-icon setup-button-${ rowData.id }` } onClick={ () => goToSetup(rowData.id) } data-pr-position="bottom" data-pr-tooltip="Setup"><i className='bx bx-slideshow' ></i></button>

                {/* Update campaign */ }
                <Tooltip target={ `.update-button-${ rowData.id }` } showDelay={ 100 } className="fs-8" />
                <button className={ `btn btn-sm btn-icon update-button-${ rowData.id }` } data-pr-position="bottom" data-pr-tooltip="
                Update" onClick={ () => editCampaign(rowData) }><i className="pi pi-pencil"></i></button>
               
                {
                    rowData.name != "default" ?
                        <>
                            {/* Delete Campaign */ }
                            <Tooltip target={ `.delete-button-${ rowData.id }` } showDelay={ 100 } className="fs-8" />
                            <button className={ `btn btn-sm btn-icon delete-record delete-button-${ rowData.id }` } data-pr-position="bottom" data-pr-tooltip="Delete" onClick={ () => confirmDeleteUser(rowData) } ><i className="bx bx-trash"></i></button>
                        </>
                        :
                        <>  </>
                }
            </div>
        );
    }

    const durationBodyTemplate = (rowData) => {
        return <>
            { formatDuration(rowData.campaign_duration) }
        </>
    }

    const statusBodyTemplate = (rowData) => {
        return (
            <div className='text-center'>
                <span className={ `text-center badge ${ rowData.status == "DRAFT" ? "bg-label-warning" : "bg-label-success" } ` }>{ rowData.status.toLowerCase() }</span>
            </div>
        );
    }

    //Dialog Handler
    const createCampaign = () => {
        setCampaign(emptyCampaign);
        setIsInteractiveCampaign(0)
        dialogHandler("createCampaign");
    }

    const editCampaign = (data) => {
        setCampaign(data);
        dialogHandler("updateCampaign")
    }

    const goToSetup = (campaignID) => {
        sessionStorage.setItem('is_default_prayer', JSON.stringify(false));
        setIsPrayerCampaign(false)
        navigate(`/campaigns/${ campaignID }/setup`)
    }

    return (
        <Layout>
            <div className="card">
                <div className="card-header d-flex align-items-center justify-content-between">
                    <h5 className="card-title m-0 me-2">Campaign List</h5>
                    <button type="button" className="btn btn-primary me-sm-3 me-1" onClick={ createCampaign }>
                        <span className="tf-icons bx bx-user"></span> Create Campaign
                    </button>
                </div>

                <div className='table-responsive text-nowrap'>
                    <DataTable value={ data.campaign } lazy filterDisplay="row" responsiveLayout="scroll" dataKey="id"
                        paginator first={ lazyParams.first } rows={ lazyParams.rows } rowsPerPageOptions={ [15, 25, 50, 100] }
                        totalRecords={ totalRecords } onPage={ onPage }
                        onSort={ onSort } sortField={ lazyParams.sortField } sortOrder={ lazyParams.sortOrder }
                        onFilter={ onFilter } filters={ lazyParams.filters } loading={ loading } className="table"
                    >
                        <Column field="name" header="Name" className='text-center' sortable filter filterPlaceholder="Search by Name" />
                        <Column field="campaign_duration" className='text-center' header="Total Duration" body={ durationBodyTemplate } />
                        <Column field="status" header="Status" className='text-center' sortable body={ statusBodyTemplate } filter filterPlaceholder="Search by Status" />
                        <Column field="created_at" header="Created Date" className='text-center' sortable />
                        <Column body={ actionBodyTemplate } exportable={ false } style={ { minWidth: '8rem' } }></Column>
                    </DataTable>
                </div>

                {
                    openDialog.createCampaign && <Create />
                }
                {
                    openDialog.updateCampaign && <UpdateCampaign setReload={ setReload } campaign={ campaign } />
                }
                {
                    openDialog.assignDeviceGroup && <AssignPopup campaignId={ campaignId } />
                }
                {
                    deleteDialog.campaign &&
                    <DeleteConfirmation
                        deleteHandler={ deleteCampaign }
                        itemName={ campaign.name }
                        dialogKey="campaign"
                    />
                }
            </div>
        </Layout>
    );
}

export default CampaignDatatable;
