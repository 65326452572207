import React from 'react'

import { Spinner } from 'react-bootstrap'
import DeviceCard from './DeviceCard'

function DevicesList ({ data, ids, setIds, device, setDevice, pageNum, lastPage, setLastElement, loading, listLoading }) {
    return (
        <>
            {
                data.length == 0 && !loading ?
                    <div className='w-100 text-center'>
                        There is no devices added yet!
                    </div>
                    :
                    <>
                        { data.length > 0 && data.map((item, index) => {
                            return index === data.length - 1 && pageNum <= lastPage ?
                                <div className="col-12 d-flex align-items-center justify-content-between " ref={ setLastElement } key={ `${ item.id }-${ index }` }>
                                    <DeviceCard ids={ ids } setIds={ setIds } item={ item } device={ device } setDevice={ setDevice } />
                                </div>
                                :
                                <div className={ `col-12 d-flex align-items-center justify-content-between my-1 
                                        ${ data.length == index + 1 ? "" : "border-bottom" } pb-2` } key={ `${ item.id }-${ index }` } >
                                    <DeviceCard ids={ ids } setIds={ setIds } item={ item } device={ device } setDevice={ setDevice } />
                                </div>

                        })

                        }
                        {
                            listLoading &&
                            <Spinner variant="primary" as="span" animation="border" size="lg" role="status" className='m-3' aria-hidden="true" />
                        }
                    </>
            }
        </>
    )
}

export default DevicesList