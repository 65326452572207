import React from 'react';

import { Link, useNavigate, useParams } from 'react-router-dom'
import { useForm, Controller } from "react-hook-form";

import { useTitle, getFormErrorMessage, setProfile } from '../../config/global';

import '../../assets/vendor/css/pages/page-auth.css';
import landing from '../../assets/img/illustrations/boy-with-rocket-light.png';
import logo from '../../assets/img/logo.png';

import { useGlobalContext } from '../Contexts/GlobalContext';
import { useCustomToast } from '../Contexts/ToastContext';
import { classNames } from 'primereact/utils';
 import axiosInstance from '../../config/Axios';
import { Password } from 'primereact/password';

const EmployeeSignUp = () => {
    useTitle('Sign-up Page');
 
    const { formState: { errors }, watch, handleSubmit, control } = useForm({ mode: 'all' });
    const { setUserInfo }   = useGlobalContext();
    const { showToast }     = useCustomToast();
    const { invitation_id } = useParams();
    const navigate   = useNavigate();

    const onSubmit = (data) => {
        axiosInstance.post(`/users/accept/${ invitation_id }/invitation`, data)
            .then((result) => {
                let data = result.data?.data;
                setProfile(data);
                setUserInfo(data)
                navigate("/dashboard");
            }).catch((error) => {
                showToast('error', 'Sign-up Credential', error.response?.data?.message);
            });
    };

    return (
        <>
            <div className="authentication-wrapper authentication-cover">
                <div className="authentication-inner row m-0">
                    <div className="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center p-5">
                        <div className="w-100 d-flex justify-content-center">
                            <img src={ landing } className="img-fluid" width={ 700 } alt="landing" />
                        </div>
                    </div>
                    <div className="d-flex col-12 col-lg-5 col-xl-4 align-items-center authentication-bg p-sm-5 p-4">
                        <div className="w-px-400 mx-auto">
                            {/* Logo */ }
                            <div className="app-brand mb-5">
                                <Link to="/" className="app-brand-link gap-2" style={ { margin: "auto" } } >
                                    <img src={ logo } className="img-fluid" width={ 150 } alt="logo" />
                                </Link>
                            </div>

                            <form onSubmit={ handleSubmit(onSubmit) } className="mb-2">

                                <div className="d-flex flex-col justify-content-between">

                                    {/* password */ }
                                    <div className="mb-2 form-password-toggle col-12">
                                        <div className="field me-2">
                                            <label className="form-label">Password</label>
                                            <span className="p-float-label">
                                                <Controller name="password" control={ control }
                                                    rules={ { required: 'Password is required.', } }
                                                    render={ ({ field, fieldState }) => (
                                                        <Password id={ field.password } { ...field } inputRef={ field.ref }
                                                            className={ `w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                                            toggleMask />
                                                    ) } />
                                            </span>
                                            { getFormErrorMessage('password', errors) }
                                        </div>
                                    </div>

                                    {/* Password Confirmation */ }
                                    <div className="mb-2 form-password-toggle col-12">
                                        <div className="field">
                                            <label className="form-label">Confirm Password </label>
                                            <span className="p-float-label">
                                                <Controller name="password_confirmation" control={ control }
                                                    rules={ {
                                                        required: 'Password confirmation is required!',
                                                        validate: (val) => {
                                                            if (watch('password') != val) {
                                                                return "Your passwords do no match";
                                                            }
                                                        },
                                                    } }
                                                    render={ ({ field, fieldState }) => (
                                                        <Password id={ field.password_confirmation } { ...field } inputRef={ field.ref }
                                                            className={ `w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                                            feedback={ false } toggleMask />
                                                    ) } />
                                            </span>
                                            { getFormErrorMessage('password_confirmation', errors) }
                                        </div>
                                    </div>
                                </div>

                                <button className="btn btn-primary d-grid w-100 mt-5">Sign up</button>
                            </form>

                            <p className='text-center mt-3 fs-8'>Already have an account!
                                <Link to='/login'>
                                    <span className='mx-2'>login</span>
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default EmployeeSignUp;