import React, { useRef } from 'react'
import { useForm, Controller } from "react-hook-form";

import {getFormErrorMessage } from '../../../config/global';
import { useCustomToast } from '../../Contexts/ToastContext';
import footerLocation from "../../../assets/img/Landing/footerLocation.png";
import footerMail from "../../../assets/img/Landing/footerMail.png";
import footerPhone from "../../../assets/img/Landing/footerPhone.png";

import { classNames } from 'primereact/utils';
import PhoneInput from 'react-phone-input-2'
import { InputText } from 'primereact/inputtext';
import axiosInstance from '../../../config/Axios';
 
const Footer = () => {
    const { register, formState: { errors }, watch, handleSubmit, control, reset } = useForm();
    const { showToast } = useCustomToast();

    const onSubmit = (data, e) => {
        axiosInstance.post(`/users/contact-us`, data
        ).then((result) => {
            reset({
                name: "",
                email: "",
                company_name: "",
                phone: "",
                msg: "",
            });
            showToast('success', 'Contact Us', result?.data?.data?.msg);
        }).catch((error) => {
            showToast('error', 'Contact Us', error?.response?.data?.message);
        });
    };

    return (
        <footer className="footer landing-footer pb-5">
            <div className="container py-5">
                <div className="row">
                    <div className="col-md-12 footer-text landing-footer-text">
                        <h2 className="text-center">Get in Touch!</h2>
                        <p className="text-center mt-2">
                            Contact our sales team to know what fits your business needs!
                        </p>
                        <div className="row mt-5 footer-icon-width my-4">
                            <div className="col-md-4 d-flex flex-column justify-content-center align-items-center text-white">
                                <img src={ footerLocation } className="icon_location mb-2" alt="location" />
                                <p>King Abdulla II st. 196</p>
                            </div>
                            <div className="col-md-4 d-flex flex-column justify-content-center align-items-center text-white">
                                <img src={ footerPhone } className="icon_phone mb-2" alt="phone" />
                                <p>+962 6 53 41 800</p>
                            </div>
                            <div className="col-md-4 d-flex flex-column justify-content-center align-items-center text-white">
                                <img src={ footerMail } className="icon_envelop mb-2" alt="mail" />
                                <p>info@popupush.com</p>
                            </div>
                        </div>
                        <div className="card mt-3 footer-card">
                            <div className="card-body">
                                <h3 className="card-title fs-3">Contact Form</h3>
                                <form onSubmit={ handleSubmit(onSubmit) }>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group footer-form-group mb-2">
                                                <label className="">Full Name</label>
                                                <span className="p-float-label">
                                                    <Controller name="name" control={ control }
                                                        rules={ { required: 'Name is required!' } }
                                                        render={ ({ field, fieldState }) => (
                                                            <InputText id={ field.name } { ...field }
                                                                className={ `w-100 form-control footer-form ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
                                                        ) } />
                                                </span>
                                                { getFormErrorMessage('name', errors) }
                                            </div>

                                            <div className="form-group footer-form-group mb-2">
                                                <label htmlFor="">Email</label>
                                                <span className="p-float-label">
                                                    <Controller name="email" control={ control }
                                                        rules={ {
                                                            required: 'Email Address is required!',
                                                            pattern: {
                                                                value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                                                message: "Invalid email address",
                                                            }
                                                        } }
                                                        render={ ({ field, fieldState }) => (
                                                            <InputText id={ field.email } { ...field }
                                                                className={ `w-100 form-control footer-form ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
                                                        ) } />
                                                </span>
                                                { getFormErrorMessage('email', errors) }
                                            </div>

                                            <div className="form-group footer-form-group mb-2">
                                                <label htmlFor="company">Company</label>
                                                <span className="p-float-label">
                                                    <Controller name="company_name" control={ control }
                                                        rules={ {
                                                            required: 'company name  is required!',
                                                        } }
                                                        render={ ({ field, fieldState }) => (
                                                            <InputText id={ field.company_name } { ...field }
                                                                className={ `w-100 form-control footer-form ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
                                                        ) } />
                                                </span>
                                                { getFormErrorMessage('company_name', errors) }
                                            </div>

                                            <div className="form-group footer-form-group mb-2">
                                                <label htmlFor="phone">Phone</label>
                                                <span className="p-float-label">
                                                    <Controller
                                                        control={ control }
                                                        name="phone"
                                                        rules={ {
                                                            required: "Phone number is required!",
                                                            minLength: {
                                                                value: 10,
                                                                message: "Phone number too short!",
                                                            },
                                                            maxLength: {
                                                                value: 13,
                                                                message: "Phone number too long!",
                                                            }
                                                        } }
                                                        render={ ({ field: { ref, ...field }, fieldState }) => (
                                                            <PhoneInput
                                                                { ...field }
                                                                inputExtraProps={ {
                                                                    ref,
                                                                    required: true,
                                                                    autoFocus: true
                                                                } }
                                                                countryCodeEditable={ false }
                                                                specialLabel={ "Player Mobile Number" }
                                                                inputClass={ `w-100 py-3 form-control footer-form py-3 ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                                                containerClass={ `w-100  ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                                            />
                                                        ) }
                                                    />
                                                </span>
                                                { getFormErrorMessage('phone', errors) }
                                            </div>


                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group footer-form-group">
                                                <label htmlFor="msg">Your Message</label>
                                                <span className="p-float-label">
                                                    <Controller name="msg" control={ control }
                                                        rules={ { required: 'message is required!' } }
                                                        render={ ({ field, fieldState }) => (
                                                            <textarea id={ field.name }  { ...field }
                                                                className={ `w-100 form-control footer-form pb-4 pt-3 ${ classNames({ 'p-invalid': `border-danger ${ fieldState.invalid }` }) }` }
                                                                name="msg" rows="9"
                                                            ></textarea>
                                                        ) } />
                                                </span>
                                                { getFormErrorMessage('msg', errors) }
                                            </div>
                                        </div>
                                    </div>

                                    <button type="submit" className="mx-auto d-block mt-3 footer-button">
                                        Send message
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>)
}

export default Footer