import React from 'react'

import check from "../../../assets/img/Landing/check.gif";
import sec6 from "../../../assets/img/Landing/sec6.png";

function Features () {
    return (
        <section className="bg-light py-5 sec6-margin">
            <div className="container">
                <div className="row">
                    <div className="col-md-7 sec6">
                        <h2>System Additional Features</h2>
                        <p className="mt-5">
                            Our system is secure, scalable &amp; ready to satisfy all your
                            business needs!
                        </p>
                        <div className="row mt-5">
                            <div className="col-12 mb-3">
                                <div className="d-flex align-items-center sec6-content">
                                    <img src={ check } width="25" alt="check" className="icon-img me-2" />
                                    <p className="mb-0">
                                        <span>Content Auto-Update ; </span> Media is updated
                                        immediately with zero delay!
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 mb-3">
                                <div className="d-flex align-items-center sec6-content">
                                    <img src={ check } width="25" alt="check" className="icon-img me-2" />
                                    <p className="mb-0">
                                        <span>Offline Content ; </span> No more stream
                                        interruption from internet disconnecting!
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 mb-3">
                                <div className="d-flex align-items-center sec6-content">
                                    <img src={ check } width="25" alt="check" className="icon-img me-2" />
                                    <p className="mb-0">
                                        <span>Security ; </span> Hosted on Amazon’s S3, ServerTLS,
                                        firewall and password policies, Player lock-down & storage
                                        encryption
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 mb-3">
                                <div className="d-flex align-items-center sec6-content">
                                    <img src={ check } width="25" alt="check" className="icon-img me-2" />
                                    <p className="mb-0">
                                        <span>Force Play ; </span>Push unscheduled content to your
                                        devices on spot!
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 mb-3">
                                <div className="d-flex align-items-center sec6-content">
                                    <img src={ check } width="25" alt="check" className="icon-img me-2" />
                                    <p className="mb-0">
                                        <span>Live Mirroring ; </span> Monitor your campaigns live
                                        through your dashboard!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <img src={ sec6 } className="img-fluid" alt="Responsive Image" />
                    </div>
                </div>
            </div>
        </section>)
}

export default Features