import React, { useEffect, useState } from 'react'
import { useForm, Controller } from "react-hook-form";

import { useDataTableContext } from '../../../Contexts/DataTableContext';
import { getFormErrorMessage } from '../../../../config/global';
import { useGlobalContext } from '../../../Contexts/GlobalContext';
import { useCustomToast } from '../../../Contexts/ToastContext';
import axiosInstance from '../../../../config/Axios';

import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Spinner } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import Select from 'react-select';

let settings = {
  ONE_WAY: {
    is_camera_enable: 0,
    is_mic_enable: 0
  },
  TWO_WAY: {
    is_camera_enable: 1,
    is_mic_enable: 1
  },
  MULTIPLE: {
    is_camera_enable: 0,
    is_mic_enable: 1
  }
}

function CreateBroadcast () {

  const { openDialog, dialogHandler, disableBtn, setDisableBtn, subLoaders, loadingHandler } = useGlobalContext();
  const { control, formState: { errors }, handleSubmit, getValues } = useForm();
  const { setReload } = useDataTableContext();
  const { showToast } = useCustomToast();

  const [devicesOption, setDevicesOption] = useState([]);

  const typeOptions = [
    { label: "One Way", value: "ONE_WAY" },
    { label: "Two way", value: "TWO_WAY" },
    { label: "Multiple", value: "MULTIPLE" },
  ];

  useEffect(() => {
    loadingHandler("createLoading")
    axiosInstance.get(`/company/devices/all`)
      .then((result) => {
        let data = result?.data?.data;
        data.map(device =>
          setDevicesOption((prev) => [...prev, {
            label: device.name,
            value: device.id,
          }]))
        loadingHandler("createLoading")
      })
      .catch((error) => {
        loadingHandler("createLoading")
      })
  }, [])

 

  const createHandler = (data) => {
    setDisableBtn(true);

    axiosInstance.post(`/streams/create`,
      {
        name: data.name,
        type: data.type,
        senders: [data.senders],
        receivers: getValues('receivers').map(option => option.value),
        senders_options: settings["TWO_WAY"],
        receivers_options: settings[data.type]
      })
      .then((result) => {
        dialogHandler("createBroadcastDialog")
        loadingHandler("reload");
        setReload(true);
        showToast('success', "Broadcast Creation", "Broadcast created successfully!");
        setDisableBtn(false);
      })
      .catch((error) => {
        setDisableBtn(false);
      })
  }


  return (
    <Dialog visible={ openDialog.createBroadcastDialog }
      style={ { width: '42%' } }
      breakpoints={ { '960px': '95vw' } }
      header="Broadcast Creation"
      modal className="p-fluid"
      onHide={ () => dialogHandler("createBroadcastDialog") }
    >
      <form onSubmit={ handleSubmit(createHandler) } className=" d-flex flex-column justify-content-center">
        {
          subLoaders.createLoading ?
            <div className="col-12 d-flex justify-content-center p-4 border-top border-gray">
              <Spinner as="span" variant="primary" size='lg' animation="border" role="status" className='mx-3 my-5 ' aria-hidden="true" />
            </div>
            :
            devicesOption.length > 0 ?
              <>
                <div className="col-12 d-flex flex-column  justify-content-center py-4 border-top border-gray">
                  {/* Name */ }
                  <div className='mb-3 px-4'>
                    <div className="field ">
                      <label className="form-label"> Name </label>
                      <span className="p-float-label">
                        <Controller name="name" control={ control }
                          rules={ { required: 'Name is required.' } }
                          render={ ({ field, fieldState }) => (
                            <InputText
                              id={ field.name }
                              { ...field }
                              autoFocus
                              inputRef={ field.ref }
                              className={ `w-100  ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                            />
                          ) } />
                      </span>
                      { getFormErrorMessage('name', errors) }
                    </div>
                  </div>

                  {/* Type */ }
                  <div className='mb-3 px-4'>
                    <label htmlFor="" className='mb-2 form-label'>type</label>
                    <Controller name="type" control={ control }
                      rules={ { required: "type is required!" } }
                      render={ ({ field, fieldState }) => (
                        <Dropdown
                          id={ field.name } { ...field }
                          value={ field.value }
                          options={ typeOptions }
                          onChange={ (e) => { field.onChange(e.value) } }
                          optionLabel="label"
                          optionValue="value"
                          inputRef={ field.ref }
                          placeholder="select..."
                          className={ `w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                        />
                      )
                      } />
                    { getFormErrorMessage('type', errors) }
                  </div>

                  {/* Hosted  Device */ }
                  <div className='mb-3 px-4'>
                    <label htmlFor="" className='mb-2 form-label'>Hosted Device</label>
                    <Controller name="senders" control={ control }
                      rules={ { required: "Hosted device is required!" } }
                      render={ ({ field, fieldState }) => (
                        <Dropdown
                          id={ field.name } { ...field }
                          value={ field.value }
                          options={ devicesOption }
                          onChange={ (e) => { field.onChange(e.value) } }
                          optionLabel="label"
                          optionValue="value"
                          inputRef={ field.ref }
                          placeholder="select..."
                          className={ `w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                        />
                      )
                      } />
                    { getFormErrorMessage('senders', errors) }
                  </div>

                  {/* Receiver  Devices */ }
                  <div className='mb-3 px-4'>
                    <label htmlFor="" className='mb-2 form-label'>Receiver Device</label>
                    <Controller name="receivers" control={ control }
                      rules={ { required: "Receiver device is required!" } }
                      render={ ({ field, fieldState }) => (
                        <Select
                          closeMenuOnSelect={ false }
                          { ...field }
                          options={ devicesOption }
                          onChange={ (selectedOptions) => {
                            field.onChange(selectedOptions);  
                          } }
                          isMulti
                          placeholder="Select..."
                        />
                      ) }
                    />
                    { getFormErrorMessage('receivers', errors) }
                  </div>
                </div>
              </>
              :
              <div className="d-flex p-5 justify-content-center ">
                <span className='   bg-label-secondary fs-4 px-5 text-black p-3'>
                  There are no online devices!
                </span>
              </div>
        }
        <div className="col-12 text-center d-flex  align-items-end  justify-content-center py-4 border-top border-gray">
          <button type="reset" className="btn btn-label-secondary me-3" disabled={ disableBtn ? true : false }
            data-bs-dismiss="modal" aria-label="Close" onClick={ () => dialogHandler("createBroadcastDialog") }>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary  me-1" disabled={ disableBtn || subLoaders.createLoading || devicesOption.length == 0 } >
            {
              disableBtn ?
                <Spinner variant="primary" as="span" animation="border" size="sm" role="status" className='mx-3' aria-hidden="true" />
                :
                "Save"
            }
          </button>
        </div>

      </form>
    </Dialog>
  )
}

export default CreateBroadcast