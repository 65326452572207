import React, { useState, useEffect } from 'react';

import '../../../../assets/vendor/css/pages/page-auth.css';
import 'react-phone-input-2/lib/style.css'

import { useProfileContext } from '../../../Contexts/ProfileContext';
import { useGlobalContext } from '../../../Contexts/GlobalContext';
import { useCustomToast } from '../../../Contexts/ToastContext';
import axiosInstance from '../../../../config/Axios';
import ProfileForm from './ProfileForm';

function UserProfile () {
    const { newImage } = useProfileContext();
    const { setProfileImage, loadingHandler, companyDetails } = useGlobalContext();
    const { showToast } = useCustomToast();

    const userInfo = {
        "name": companyDetails?.user?.name,
        "phone": companyDetails?.user?.phone,
        "email": companyDetails?.user?.email
    };

    const submitHandler = (data) => {
        var formData = new FormData();

        formData.append("name", data.name);
        formData.append("profile_img", newImage.user);
        formData.append("phone", data.phone);

        axiosInstance.post(`/users/update`, formData)
            .then((result) => {
        

                setProfileImage(result.data?.data?.image)
                localStorage.setItem("name", result.data?.data?.name);
                localStorage.setItem("image", result.data?.data?.image);
                localStorage.setItem("phone", result.data?.data?.phone);

                showToast('success', 'Update User Profile', 'Profile Updated Successfully!');
                loadingHandler("companyDetails")

            }).catch((error) => {
                showToast('error', 'Update User Profile', error?.response?.data?.message);
            });
    };

    return (
        <ProfileForm profileType={ "user" } submitHandler={ submitHandler } data={ userInfo } />
    )
}

export default UserProfile