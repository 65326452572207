import { useRef } from "react";
import { useContext, createContext, useState } from "react";

const Gallery = createContext({});

export const GalleryProvider = (props) => {
    const [rerender, setRerender] = useState(false);
    const [capacityAvailability, setCapacityAvailability] = useState(false);

    // For pluck delete media
    const [multiSelectCheck, setMultiSelectCheck] = useState(false);
    const [selectedItem, setSelectedItem] = useState("");
    const [layout, setLayout] = useState("list");
    const [idsList, setIdsList] = useState([]);
 

    return (
        <Gallery.Provider value={ {
            capacityAvailability, setCapacityAvailability,
            multiSelectCheck, setMultiSelectCheck,
            selectedItem, setSelectedItem,
            rerender, setRerender,
            idsList, setIdsList,
            layout, setLayout,
        } }>
            { props.children }
        </Gallery.Provider>
    )

}

export const useGalleryContext = () => {
    return useContext(Gallery);
}
