import React, { useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";

import '../../../../assets/vendor/css/pages/page-auth.css';
import 'react-phone-input-2/lib/style.css'

import { getFormErrorMessage } from '../../../../config/global';
import { useProfileContext } from '../../../Contexts/ProfileContext';
import { useGlobalContext } from '../../../Contexts/GlobalContext';

import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import PhoneInput from 'react-phone-input-2'

function ProfileForm ({ profileType, submitHandler, data }) {

    const { previewImg, setPreviewImg, newImage, setNewImage } = useProfileContext();
    const { companyDetails } = useGlobalContext();
    const { formState: { errors }, reset, handleSubmit, control } = useForm();

    const previewImage = (e) => {
         setPreviewImg(prev => ({ ...prev, [profileType]: URL.createObjectURL(e.target.files[0]) }))
        setNewImage(prev => ({ ...prev, [profileType]: e.target.files[0] }));
    }

    const cancelHandler = (e) => {
         if (profileType == "company") {
            setPreviewImg(prev => ({ ...prev, [profileType]: companyDetails?.company?.logo ?? prev[profileType] }))
        } else {
            setPreviewImg(prev => ({ ...prev, [profileType]: localStorage.getItem("image") ?? prev[profileType] }))
        }
    }

    useEffect(() => {
        reset(data)
    }, [])
 
    return (
        <form onSubmit={ handleSubmit(submitHandler) } className="mb-3" encType="multipart/form-data" >
            <div className="card mb-4">
                <h5 className="card-header text-capitalize">{ profileType } Profile Details</h5>
                {/* Account */ }
                <div className="card-body">
                    <div className="d-flex align-items-start align-items-sm-center gap-4">
                        <img src={ previewImg[profileType] } alt="user-avatar" accept="image/png, image/jpg, image/jpeg" className="d-block rounded" height={ 100 } width={ 100 } id="uploadedAvatar" />
                        <div className="button-wrapper">
                            <label htmlFor={ `${ profileType }_upload` } className="btn btn-primary me-2 mb-4" tabIndex={ 0 }>
                                <span className="d-none d-sm-block">Choose new photo</span>
                                <i className="bx bx-upload d-block d-sm-none" />
                                <Controller name="name" control={ control }
                                    render={ ({ field, fieldState }) => (
                                        <input onChange={ previewImage } type="file" id={ `${ profileType }_upload` }  hidden accept="image/png, image/jpeg" />
                                    ) } />
                            </label>
                            <button type="button" className="btn btn-outline-secondary account-image-reset mb-4"
                                onClick={ cancelHandler }>
                                <i className="bx bx-reset d-block d-sm-none" />
                                <span className="d-none d-sm-block" >Cancel</span>
                            </button>
                            <p className="text-muted mb-0">Allowed JPG, JPEG or PNG.</p>
                        </div>
                    </div>
                </div>

                <hr className="my-0" />
                <div className="card-body">
                    <div className="d-flex flex-wrap">
                        {/* Name */ }
                        <div className="mb-3 col-6  ">
                            <div className="field">
                                <label className="form-label">{ profileType } Name</label>
                                <span className="p-float-label mb-1">
                                    <Controller name="name" control={ control }
                                        rules={ { required: 'Name is required.' } }
                                        render={ ({ field, fieldState }) => (
                                            <InputText id={ field.name } { ...field } 
                                                className={ `w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
                                        ) } />
                                </span>
                                { getFormErrorMessage('name', errors) }
                            </div>
                        </div>

                        {/* phone */ }
                        <div className="mb-3 col-6  form-password-toggle">
                            <div className="field ps-3 ">
                                <label className="form-label">phone</label>
                                <span className="p-float-label mb-1">
                                    <Controller
                                        control={ control }
                                        name="phone"
                                        rules={ {
                                            required: "Phone number is required!",
                                            minLength: {
                                                value: 10,
                                                message: "Phone number too short!",
                                            },
                                            maxLength: {
                                                value: 13,
                                                message: "Phone number too long!",
                                            }
                                        } }
                                        render={ ({ field: { ref, ...field }, fieldState }) => (
                                            <PhoneInput
                                                { ...field }
                                                inputExtraProps={ {
                                                    ref,
                                                    required: true,
                                                    autoFocus: true
                                                } }
                                                // country={ countryCode.toLocaleLowerCase() }
                                                countryCodeEditable={ false }
                                                specialLabel={ "Player Mobile Number" }
                                                inputClass={ `w-100 py-3 ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                                containerClass={ `w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                            />
                                        ) }
                                    />
                                </span>
                                { getFormErrorMessage('phone', errors) }
                            </div>
                        </div>

                        {/* email */ }
                        <div className="mb-3 col-6">
                            <div className="field">
                                <label className="form-label">Email</label>
                                <span className="p-float-label">
                                    <Controller name="email" control={ control }
                                        rules={ {
                                            required: 'Email Address is required.',
                                            pattern: {
                                                value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                                message: "Invalid email address",
                                            }
                                        } }
                                        render={ ({ field, fieldState }) => (
                                            <InputText id={ field.email } { ...field }  disabled={ profileType == "user" }
                                                className={ `w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
                                        ) } />
                                </span>
                                { getFormErrorMessage('email', errors) }
                            </div>
                        </div>
                    </div>
                    <div className='col-12 d-flex justify-content-end' >
                        <button className="btn btn-primary d-grid  mt-3">Save Changes</button>
                    </div>
                </div>
                {/* /Account */ }
            </div>
        </form>
    )
}

export default ProfileForm