import React from "react";
import { useState } from "react";
import { Link } from 'react-router-dom';
import "../../assets/vendor/css/Landing.css";
import "./css/app.css";
import logo from "../../assets/img/Landing/logo.png";

import CentralizedManagement from "./components/CentralizedManagement";
import ContentManagement from "./components/ContentManagement";
import FlexibleContent from "./components/FlexibleContent";
import CoreFeature from "./components/CoreFeature";
import Features from "./components/Features";
import Solution from "./components/Solution";
import Pricing from "./components/Pricing";
import Footer from "./components/Footer";
import Header from "./components/Header";

const Landing = () => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const token    = localStorage.getItem('access_token');
    const username = localStorage.getItem('name');

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };

    return (
        <div className="landingBody">
            <nav className="navbar navbar-expand-lg navbar-light fixed-top">
                <div className="container">
                    <a className="navbar-brand" href="/">
                        <img src={ logo } className="img-fluid" alt="logo" />
                    </a>
                    <button className="navbar-toggler" type="button" onClick={ toggleNav }>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={ ` navbar-collapse ${ isNavOpen ? "show" : "" }` } id="navbarNav">
                        <ul className="navbar-nav ms-auto ">
                            <li className="nav-item nav-list">
                                <a className="nav-link" href="#feature">
                                    Features
                                </a>
                            </li>
                            <li className="nav-item nav-list">
                                <a className="nav-link" href="#solution">
                                    Solutions
                                </a>
                            </li>
                            <li className="nav-item nav-list">
                                <a className="nav-link" href="#pricing">
                                    Pricing
                                </a>
                            </li>
                        </ul>
                        <ul className="navbar-nav">
                            { token ? (
                                <>
                                    <li className="nav-item">
                                        <Link to="/dashboard" className="btn me-2 login-button" type="button">Dashboard</Link>
                                    </li>
                                    <li className="nav-item pt-2">
                                        <span className="welcome-button">Welcome, { username }</span>
                                    </li>
                                </>
                            ) : (
                                <>
                                    <li className="nav-item">
                                        <Link to="/login" className="btn me-2 login-button" type="button">Login</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/login" className="btn start-button" type="button">Start Today!</Link>
                                    </li>
                                </>
                            ) }

                        </ul>
                    </div>
                </div>
            </nav>

            <Header />
            <CoreFeature />
            <FlexibleContent />
            <CentralizedManagement />
            <ContentManagement />
            <Features />
            <Solution />
            <Pricing />
            <Footer />
        </div>
    );
};

export default Landing;
