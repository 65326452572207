import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from 'react-router-dom';
import RotationIcon from "../../../../assets/img/rotation_icon.png"

import { Card, Image } from 'react-bootstrap'
import { Tooltip } from 'primereact/tooltip';
import { SplitButton } from 'primereact/splitbutton';

import DeviceLastLoginListener from '../../../Services/DeviceLastLoginListener';
import FirebaseDeviceListener from '../../../Services/FirebaseDeviceListener';
import { useDeviceContext } from '../../../Contexts/DeviceContext';
import { useGlobalContext } from '../../../Contexts/GlobalContext';
import { useCustomToast } from '../../../Contexts/ToastContext';
import axiosInstance from '../../../../config/Axios';
import Rotation from './Rotation';

function DeviceCard ({ data, setRerender }) {
    const { setSelectedDevice, onlineDevices, setOnlineDevices, setDevice, setSelectedCampaign } = useDeviceContext();
    const { dialogHandler, deleteDialogHandler, setIsPrayerCampaign, companyDetails, setStepsBack, layer } = useGlobalContext();
    const { showToast } = useCustomToast();

    const navigate = useNavigate();

    const [counter, setCounter] = useState(10);
    const [realtimeData, setRealtimeData] = useState({
        "id": "",
        "name": "",
        "is_playing": "",
        "screen_angle": "",
    })

    useEffect(() => {
        setOnlineDevices(prev => ({ ...prev, [data.serial_name]: data.online }))
    }, [])

    useEffect(() => {
        if (counter === 0) {
            setTimeout(function () {
                setOnlineDevices(prev => ({ ...prev, [data.serial_name]: 0 }))
            }, 1000);
        }

        // exit early when we reach 0
        if (!counter) return;

        // save intervalId to clear the interval when the component re-renders
        var intervalId = setInterval(() => {
            setCounter(counter - 1);
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
    }, [counter]);

    useEffect(() => {
        if (onlineDevices[data.serial_name]) {
            setCounter(13)
        }

    }, [onlineDevices[data.serial_name]])


    // Delete Handler
    const confirmDeleteDevice = () => {
        setDevice(data);
        deleteDialogHandler("device");
    }

    const openEditDialog = () => {
        setDevice(data);
        dialogHandler("updateDevice");
    }

    const openAssignDialog = (data) => {
        setDevice(data);
        // Get previous selected campaign for the device
        axiosInstance.get(`/device/${ data.id }/info`).then((res) => {
            setSelectedCampaign(res?.data?.data?.campaigns[0]?.id)
        });
        dialogHandler("assignCampaignsDropDown");
    }

    const openAssignScheduleCampaignDialog = (data) => {
        setDevice(data);
        dialogHandler("assignScheduleCampaigns");
    }

    const playPause = (deviceID, action) => {
        axiosInstance.post(
            `/device/${ deviceID }/play/pause`,
            {
                "action": action
            }
        ).then((result) => {
            showToast('success', 'Pause Action', result?.data?.data?.msg);
        }).catch((error) => {
            showToast('error', 'Pause Action', error.response?.data?.message);
        });
    }

    const goToCampaign = () => {
        if (realtimeData.id != "") {
            setStepsBack(-2)
            axiosInstance.get(`/campaign/${ realtimeData.id }`)
                .then((res) => {
                    setIsPrayerCampaign(false)
                    sessionStorage.setItem('is_default_prayer', JSON.stringify(false));

                    if (res.data.data.is_interactive)
                        navigate(`/interactive/campaign/${ realtimeData.id }/setup`)
                    else
                        navigate(`/campaigns/${ realtimeData.id }/setup`)
                })
        }
    }

    const channelHandler = (deviceID) => {
        if (!companyDetails?.package?.features?.media_type?.includes("satellite")) {
            setSelectedDevice({
                id: deviceID,
                serial_name: data.serial_name,
                online: onlineDevices[data.serial_name],
                name: data.name
            });
            dialogHandler("createCampaign");
            navigate("/campaigns/list");
        }
    }

    const flushHandler = () => {
        axiosInstance.get(`/device/${ data.id }/screen`)
            .then((res) => {
                showToast('success', 'Device', res?.data?.data?.msg);
            }).catch(error => {
                showToast('error', 'Device', error?.response?.data?.message);
            });
    }

    const restartHandler = () => {
        axiosInstance.get(`/device/${ data?.id }/restart`)
            .then((res) => {
                showToast('success', 'Device', res?.data?.data?.msg);

            })
    }

    const items = [
        {
            label: <sapn className="fs-6 ">Assign campaign</sapn>,
            icon: 'bx bx-up-arrow-alt me-2 text-light',
            command: () => {
                openAssignDialog(data)
            }
        },
        {
            label: <sapn className="fs-6 "> Assign schedule </sapn>,
            icon: 'bx bx-time-five me-2 text-light',
            command: () => {
                openAssignScheduleCampaignDialog(data)
            },
            visible: companyDetails?.package?.features?.advance_content_schedule
        },
        {
            label: 'Assign channel',
            icon: 'bx bx-tv me-2 text-light',
            command: () => {
                channelHandler(data.id)
            },
            visible: data?.device_info?.satellite_is_available != 0 && companyDetails?.package?.features?.media_type?.includes("satellite")
        },
        {
            label: <sapn className="fs-6 ">Edit</sapn>,
            icon: 'pi pi-pencil text-light me-2',
            command: () => {
                openEditDialog()
            }
        },
        {
            label:  <sapn className="fs-6 ">{realtimeData.is_playing ? 'Pause' : "Play"}</sapn> ,
            icon: realtimeData.is_playing ? "bx bx-pause-circle me-2 text-light" : "bx bx-play-circle me-2 text-light",
            command: () => {
                playPause(data.id, realtimeData.is_playing ? "pause" : "play")
            }
        },
        {
            label: <sapn className="fs-6 ">Flush</sapn> ,
            icon: 'bx bx-help-circle me-2 text-light',
            command: () => {
                flushHandler()
            }
        },
        {
            label: <sapn className="fs-6 ">Restart</sapn> ,
            icon: 'fas fa-power-off me-2 text-light',
            command: () => {
                restartHandler()
            }
        },
        // {
        //     label: 'Setup', 
        //     icon: 'bx bx-slideshow me-2 text-light',
        //     command: () => {
        //         navigate('/devices/' + data.id + '/show')            }
        // }
    ];


    return (
        <>
            <Card className='my-2 d-flex flex-row flex-nowrap align-items-center py-3 pe-3 ' style={ { backgroundColor: '#f5f5f9' } }>
                <div className="col-1 text-center">
                    <i className="menu-icon tf-icons bx bx-desktop  fs-1 text-secondary mx-auto "></i>
                </div>
                <div className='col-1 d-flex flex-column'>
                    <h6 className='my-1 fw-bold'>{ data.name == "" ? "---" : data.name }</h6>
                </div>
                <div className={ `col-3 d-flex ${ realtimeData.name == "" ? "" : "cursor-pointer" }` } onClick={ () => goToCampaign() }>
                    <div className={ `my-1 fw-bold ${ realtimeData.name == "" ? "" : "cursor-pointer playing_now_container" } py-1 mx-4 px-2 w-100` } onClick={ () => goToCampaign() }>
                        <div className='fs-10 text-light text-uppercase'>now playing</div>
                        <div className='d-flex align-items-center '>
                            <i className="fas fa-bolt fs-8 me-1"></i>
                            <span> { realtimeData.name == "" ? "---" : realtimeData.name }</span>
                        </div>
                    </div>
                </div>

                <div className='col-1 d-flex'>
                    <span className={ `text-center p-2 badge ${ onlineDevices[data.serial_name] != 0 ? "bg-label-success" : "bg-label-danger" } ` }>{ onlineDevices[data.serial_name] ? "On-line" : "Off-line" }</span>
                </div>
                <div className='col-1 d-flex'>
                    <span className={ `text-center p-2  badge ${ data.status == "ACTIVE" ? "bg-label-success" : "bg-label-danger" } ` }>{ data.status }</span>
                </div>
                <div className='col-2 d-flex justify-content-center align-items-center'>
                    <Rotation rowData={ data } realtimeData={ realtimeData } />

                </div>

                <div className='col-3 d-flex justify-content-end'>
                    {/* Delete */ }
                    <Tooltip target={ `.delete-button-${ data.id }` } showDelay={ 100 } className="fs-8" />
                    <button className={ `btn btn-sm btn-icon delete-button-${ data.id }` }
                        data-pr-position="bottom" data-pr-tooltip="Delete"
                        onClick={ () => confirmDeleteDevice() } >
                        <i className="bx bx-trash text-light"></i>
                    </button>

                    <SplitButton
                        label={ null }
                        model={ items }
                        className="p-button-text p-button-primary p-button-sm mr-2 mb-2 p-button-secondary device_actions ms-3"
                        buttonClassName="d-none"
                        dropdownIcon="fas fa-ellipsis-v"

                    />

                    {/* Assign channel */ }
                    {/* {
                        data?.device_info?.satellite_is_available == 0 ? "" :
                            <>
                                <Tooltip target={ `.assign-channel-${ data.id }` } showDelay={ 100 } className="fs-8" />
                                <button className={ `btn btn-sm btn-icon assign-channel-${ data.id }` }
                                    data-pr-position="bottom"
                                    data-pr-tooltip={ `Assign channel` }
                                    onClick={ () => { channelHandler(data.id) } }
                                    disabled={ !companyDetails?.package?.features?.media_type?.includes("satellite") }
                                >
                                    <i className='bx bx-tv text-light' ></i>
                                </button>
                            </>
                    } */}


                </div>
            </Card>

            {/* Listen to campaign changes on device */ }
            <FirebaseDeviceListener
                setRealtimeData={ setRealtimeData }
                path={ "Devices/" + data.serial_name + "/DeviceState" }
                setRerender={ setRerender } />

            <DeviceLastLoginListener
                serialName={ data.serial_name }
                path={ "Devices/" + data.serial_name + "/lastLogin" } />

        </>
    )
}

export default DeviceCard