import React, { useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";

const ProtectedRouter = () => {
  if (localStorage.getItem("email_verified_at")) {
    if (localStorage.getItem("access_token") !== "null") {
      return <Outlet />;
    } else {
      return <Navigate to="/verify/email" />;
    }
  } else {
    return <Navigate to="/login" />;
  }
};

const ProtectedRouterTwo = () => {
  const token = localStorage.getItem("access_token");

  if (token) {
    return <Navigate to="/dashboard" />;
  } else {
    return <Outlet />;
  }
};

export { ProtectedRouter, ProtectedRouterTwo };
