import React, { useState } from 'react'
import LazyImage from '../../ShowMedia/LazyImage';

function MediaThumb ({ item }) {
    const [updatedItem, setUpdatedItem] = useState(item);

    return (
        <>
            <LazyImage media={ updatedItem } popupType={ "media_preview" } index={ item.id } styleClass={ "me-2 image_container" } />
        </>
    )
}

export default MediaThumb