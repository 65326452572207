import React, { useState, useEffect} from "react";
import { useParams } from "react-router-dom";
 
import { ProgressSpinner } from "primereact/progressspinner";
import { Checkbox } from "primereact/checkbox";

import BreadCrumb from "../../Media/components/MediaLayout/BreadCrumb";
import MediaCard from "../../Media/components/MediaLayout/MediaCard";
import FolderCard from "../../Media/components/MediaLayout/FolderCard";

import { useGlobalContext } from "../../Contexts/GlobalContext";
import { useCustomToast } from "../../Contexts/ToastContext";
import axiosInstance from "../../../config/Axios";

function MediaList () {

  const { layer, assign, setAssign, dialogHandler, setDisableBtn, campaignDetails, setRerenderList } = useGlobalContext();
  const { showToast } = useCustomToast();

  const [currentFolder, setCurrentFolder] = useState({});
  const [breadCrumb, setBreadCrumb] = useState([]);
  const [rerender, setRerender] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [ids, setIds] = useState([]);

  const { id } = useParams();

  const getData = (folder_id = "") => {
    setLoading(true);

    if (folder_id == "") {
      var url = `/media?section=MEDIA&layer=${ layer }&is_interactive=${ campaignDetails.is_interactive ? 1 : 0 }`;
    } else {
      var url = `/media?folder_id=${ folder_id }&section=MEDIA&layer=${ layer }&is_interactive=${campaignDetails.is_interactive ? 1 : 0}`;
    }

    axiosInstance.get(url)
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
        setRerender(false);
      })
  };

  useEffect(() => {
    if (assign) {
      setDisableBtn(true);

      if (window.location.pathname.includes("playlist")) {
        assignHandler(
          "/playlist/media/assign",
          "Playlist",
          {
            "list_media_ids": ids,
            "playlist_id": id,
            "layer": layer,
          });
      } else {
        assignHandler(
          "/campaign/media/assign",
          "Campaign",
          {
            "list_media_ids": ids,
            "campaign_id": id,
            "layer": layer,
          });
      }
    }
  }, [assign]);

  const assignHandler = (url, type, data) => {
    axiosInstance.post(url, data)
      .then((result) => {
        setDisableBtn(false);
        showToast('success', `Assign Media to ${ type }`, result?.data?.data?.msg);
        setRerenderList(true);
        dialogHandler("addContent")
      })
      .catch((error) => {
        setDisableBtn(false);
        if (error?.response?.status) {
          showToast('error', `Assign Media to ${ type }`, error?.response?.data?.message);
        }
        if (error?.response?.data?.errors)
        Object.values(error?.response?.data?.errors).forEach((error) => {
          showToast('error', `Assign Media to ${ type }`, error[0]);
        });
      });
    setAssign(false);
  };

  const onMediaChange = (e) => {
    let selectedMedia = [...ids];

    if (e.checked) selectedMedia.push(e.value);
    else selectedMedia.splice(selectedMedia.indexOf(e.value), 1);

    setIds(selectedMedia);
  };

  //fetch data  when mount component
  useEffect(() => {
    setCurrentFolder({
      id: "",
      name: "",
    });
    getData();
  }, []);

  return (
    <>
      <div className="d-flex flex-column  scroll_container scroll_div px-3  w-100">
        {
          breadCrumb.length != 0 &&
          <div className=" my-3 d-flex justify-content-start w-75">
            <BreadCrumb
              setMedia={ setData }
              getData={ getData }
              breadCrumb={ breadCrumb }
              setBreadCrumb={ setBreadCrumb }
              setCurrentFolder={ setCurrentFolder }
            />
          </div>
        }
        <div className="d-flex flex-wrap py-3 w-100">
          {
            loading ?
              <ProgressSpinner
                style={ { width: "60px", height: "60px" } }
                strokeWidth="3"
                fill="var(--surface-ground)"
                animationDuration="1s" />
              :
              data.length == 0 ?
                <h5 className="py-5 text-center w-100">No media assigned yet!</h5>
                :
                <>
                  {
                    data.map((item, index) => {
                      return (
                        <div key={ item.id } className={ `col-12 media_list ${ index + 1 != data.length ? "border-bottom border-gray" : "" } mx-2 py-2 media position-relative` } >
                          {
                            item.hasOwnProperty("path") ?
                              <div className="col-12 d-flex align-items-center justify-content-between my-1 " key={ `${ item.type }-${ index }` }>
                                <label htmlFor={ item.id } className="col-11 d-flex align-items-center" >
                                  <MediaCard  item={ item } displayAction={ false } />
                                </label>
                                <Checkbox inputId={ item.id } name="media" className="me-3" value={ item.id } onChange={ onMediaChange } checked={ ids.indexOf(`${ item.id }`) !== -1 } />
                              </div>
                              :
                              <>
                                <div className="col-12  d-flex align-items-center my-1 " key={ `${ item.type }-${ index }` } >
                                  <FolderCard
                                    setCurrentFolder={ setCurrentFolder }
                                    displayAction={ false }
                                    getData={ getData }
                                    item={ item }
                                    setRerender={ setRerender }
                                    layout="list"
                                    setBreadCrumb={ setBreadCrumb }
                                    breadCrumb={ breadCrumb }
                                  />
                                </div>
                              </>
                          }
                        </div>
                      )
                    })
                  }
                </>
          }
        </div>
      </div>
    </>
  );
}

export default MediaList;
