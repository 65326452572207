import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

import { useGlobalContext } from "../Contexts/GlobalContext";
import logo from "../../assets/img/logo.png";
import "./css/Menu.css";

function MenuList () {

    const { companyDetails } = useGlobalContext();
    const location = useLocation();

    const [isMenuOpen, setIsMenuOpen] = useState(location.pathname.includes("/campaign") ? true : false);
    const [pathName, setPathName] = useState("");

    useEffect(() => {
        const pathname = window.location.pathname;
        const firstSlashIndex = pathname.indexOf('/');
        const secondSlashIndex = pathname.indexOf('/', firstSlashIndex + 1);
        const contentBetweenSlashes = pathname.substring(firstSlashIndex + 1, secondSlashIndex);

        setPathName(contentBetweenSlashes);
    }, [])

    var features = companyDetails?.package?.features

    const menuItem = [
        {
            path: "/dashboard",
            name: "Dashboard",
            icon: <i className="menu-icon bx bx-home-circle mb-1" />,
            visible: true,
        },
        {
            path: "/media/gallery",
            name: "Media",
            icon: <i className="menu-icon tf-icons bx bx-images"></i>,
            visible: true,

        },
        {
            path: "/links",
            name: "Links",
            icon: <i className="menu-icon tf-icons bx bx-link"></i>,
            visible: true,

        },
        {
            path: "/devices/list",
            name: "Devices",
            icon: <i className="menu-icon tf-icons bx bx-desktop"></i>,
            visible: true,

        },
        // {
        //     path: "5",
        //     name: "Location",
        //     icon: <i className="menu-icon tf-icons bx bx-map-alt"></i>,
        // },
        {
            path: "/campaign",
            name: "Campaign",
            icon: <i className="fa-solid fa-bullhorn menu-icon fs-6"></i>,
            submenu: [
                {
                    path: "/campaigns/list",
                    name: "Campaign",
                    icon: <i className="fa-solid fa-bullhorn menu-icon fs-6"></i>,
                    visible: true,

                },
                {
                    path: "/interactive/campaigns",
                    name: "Interactive",
                    icon: <i className="fa-solid fa-hand-pointer menu-icon fs-6"></i>,
                    visible: features?.interactive_action

                }
            ]
        },
        {
            path: "/playlist/list",
            name: "Playlist",
            icon: <i className="bx bxs-playlist menu-icon"></i>,
            visible: true,
        },
        {
            path: "/reports",
            name: "Reports",
            icon: <i className="menu-icon tf-icons bx bx-carousel" />,
            visible: true,

        },
        // {
        //     path: "5555",
        //     name: "Health",
        //     icon: <i className="menu-icon tf-icons bx bxs-heart"></i>,
        //     visible: true,
        // },
        {
            path: "/users",
            name: "Users",
            icon: <i className="menu-icon tf-icons bx bx-user open" />,
            visible: features?.add_users,
        },
        // {
        //     path: "55555",
        //     name: "Invoices",
        //     icon: <i className="menu-icon tf-icons bx bx-food-menu"></i>,
        //     visible: true,

        // },
        {
            path: "/apps",
            name: "Apps",
            icon: <i className="menu-icon fa-solid fa-microchip"></i>,
            visible: features?.prayer_app || features?.live_meeting_access,
        }
    ];

    const NavbarLink = ({ item, index, subItem = false }) => {
        return item.visible &&
            <NavLink to={ item.path } key={ index }
                className={ `menu-item ${ pathName == item.name.toLocaleLowerCase() && ("submenu" in item) ? "active" : "" }` }
            >
                <li className="d-flex flex-row  p-2 menu-link">
                    <div>{ item.icon }</div>
                    <div>{ item.name }</div>
                </li>
            </NavLink>

    }

    const SubMenu = ({ item, index }) => {
        return (
            <>
                <div onClick={ () => setIsMenuOpen(!isMenuOpen) } key={ index }
                    className={ `menu-item ${ pathName == item.name.toLocaleLowerCase() && ("submenu" in item) ? "active" : "" }` }>
                    <li className="d-flex flex-column align-items-start p-2 menu-link cursor-pointer" >
                        <div className="d-flex flex-row  w-100">
                            <div >{ item.icon }</div>
                            <div className="d-flex justify-content-between align-items-center w-100"  >
                                <span>{ item.name }</span>
                                {
                                    isMenuOpen ?
                                        <i className="fas fa-chevron-down fs-10"></i>
                                        :
                                        <i className="fas fa-angle-right fs-10"></i>
                                }
                            </div>
                        </div>
                    </li>
                </div>
                {
                    isMenuOpen &&
                    <ul className="ps-3 d-block w-100 flex-grow-0 h-auto  menu-inner">
                        {
                            item.submenu.map((item, index) => {
                                return item.visible && <NavbarLink item={ item } index={ index } subItem={ true } />
                            })
                        }
                    </ul>
                }
            </>
        )
    }

    return (
        <>
            <div className="app-brand demo">
                <Link to="/" className="app-brand-link gap-2 mt-3 mb-3" style={ { margin: "auto" } }>
                    <img src={ logo } className="img-fluid" width={ 200 } alt="" />
                </Link>
            </div>

            <div className="menu-inner-shadow"></div>

            {/* =============== Menu Sorting Code =============== */ }
            <ul className="menu-inner">
                { menuItem.map((Item, index) => {

                    return "submenu" in Item ?
                        <SubMenu item={ Item } index={ index }  key={ index } />
                        :
                        <NavbarLink item={ Item } index={ index }  key={ index }/>

                }) }
            </ul>
        </>
    )
}

export default MenuList