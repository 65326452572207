import React from 'react'

import GroupCard from './GroupCard'

function GroupsList ({ data, ids, setIds, pageNum, lastPage, setLastElement, loading }) {
  return (
    <>
      {
        data.length == 0 && !loading ?
          <div className='w-100 text-center'>
            There is no groups added yet!
          </div>
          :

          data.length > 0 && data.map((item, index) => {
            return index === data.length - 1 && pageNum <= lastPage ?
              (
                <div className="col-12 d-flex align-items-center justify-content-between " ref={ setLastElement } key={ `${ item.id }-${ index }` }>
                  <GroupCard ids={ ids } setIds={ setIds } item={ item } />
                </div>
              ) : (
                <div className={ `col-12 d-flex align-items-center justify-content-between my-1 
                                ${ data.length == index + 1 ? "" : "border-bottom" } pb-2` } key={ `${ item.id }-${ index }` } >
                  <GroupCard ids={ ids } setIds={ setIds } item={ item } />
                </div>
              )
          })
      }

    </>
  )
}

export default GroupsList