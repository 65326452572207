import React, { useEffect, useState } from "react";

import { useGalleryContext } from "../../Contexts/GalleryContext";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import { LazyLoadImage } from "react-lazy-load-image-component";
import PreviewMedia from "./PreviewMediaDialog";

const LazyImage = ({ media, popupType, styleClass = "", setMediaSettingPopup }) => {
  const { openDialog, dialogHandler } = useGlobalContext();
  const { selectedItem, setSelectedItem } = useGalleryContext();
  const [thumb, setThumb] = useState();

  const selectDialog = () => {
    if (popupType == "media_preview") {
      setSelectedItem(media.id)
      dialogHandler("previewMedia");
    } else {
      setMediaSettingPopup(true);
    }
  };

  useEffect(() => {
    let oldUrl  = "http://storage-popuppush-copy.s3.eu-west-1.amazonaws.com";
    let oldUrl2 = "http://storage-popuppush.s3.me-south-1.amazonaws.com";
    let newUrl  = "https://storage.popuppush.com";

    if (media?.thumb?.includes(oldUrl))
      setThumb((media?.thumb)?.replace(oldUrl, newUrl))
    else if (media?.thumb?.includes(oldUrl2))
      setThumb((media?.thumb)?.replace(oldUrl2, newUrl))
    else
      setThumb(media?.thumb)
  }, [media])


  return (
    <>
      <LazyLoadImage src={ thumb }
        className={ styleClass != "" ? `${ styleClass } cursor-pointer` : `mb-2 mt-4 item cursor-pointer` }
        alt="item" onClick={ selectDialog } />

      { selectedItem == media.id && openDialog.previewMedia && <PreviewMedia data={ media } /> }
    </>
  );
};

export default LazyImage;
