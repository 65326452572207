
const defaultRssSetting = {
    bgColor: '#dddddd',
    textColor: '#000000ff',
    position: "BOTTOM",
    speed: 1,
    direction: "left",
    separator: "",
    newSeparator: {},
    duration: 20
}

// RSS position dropdown options
const positionOptions = [
    { label: "Full Screen", value: "FULL_SCREEN" },
    // { label: "Right", value: "RIGHT" },
    // { label: "Left", value: "LEFT" },
    // { label: "Top", value: "TOP" },
    { label: "Bottom", value: "BOTTOM" },
    // { label: "Breaking News", value: "BOTTOM_BAR" },
    // { label: "Top Left", value: "TOP_LEFT" },
    // { label: "Top Right", value: "TOP_RIGHT" },
    // { label: "Bottom Left", value: "BOTTOM_LEFT" },
    // { label: "Bottom Right", value: "BOTTOM_RIGHT" },
];

const designOptions = [
    { label: "Original", value: "original" },
    { label: "Default", value: "default" },
];

const blendingOptions = [
    { label: "full", value: "full" },
    { label: "Medium", value: "medium" },
    { label: "None", value: "none" },
];

const perPageOptions = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
];

const directionOptions = [
    { icon: <i className="fas fa-arrow-left" ></i>, value: 'left' },
    { icon: <i className="fas fa-arrow-right" ></i>, value: 'right' },
    // { icon: <i className="fas fa-arrow-up" ></i>, value: 'up' },
    // { icon: <i className="fas fa-arrow-down" ></i>, value: 'down' }
];

const speedOptions = [
    { label: '1x', value: 1 },
    { label: '2x', value: 2 },
    { label: '3x', value: 3 },
];

const rotationOptions = [
    { label: "0ْ ", value: "0" },
    { label: "90ْ ", value: "90" },
    { label: "180ْ ", value: "180" },
    { label: "270 ", value: "270" },
];

// ReactPrime file input options 
const chooseOptions = {
    className: 'upload_separator',
    label: 'click to upload image...',
    style: {
        background: 'transparent',
        color: 'gray',
        width: "100%",
        border: "2px dashed #D9DEE3",
        fontWeight: "normal",
        fontSize: "14px",

    }
};

export {
    defaultRssSetting,
    directionOptions,
    blendingOptions,
    positionOptions,
    rotationOptions,
    perPageOptions,
    designOptions,
    speedOptions,
    
    chooseOptions
}