import React from 'react'
import section3 from "../../../assets/img/Landing/section3.png";

function FlexibleContent () {
    return (
        <section className="bg-light">
            <div className="container">
                <div className="row sec3">

                    <div className="col-lg-6 py-5 sec3-content">
                        <h2>Flexible Content</h2>
                        <p className="lead">
                            Display HD videos, Images, Audio files, Web links, Satellite
                            channels, widgets, HDMI feed and much more limitless options.
                            You will have your own media gallery then you can use to create
                            various playlists and use them to create campaigns that serves
                            your business goals.
                        </p>
                    </div>
                    <div className="col-lg-6 p-0 mx-auto">
                        <img src={ section3 } alt="Your Image" className="mx-auto" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FlexibleContent