import React from 'react'

function Solution () {
    return (
        <div className="container sec7" id="solution">
            <div className="row">
                <div className="col-12 sec8">
                    <span className="d-block mb-3">Solution</span>
                    <h2 className="mb-4">Digital Signages That Solve Your Problems!</h2>
                    <p className="mb-5">
                        Enjoy the limitless use cases that serve different industries
                        fully customizable and effective to fit your business goals.
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-md-3 mb-4">
                    <div className="card card-sec8 first_card position-relative">
                        <div className="card__thumb bg-transparent">
                        </div>
                        <div className="card__body bg-white">
                            <h2 className="card__title">
                                Entertainment
                            </h2>
                            <p className="card__description">
                                Whether you want to entertain, direct, promote amenities,
                                delight, or anything else, the Poppulo Harmony platform gets
                                your messages to the right people at just the right moment in
                                time.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-md-3 mb-4">
                    <div className="card second_card card-sec8">
                        <div className="card__thumb bg-transparent">
                        </div>
                        <div className="card__body bg-white">
                            <h2 className="card__title">
                                Promotional
                            </h2>
                            <p className="card__description desc1">
                                Create an engaging brand experience by unifying the look
                                throughout your stores and reinforcing your company’s values,
                                philosophy, & personality.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-md-3 mb-4">
                    <div className="card third_card card-sec8">
                        <div className="card__thumb bg-transparent"></div>
                        <div className="card__body bg-white">
                            <h2 className="card__title">
                                Corporate
                            </h2>
                            <p className="card__description">
                                Share company updates and KPIs. Also, boost employee
                                engagement through motivational quotes. In reality, there are
                                endless options for using internal digital signage for
                                communication.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-md-3 mb-4">
                    <div className="card fourth_card card-sec8">
                        <div className="card__thumb bg-transparent"></div>
                        <div className="card__body bg-white">
                            <h2 className="card__title">
                                Hospitality
                            </h2>
                            <p className="card__description desc2">
                                Engage customers and employees everywhere. Transform your
                                lobbies, meeting spaces, lounges, and more with dynamic and
                                interactive digital signage.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}

export default Solution