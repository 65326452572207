import React, { createContext, useContext, useState, useRef } from 'react';
import { Toast } from 'primereact/toast';

const ToastContext = createContext();

export function useCustomToast () {
    return useContext(ToastContext);
}

export const GlobalToastProvider = ({ children }) => {
    const toastRef = useRef(null);

    const showToast = (severity, summary, detail,content = null) => {
        if (content == null)
            toastRef.current.show({ severity: severity, summary: summary, detail: detail, life: 3000 });
        else
            toastRef.current.show({ severity: severity, summary: summary, detail: detail, content: content, sticky: true });

    };

    return (
        <ToastContext.Provider value={ { showToast } }>
            <Toast ref={ toastRef } position="bottom-center"></Toast>
            { children }
        </ToastContext.Provider>
    );
}

