import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";

import { useGlobalContext } from "../pages/Contexts/GlobalContext";
import axiosInstance from "../config/Axios";

const CompanyDetailsRouter = () => {

    const {
        setCompanyDetails,
        setLayerOptions,
        setRerenderSubscription,
        rerenderSubscription,
        setSubscriptionInfo,
        setUserInfo,
        loadingHandler,
        isAuthenticated,
        setShowUpgrade
    } = useGlobalContext();

    useEffect(() => {
 
            fetchData();
    }, [ ]);

    useEffect(() => {
        if (rerenderSubscription) {
            fetchData();
        }
    }, [rerenderSubscription]);

    const fetchData = () => {
        if (isAuthenticated) {
            loadingHandler("companyDetails");
            setLayerOptions([]);
            axiosInstance.get(`/company/details`).then((result) => {
                let res = result?.data?.data;

                setCompanyDetails(res);
                setUserInfo(res?.user);
                loadingHandler("companyDetails");

                setSubscriptionInfo({
                     isProPackage: res?.package?.title.toLowerCase() == "professional",
                    isFinished: new Date() > new Date(res?.subscription?.end_date),
                    isYearly: res?.subscription?.period == "ONE_YEAR",
                    isFree: res?.package?.is_free == 1,
                });

                //Set allowed Layers for campaign and playlist setup
                res?.package?.features?.multiple_layer.map((layer) => {
                    setLayerOptions((prev) => [
                        ...prev,
                        {
                            name:
                                layer.charAt(0).toUpperCase() + layer.slice(1).toLowerCase(),
                            code: layer.toUpperCase(),
                        },
                    ]);
                });
                setRerenderSubscription(false);
            })
        }
    };

    return <Outlet />;

}

export { CompanyDetailsRouter }