import React, { useEffect } from 'react'
import moment from 'moment/moment';

import { db } from "../../config/firebase";
import { onValue, ref } from "firebase/database";
import { useDeviceContext } from '../Contexts/DeviceContext';

function DeviceLastLoginListener ({ path, serialName }) {
    const { selectedDevice, setSelectedDevice, onlineDevices, setOnlineDevices } = useDeviceContext();

    useEffect(() => {
        const query = ref(db, process.env.REACT_APP_APP_ENV + "/" + path);
        return onValue(query, (snapshot) => {
            const data = snapshot.val();

            if (snapshot.exists()) {
                const currenTime = moment(new Date());
                const timeEnd = moment(data);
                const diff = currenTime.diff(timeEnd);
                const diffDuration = moment.duration(diff);

                if (diffDuration.seconds() > 10) {
                    setOnlineDevices(prev => ({ ...prev, [serialName]: 0 }))
                    setSelectedDevice(prev => ({ ...prev, online: 0 }))
                }
                else {
                    setOnlineDevices(prev => ({ ...prev, [serialName]: Math.random() }))
                    setSelectedDevice(prev => ({ ...prev, online: Math.random() }))

                }
            }
        });

    }, [selectedDevice.id]);

    return (
        <></>
    )
}

export default DeviceLastLoginListener