import React, { useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import { getFormErrorMessage } from '../../../config/global';

import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Spinner } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';

import { useGlobalContext } from '../../Contexts/GlobalContext';
import { useCustomToast } from '../../Contexts/ToastContext';
import axiosInstance from '../../../config/Axios';

const UpdateCampaign = ({ campaign, setReload }) => {
    const { showToast } = useCustomToast();
    const { disableBtn, setDisableBtn, openDialog, dialogHandler } = useGlobalContext();
    const { control, formState: { errors }, handleSubmit, reset, watch } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
    })

    const status = [
        { name: 'Draft', code: "DRAFT" },
        { name: 'Published', code: 'PUBLISHED' },
    ];

    useEffect(() => {
        reset(campaign)
    }, [campaign])

    const onSubmit = (data) => {
        setDisableBtn(true)

        axiosInstance.put(
            `/campaign/${ campaign.id }/update`,
            {
                "status": data.status,
                "name": data.name,
            }
        ).then((result) => {
            setReload(true)
            setDisableBtn(false)
            dialogHandler("updateCampaign")
            showToast('success', 'Campaign Updating', "The campaign has been updated successfully!");
        }).catch((errors) => {
            setDisableBtn(false);
            Object.values(errors.response.data.errors).forEach(error => {
                showToast('error', 'Campaign Updating', error[0]);
            });
        });

    };

    const validateNotEqual = (value) => {
        return value.toLowerCase() !== 'default' ||`"default" is a reserved name!`
    };

    return (
        <>
            <Dialog visible={ openDialog.updateCampaign } breakpoints={ { '960px': '95vw' } } style={ { width: '600px' } } header="Update Campaign" modal
                className="p-fluid  " onHide={ () => dialogHandler("updateCampaign") }>
                <form onSubmit={ handleSubmit(onSubmit) } className="d-flex flex-column justify-content-center">
                    <div className='px-5 my-5'>
                        {
                            campaign.name != "default" ?
                                <div className={ `col-12 mb-3` }>
                                    <div className="field">
                                        <label className="form-label"> Name  </label>
                                        <span className="p-float-label">
                                            <Controller name="name" control={ control }
                                                rules={ { required: 'Name is required.' , validate: validateNotEqual} }
                                                render={ ({ field, fieldState }) => (
                                                    <InputText id={ field.name } { ...field } autoFocus
                                                        className={ `w-100  ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                                    />
                                                ) }
                                            />
                                        </span>
                                        { getFormErrorMessage('name', errors) }
                                    </div>
                                </div>
                                : <></>
                        }

                        {/* status input */ }
                        <div className={ `col-12  mb-4` }>
                            <div className="field">
                                <label className="form-label" htmlFor="basic-default-company">  Status </label>
                                <span className="p-float-label">
                                    <Controller name="status" control={ control }
                                        rules={ { required: 'Status is required.' } }
                                        render={ ({ field, fieldState }) =>
                                        (<Dropdown id={ field.name } { ...field }
                                            onChange={ (e) => field.onChange(e.value) }
                                            options={ status }
                                            optionLabel="name"
                                            optionValue="code"
                                            className={ `w-100  ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                        />)
                                        } />
                                    { getFormErrorMessage('status', errors) }
                                </span>
                            </div>
                        </div>
                    </div>

                    {/* Footer */ }
                    <div className="col-12 d-flex justify-content-center py-3 border-top ">
                        <button type="reset" className="btn btn-label-secondary me-3" data-bs-dismiss="modal" aria-label="Close" disabled={ disableBtn ? true : false } onClick={ () => dialogHandler("updateCampaign") }>Cancel</button>
                        <button href='/campaigns/list' type="submit" className="btn btn-primary me-2" disabled={ disableBtn ? true : false }>
                            { disableBtn ?
                                <Spinner variant="white" as="span" animation="border" size="sm" role="status" className='mx-3' aria-hidden="true" />
                                :
                                "Update"
                            }
                        </button>
                    </div>

                </form>
            </Dialog>
        </>
    )
}
export default UpdateCampaign;