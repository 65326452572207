import React,  { useEffect }from 'react';
import { useNavigate } from 'react-router-dom'

import { useGlobalContext } from '../../Contexts/GlobalContext';
 import { useCustomToast } from '../../Contexts/ToastContext';
import axiosInstance from '../../../config/Axios';
import { emptyLocalStorage } from '../../../config/global';

const LogoutProcess = () => {
  const navigate      = useNavigate();
  const { showToast } = useCustomToast();
  const { setIsAuthenticated }   = useGlobalContext();

  useEffect(() => {
    setIsAuthenticated(false)
    axiosInstance.get(`/users/logout`)
    .then(response => {
           showToast('success', 'Logout', response?.data?.data?.msg);
          emptyLocalStorage()

          navigate("/login");
        })
    }, [])

  return (<></>)
}

export default LogoutProcess 