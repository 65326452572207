import React from 'react'
import { explodeArray } from '../../../../config/global';
import { useProfileContext } from '../../../Contexts/ProfileContext';

function FeaturesList({ selectedPackage }) {

  return selectedPackage?.features &&
    Object.entries(selectedPackage?.features).map(([key, value], index) => {
      return value &&
        <div className='mb-1 fs-8 d-flex align-items-start' key={index + "_" + Math.random(20)} >
          <i className="far fa-check-circle text-primary me-2 mt-1 fw-bold"></i>
          <span className=' text-capitalize'>
            {
              typeof value == "object" ?
                key.replace(/_/g, ' ') + " ( " + explodeArray(value) + " ) "
                :
                key.replace(/_/g, ' ')
            }
          </span>
        </div>
    })

}

export default FeaturesList