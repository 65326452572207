import React from 'react'

import { useGlobalContext } from '../../Contexts/GlobalContext';
import Layout from '../../Layout/Layout';
import CreateBroadcast from './components/CreateBroadcast';
import StreamsDataTable from './components/StreamsDataTable';

function BroadcastCard () {
    const { openDialog, dialogHandler, isLoading } = useGlobalContext();

    return (
        <Layout>
            <div className="card h-100">
                <div className="card-body">
                    <div className="card-title d-flex align-items-center justify-content-between   flex-shrink-0 ">
                        <div className='d-flex align-items-center '>
                            <span className="avatar-initial rounded bg-label-primary p-2 me-2">
                                <i className="fas fa-broadcast-tower"></i>
                            </span>
                            <div className='d-flex flex-column'>
                                <h5 className="fw-semibold my-0  ">Broadcast App</h5>
                                <small className=' text-capitalize'>

                                </small>
                            </div>
                        </div>
                        <div className='d-flex align-items-center '>
                            <button className="btn btn-primary mx-1"
                                disabled={ isLoading ? true : false }
                                onClick={ () => window.history.go(-1) } >
                                Back
                            </button>
                            <button type="reset" className="btn btn-primary "
                                disabled={ isLoading ? true : false }
                                onClick={ () => dialogHandler("createBroadcastDialog") } >
                                New Broadcast
                            </button>
                        </div>
                    </div>

                    <div className="d-flex flex-column">
                        <StreamsDataTable />
                    </div>
                </div>
            </div>
            { openDialog.createBroadcastDialog && <CreateBroadcast /> }
        </Layout>
    )
}

export default BroadcastCard