import React from 'react'

import { Checkbox } from 'primereact/checkbox';

function GroupCard ({ item, ids, setIds }) {
  
  const onGroupChange = (e) => {
    let selectedMedia = [...ids];

    if (e.checked)
      selectedMedia.push(e.value);
    else
      selectedMedia.splice(selectedMedia.indexOf(e.value), 1);

    setIds(selectedMedia);
  }

  return (
    <>
      <label htmlFor={ item?.id } className="col-11 d-flex ">
        <div className="col-2 mx-2 ">
          <div className=" me-2 p-3 rounded-2 bg-secondary d-flex align-items-center justify-content-center">
            <i className="menu-icon tf-icons bx bx-desktop  fs-3 text-white mx-auto"></i>
          </div>
        </div>
        <div className="col-3  mx-1 fs-6 d-flex flex-column justify-content-center align-items-start">
          <h6 className='text-truncate mb-1 fw-bold'>{ item?.name ?? "--" }</h6>
          <div className='text-truncate fw-light fs-8'>{ item?.serial_name }</div>
        </div>
        <div className="col-5 fs-6 text-center text-light  d-flex justify-content-center align-items-center" >
          {
            item.status == "ACTIVE" ? (
              <span className="text-center badge bg-label-success">{ item?.status }</span>
            ) : (
              <span className="text-center badge bg-label-danger">{ item?.status }</span>

            )
          }
        </div>
      </label>

      <Checkbox inputId={ item.id } name="campaign" className='me-2' value={ item.id } onChange={ onGroupChange } checked={ ids.indexOf(`${ item.id }`) !== -1 } />
    </>
  )
}

export default GroupCard