import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';

import { Controller } from "react-hook-form";
import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';

import { positionOptions } from '../../../config/constants';
import { useAppsContext } from '../../Contexts/AppsContext';
import { BottomOption } from './Positions/Bar';
import { ColorsInputs } from './Inputs';
import logo from "../../../assets/img/logo.png"

function RssSettings ({ classes = {}, item }) {
    const { control, reset } = useForm();
    const { rssSettings, setRssSettings, rssSettingsHandler } = useAppsContext();

    useEffect(() => {
        if (item?.type == "RSS") {
            setRssSettings(prev => ({
                ...prev,
                bgColor: item?.rss_settings?.bg_color,
                textColor: item?.rss_settings?.text_color,
                separator: item?.rss_settings?.separator ?? logo,
            }))

            reset({
                direction: (item?.rss_settings?.direction === "rtl" ? "right" : "left"),
                position: item?.rss_settings?.position,
                speed: parseInt(item?.rss_settings?.speed),
            })
        }

        return () => {

        }
    }, [])

    return (
        <div className='d-flex flex-wrap'>
            {/* Position Option */ }
            <div className='mb-2 col-12'>
                <label htmlFor="" className={ `form-label text-capitalize ${ classes?.labels ? classes?.labels : "mb-2" }` } >position</label>
                <Controller name="position" control={ control }
                    rules={ { required: "position is required!" } }
                    render={ ({ field, fieldState }) => (
                        <Dropdown
                            id={ field.name } { ...field }
                            value={ field.value }
                            options={ positionOptions }
                            onChange={ (e) => { field.onChange(e.value); rssSettingsHandler("position", e.value) } }
                            optionLabel="label"
                            optionValue="value"
                            inputRef={ field.ref }
                            placeholder="select..."
                            className={ `w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                        />
                    ) } />
            </div>

            <ColorsInputs
                classes={ {
                    position: "right-0 bottom-0",
                    mb: "mb-1",
                } } />

            {
                ["BOTTOM", "TOP"].includes(rssSettings?.position) ?
                    <BottomOption control={ control } classes={ classes } />
                    :
                    <></>
            }
        </div>
    )
}

export default RssSettings