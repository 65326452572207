import React, { Suspense } from "react";
import { Canvas } from "react-three-fiber";
import { OrbitControls, useGLTF } from "@react-three/drei";

// 3D files viewer
const ModelViewer = ({ gltfPath }) => {
    return (
        <Canvas pixelRatio={ [1, 2] } camera={ { position: [5, 10, 8], fov: 20 } } style={ { height: '60vh' } }>
            <ambientLight intensity={ 1 } />
            <Suspense fallback={ null }>
                <Model gltfPath={ gltfPath } />
            </Suspense>
            <OrbitControls />
        </Canvas>
    );
};

function Model ({gltfPath}) {
    const { scene } = useGLTF(gltfPath);
    return <primitive object={ scene } scale={ [2, 2, 2] } position={ [0, -1, 0] } />;
}

export default ModelViewer;