import React from 'react';
import { useNavigate, Link } from 'react-router-dom'

import axiosInstance from '../../../config/Axios';
import { emptyLocalStorage } from '../../../config/global';
import { useGlobalContext } from '../../Contexts/GlobalContext';

const Logout = ({ classes = "" }) => {
    const navigate = useNavigate();
    const { setIsAuthenticated } = useGlobalContext();

    const handleClick = () => {
        setIsAuthenticated(false)
        axiosInstance.get(`/users/logout`)
            .then(result => {
                emptyLocalStorage()
                navigate("/login");
            })
    }

    return (
        <Link className={ `dropdown-item ${ classes != "" ? classes : "" }` } to="/login" onClick={ handleClick }>
            <i className="bx bx-power-off me-2" />
            <span className="align-middle">Log Out</span>
        </Link>
    );
}

export default Logout;