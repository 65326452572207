import { InputText } from 'primereact/inputtext';
import React, { useState } from 'react'
import { Controller } from 'react-hook-form';

import { useAppsContext } from '../../Contexts/AppsContext';
import { getFormErrorMessage } from '../../../config/global';

import { classNames } from 'primereact/utils';

function RssName ({ clearErrors, setError, control, errors }) {
    const { rssName, setRssName } = useAppsContext();
    const [editName, setEditName] = useState(false);

    const nameHandler = (_name) => {
        if (_name) {
            clearErrors('name');
        } else
            setError('name', {
                type: 'manual',
                message: 'Name field is Required',
            })
    }

    return (
        <>
            <span className="p-float-label">
                <div className="d-flex align-items-center">
                    <span className="avatar-initial rounded bg-label-primary py-2  px-3 me-2"><i className="fa-solid fa-rss fs-4"></i></span>
                    {
                        editName ?
                            <>
                                <Controller name="name" control={ control }
                                    rules={ { required: rssName == "" ? 'RSS feed name is required.' : false } }
                                    render={ ({ field, fieldState }) => (
                                        <InputText
                                            type="text"
                                            id={ field.name }
                                            { ...field }
                                            value={ rssName }
                                            onChange={ (e) => { setRssName(e.target.value); nameHandler(e.target.value) } }
                                            inputRef={ field.ref }
                                            className={ `w-50 ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                        />
                                    ) } />
                                <i className="far fa-check-circle text-light ms-3 cursor-pointer" onClick={ () => { rssName ? setEditName(false) : <></> } }></i>
                            </>
                            :
                            <>
                                <h3 className='my-2 me-2'>{ rssName }</h3>
                                <i className="pi pi-pencil text-light" onClick={ () => setEditName(true) }></i>
                            </>
                    }
                    <div className="fs-12 fw-normal m-1">
                        { getFormErrorMessage('name', errors) }
                    </div>
                </div>
            </span>
        </>
    )
}

export { RssName }