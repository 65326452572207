import React from 'react'

import section4 from "../../../assets/img/Landing/animation.gif";
import section4_bg from "../../../assets/img/Landing/sec4_bg.png";
import icon1 from "../../../assets/img/Landing/icon1.png";
import icon2 from "../../../assets/img/Landing/icon2.png";
import icon3 from "../../../assets/img/Landing/icon3.png";
import icon4 from "../../../assets/img/Landing/icon4.png";

function CentralizedManagement () {
    
    return (
        <div className="container mt-5">
            <div className="row sec4">
                <div className="col-md-6 position-relative ">
                    <img src={ section4_bg } className=" position-absolute bg_sec4" alt="Responsive image" />
                    <img src={ section4 } className=" position-absolute  network_gif" alt="Responsive image" />
                </div>
                <div className="col-md-6">
                    <div className="my-3 sec4-title">
                        <h2>Centralized Management</h2>
                        <p>
                            Manage all the devices and users and monitor your system from
                            your centralized administration dashboard.
                        </p>
                    </div>
                    <div className="row mt-5">
                        <div className="col-md-6 mb-3">
                            <div className="card h-100 bg-light card-design">
                                <div className="row no-gutters sec4-card container">
                                    <div className="col-md-4">
                                        <img src={ icon1 } width="50" alt="..." />
                                    </div>
                                    <div className="col-md-8">
                                        <div className="card-body sec4-card-content">
                                            <h5 className="card-title">Zones</h5>
                                            <p className="card-text">
                                                You can control multiple devices under the same zone
                                                for an easier management
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="card h-100 bg-light card-design">
                                <div className="row no-gutters sec4-card container">
                                    <div className="col-md-4">
                                        <img src={ icon2 } width="60" alt="..." />
                                    </div>
                                    <div className="col-md-8">
                                        <div className="card-body sec4-card-content">
                                            <h5 className="card-title">Dashboard</h5>
                                            <p className="card-text">
                                                Manage & monitor users, devices, media storage &
                                                transactions
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="card h-100 bg-light card-design">
                                <div className="row no-gutters sec4-card container">
                                    <div className="col-md-4">
                                        <img src={ icon3 } width="60" alt="..." />
                                    </div>
                                    <div className="col-md-8">
                                        <div className="card-body sec4-card-content">
                                            <h5 className="card-title">Devices</h5>
                                            <p className="card-text">
                                                Manage devices connectivity, location, health,
                                                orientation, media & traffic
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="card h-100 bg-light card-design">
                                <div className="row no-gutters sec4-card container">
                                    <div className="col-md-4">
                                        <img src={ icon4 } width="70" alt="..." />
                                    </div>
                                    <div className="col-md-8">
                                        <div className="card-body sec4-card-content">
                                            <h5 className="card-title">Users</h5>
                                            <p className="card-text">
                                                Manage users by defining roles in the system & actions
                                                they’re allowed to make
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CentralizedManagement