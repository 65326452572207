import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ProgressSpinner } from 'primereact/progressspinner';
import AddContentPopup from '../../../SharedComponents/AddContentPopup/AddContentPopup';
import { SplitButton } from 'primereact/splitbutton';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import DragDrop from '../../../SharedComponents/DragDrop/DragDrop';
import Layout from '../../../Layout/Layout'

import { useTitle, formatDuration, } from "../../../../config/global";
import { useGlobalContext } from '../../../Contexts/GlobalContext';
import { useCustomToast } from '../../../Contexts/ToastContext';
import axiosInstance from '../../../../config/Axios';

function PlaylistSetup () {
  useTitle("Playlist Setup")

  const { setIsPublished, layerOptions, layer, setLayer, dialogHandler, openDialog, rerenderList, setRerenderList } = useGlobalContext();
  const { showToast } = useCustomToast();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [playlistDetails, setPlaylistDetails] = useState({ "total_duration": 0 });
  const [orderLoading, setOrderLoading] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  //Actions options (mobile view)
  const options = [
    {
      label: 'Add Content',
      icon: 'pi pi-plus',
      command: () => {
        dialogHandler("addContent")
      }
    },
    {
      label: 'Publish',
      icon: '',
      command: () => {
        publish()
      }
    }

  ];

  //Get Playlist Details 
  useEffect(() => {
    axiosInstance.get(`/playlist/${ id }`)
      .then((res) => {
        setPlaylistDetails(res.data.data);
      })
  }, [])

  const getDate = () => {
    axiosInstance.get(`/playlist/${ id }/${ layer }/media`)
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
        setRerenderList(false)
      })
  }

  //Get layer media on layer change
  useEffect(() => {
    setLoading(true)
    if (layer)
      getDate()
  }, [layer]);

  //Get layer media after update it
  useEffect(() => {
    if (rerenderList) {
      updateStatus("INACTIVE")
      setIsPublished(false)
    }
  }, [rerenderList]);

  //Publish all playlist content from draft 
  const publish = () => {
    setLoading(true);
    setIsPublished(true)
    axiosInstance.post(`/playlist/${ id }/publish`, {})
      .then((result) => {
        getDate()
        showToast('success', 'Publish Campaign', result?.data?.data?.msg);
      })

    updateStatus("ACTIVE")
  }

  //Update playlist status  ACTIVE/INACTIVE
  const updateStatus = (status) => {
    axiosInstance.put(`/playlist/${ id }`,
      {
        name: playlistDetails.name,
        status: status
      }
    ).then((res) => {
      getDate()
      setPlaylistDetails(res.data.data);
    })
  }

  return (
    <>
      <Layout>
        <div className="d-flex justify-content-between mb-3">
          <div className="col-8 col-md-6 d-flex ms-2">
            {/* <Tooltip target='.back-button' showDelay={ 100 } className="fs-8" /> */ }
            <Button label="" icon="fas fa-arrow-left" className='py-2 me-2 back-button'
              data-pr-position="bottom" data-pr-tooltip="Go back"
              onClick={ () => navigate("/playlist/list") } disabled={ loading ? true : false } />

            {/* dropdown */ }
            <Dropdown value={ layer } options={ layerOptions } optionLabel="name" optionValue="code" className='w-50 '
              onChange={ (e) => setLayer(e.value) } />
            {/* Duration */ }
            {
              layer == "MAIN" && !loading &&
              <div className='mx-3 d-none d-sm-block'>
                <p className="mb-0">Total Duration</p>
                <div className='fw-bold'> { formatDuration(playlistDetails.playlist_duration) }</div>
              </div>
            }
          </div>
          <div className='d-none d-md-flex'>
            {/* Publish */ }
            <Button
              label={ orderLoading ?
                <ProgressSpinner className='btn-spinner py-0' style={ { width: '20px', height: '20px' } } strokeWidth="2" fill="transparent" animationDuration="2s" /> : "Publish"
              }
              className={ orderLoading ? `pt-2 pb-1 me-2` : `py-2 me-2 ` }
              disabled={ loading ? true : (playlistDetails.status == "ACTIVE" ? true : false) }
              onClick={ publish } />

            {/* Add content */ }
            <Button label="Add Content" icon="pi pi-plus" className='py-2 ' onClick={ () => dialogHandler("addContent")
            } disabled={ loading ? true : false } />
          </div>

          {/* Action btn on mobile */ }
          <SplitButton label="Option" model={ options } className="p-button-sm mr-2 mb-2 d-flex d-md-none" />
        </div>

        {
          data.length == 0 && !loading ?
            <h5 className='w-100 card d-flex align-items-center p-5  text-center mt-5'>
              There is no media assigned yet !
            </h5>
            :
            ""
        }

        {
          loading ?
            <div className='w-100 h-100 d-flex align-items-center' >
              <ProgressSpinner style={ { width: '60px', height: '60px', } } strokeWidth="3" fill="var(--surface-ground)" />
            </div>
            :
            <DragDrop updateStatus={ updateStatus } data={ data } setOrderLoading={ setOrderLoading } />
        }
        {
          openDialog.addContent && <AddContentPopup updateStatus={ updateStatus } />
        }
      </Layout>
    </>
  )
}

export default PlaylistSetup