

import React, { useEffect, useState } from 'react'

import { useAppsContext } from '../../Contexts/AppsContext';
import Sketch from '@uiw/react-color-sketch';
import { getFormErrorMessage } from '../../../config/global';

import { Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { positionOptions } from '../../../config/constants';
import { Dropdown } from 'primereact/dropdown';

const ColorsInputs = ({ classes = {} }) => {
    const { rssSettings, rssSettingsHandler } = useAppsContext();
    const [displayColorPicker, setDisplayColorPicker] = useState({
        text: false,
        bg: false
    });

    const colorPickerHandler = (key) => {
        setDisplayColorPicker(prev => ({ ...prev, [key]: !prev[key] }))
    }

    return (
        <>
            <div className={ `col-6 p-1 ${ classes?.mb }` }>
                <label htmlFor="bg_color" className={ `form-label text-capitalize ${ classes?.mb ?? "mb-2" }` }  >Background color</label>
                <div className="border-1 p-1 rounded d-flex position-relative">
                    <Sketch
                        style={ { marginLeft: 20 } }
                        color={ rssSettings?.bgColor }
                        disableAlpha={ false }
                        onChange={ (color) => {
                            rssSettingsHandler("bgColor", color.hexa)
                        } }
                        onMouseLeave={ () => colorPickerHandler("bg") }
                        className={ `position-absolute color_picker ${ classes?.position } ${ displayColorPicker.bg ? "d-block" : "d-none" }` }
                    />
                    <div className='me-3 cursor-pointer border-1'
                        onClick={ () => colorPickerHandler("bg") }
                        style={ { backgroundColor: `${ rssSettings?.bgColor }`, width: "25px", height: "25px" } } >
                    </div>
                    <div className='border-bottom' >{ rssSettings?.bgColor }</div>
                </div>
            </div>

            <div className={ `col-6 p-1 ${ classes?.mb }` }>
                <label htmlFor="bg_color" className={ `form-label text-capitalize ${ classes?.mb ?? "mb-2" }` }>Text color</label>
                <div className="border-1 p-1 rounded d-flex position-relative">
                    <Sketch
                        style={ { marginLeft: 20 } }
                        color={ rssSettings?.textColor }
                        disableAlpha={ false }
                        onChange={ (color) => {
                            rssSettingsHandler("textColor", color.hexa)
                        } }
                        onMouseLeave={ () => colorPickerHandler("text") }
                        className={ `position-absolute color_picker ${ classes?.position } ${ displayColorPicker.text ? "d-block" : "d-none" }` }
                    />
                    <div className='me-3 cursor-pointer border-1'
                        onClick={ () => colorPickerHandler("text") }
                        style={ { backgroundColor: `${ rssSettings?.textColor }`, width: "25px", height: "25px" } } >
                    </div>
                    <div className='border-bottom' >{ rssSettings?.textColor }</div>
                </div>
            </div>
        </>
    )
}

const PositionInput = ({ control, errors, classes = {} }) => {
    const { rssSettings, rssSettingsHandler } = useAppsContext();

    return (
        <>
            {/* Position Option */ }
            <div className={ `${ classes?.mb } col-12 p-1` }>
                <label htmlFor="" className={ `form-label text-capitalize ${ classes?.mb ?? "mb-2" }` }>position</label>
                <Controller name="position" control={ control }
                    rules={ { required: "position is required!" } }
                    render={ ({ field, fieldState }) => (
                        <Dropdown
                            id={ field.name } { ...field }
                            value={ rssSettings?.position }
                            options={ positionOptions }
                            onChange={ (e) => { field.onChange(e.value); rssSettingsHandler("position", e.value) } }
                            optionLabel="label"
                            optionValue="value"
                            inputRef={ field.ref }
                            placeholder="select..."
                            className={ `w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                        />
                    ) }
                />
                { getFormErrorMessage('position', errors) }
            </div>
        </>
    )
}

export { ColorsInputs, PositionInput }