import React, { useMemo, useState, useEffect } from 'react'

import { ProgressBar } from 'primereact/progressbar';
import { Image, Spinner } from 'react-bootstrap';
import { useAppsContext } from '../../Contexts/AppsContext';

import { ScreenSides } from './Positions/ScreenSides';
import { Bar } from './Positions/Bar';

function RssPreview ({ feeds, loading, rssType }) {
    const { rssSettings } = useAppsContext();
    const [activePosition, setActivePosition] = useState({
        component: <></>,
        classes: "justify-content-center align-items-center"

    });

    useEffect(() => {
        let _position = rssSettings?.position;
        let _child = {}

        if (_position == "FULL_SCREEN")
            _child = ({
                component: <ScreenSides feeds={ feeds } />,
                classes: "justify-content-center align-items-center",
            })
        else if (_position == "BOTTOM")
            _child = {
                component: <Bar feeds={ feeds } />,
                classes: "justify-content-center align-items-end",
            }
        else if (_position == "TOP")
            _child = {
                component: <Bar feeds={ feeds } />,
                classes: "justify-content-center align-items-start",
            }
        else if (_position == "RIGHT")
            _child = {
                component: <ScreenSides feeds={ feeds } />,
                classes: "flex-column justify-content-start align-items-end"
            }
        else if (_position == "LEFT")
            _child = {
                component: <ScreenSides feeds={ feeds } />,
                classes: "flex-column justify-content-start align-items-start"
            }
        else if (_position == "BOTTOM_BAR")
            _child = {
                component: <ScreenSides feeds={ feeds } />,
                classes: "flex-column justify-content-start align-items-end"
            }
        else
            _child = {
                component: <></>,
                classes: "justify-content-center align-items-center"
            }

        setActivePosition(_child)
    }, [rssSettings.position, feeds]);

    return (
        <div className={ `d-flex feed_container border-5 border-dark h-100 ${ loading ? "justify-content-center align-items-center" : activePosition?.classes }` }>
            {
                loading ?
                    <Spinner as="span" variant="primary" size='lg' animation="border" role="status" className='mx-3 my-5 ' aria-hidden="true" />
                    :
                    process.env.REACT_APP_APP_ENV != "Production" && rssType == "RSS_CUSTOM" ?
                        <div className='d-flex justify-content-center flex-column align-items-center my-auto'>
                            <i className="fas fa-cogs fs-1 mb-3"></i>
                            <p className='text-center'>You can preview custom RSS on your device!</p>
                        </div>
                        :
                        feeds?.length > 0
                            ? activePosition?.component
                            :
                            <div className='d-flex justify-content-center flex-column align-items-center my-auto'>
                                <i className="fas fa-cogs fs-1 mb-3"></i>
                                <h5 className='text-center mb-2' >App instance preview </h5>
                                <p className='text-center'>Edit and save the configuration to preview this app instance </p>
                            </div>
            }
        </div >
    )
}

export default RssPreview