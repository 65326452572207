import React, { useEffect, useState } from 'react'

import { Dialog } from 'primereact/dialog';
import { Spinner } from 'react-bootstrap';
import { Card } from 'primereact/card';

import AssignScheduleCampaign from './AssignScheduleCampaign';

import { useGlobalContext } from '../../../Contexts/GlobalContext';
import { useDeviceContext } from '../../../Contexts/DeviceContext';
import { useCustomToast } from '../../../Contexts/ToastContext';
import axiosInstance from '../../../../config/Axios';

function ShowScheduleCampaign ({ device, deviceID, options }) {
    const { disableBtn, openDialog, dialogHandler } = useGlobalContext();
    const { showAssignForm, setShowAssignForm } = useDeviceContext();
    const { showToast } = useCustomToast();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [rerender, setRerender] = useState(true);

    useEffect(() => {
        if (rerender) {
            setLoading(true)
            setShowAssignForm(false)
            axiosInstance.get(`/campaign/device/${ deviceID }/show`)
                .then((res) => {
                    setData(res?.data?.data)
                    setLoading(false)
                    setRerender(false)
                }).catch((error) => {

                });
        }
    }, [rerender])

    const deleteScheduledCampaign = (id) => {
        axiosInstance.delete(`/campaign/device/${ id }/delete`)
            .then((res) => {
                setRerender(true)
                showToast('success', 'Delete Scheduled Campaign', res?.data?.data?.msg);
            }).catch((error) => {

            });
    }

    const pray = (campaignDeviceID) => {
        axiosInstance.post(
            `/device/start-schedule`,
            {
                "campaign_id": campaignDeviceID,
                "serial_name": device.serial_name,
            }
        )
    }

    return (
        <>
            <Dialog visible={ openDialog.assignScheduleCampaigns }
                style={ { width: '42%' } }
                breakpoints={ { '960px': '95vw' } }
                header="Scheduled Campaigns Assigned"
                modal className="p-fluid"
                onHide={ () => dialogHandler("assignScheduleCampaigns") }
            >
                {/* Display Assigned Campaigns */ }
                {
                    loading ?
                        <div className='d-flex m-5 justify-content-center align-items-center '>
                            <Spinner variant="primary" as="span" animation="border" size="lg" role="status" className='mx-3' aria-hidden="true" />
                        </div>
                        :
                        data.length != 0 && !showAssignForm &&
                        <>
                            <div className="d-flex flex-col my-4 scroll_div schedule_campaign_container">
                                {
                                    data.map((campaign, index) => {
                                        return <>
                                            <Card className='mb-3 mx-3 schedule_card position-relative' disabled key={ index }>
                                                <div className="w-100 d-flex align-items-center">
                                                    <div className="col-1 p-3 d-flex justify-content-center"><i className="bx bx-time-five text-secondary fs-1 "></i></div>
                                                    <div className="col-9 fs-5 d-flex flex-col ps-3">
                                                        <span className='mb-2'>{ campaign.name }</span>
                                                        <div className='d-flex'>
                                                            <span className="fs-10 text-capitalize text-center badge bg-label-secondary  d-flex flex-column me-2">
                                                                { campaign.is_repeated ? "Repeated" : "once" }
                                                            </span>
                                                            <span className="text-center badge bg-label-secondary fs-10 d-flex  me-2">
                                                                {
                                                                    !campaign.is_repeated ? campaign.date
                                                                        :
                                                                        <>
                                                                            {
                                                                                campaign.days.length == 7 ?
                                                                                    <div className='text-capitalize'>Daily</div>
                                                                                    :
                                                                                    <>
                                                                                        {
                                                                                            campaign.days.map((day, index) => {
                                                                                                return <div className="me-2" key={ index }>
                                                                                                    {
                                                                                                        day.substring(0, 3).toUpperCase()
                                                                                                    }
                                                                                                </div>
                                                                                            })
                                                                                        }
                                                                                    </>
                                                                            }
                                                                        </>
                                                                }
                                                            </span>
                                                            <span className="text-center badge bg-label-secondary fs-10 d-flex flex-column">
                                                                { campaign.start_time + " - " + campaign.end_time }
                                                            </span>

                                                        </div>
                                                    </div>
                                                    {

                                                        !campaign.is_finished &&
                                                        <div className="col-2 text-end text-light cursor-pointer">
                                                            {
                                                                process.env.REACT_APP_APP_ENV != "Production" &&
                                                                <butt on type="submit" onClick={ () => pray(campaign.id) } className="btn btn-primary me-2"> send </butt>
                                                            }
                                                            <i className="bx bx-trash cursor-pointer" onClick={ () => deleteScheduledCampaign(campaign.id) } ></i>
                                                        </div>
                                                    }
                                                </div>
                                                {
                                                    campaign.is_finished &&
                                                    <div className='card_overlay p-4 d-flex align-items-center position-absolute bg-gray-100/75 w-100 h-100 top-0 start-0'>
                                                        <div className="col-10 text-end ">
                                                            <span className="text-center badge bg-label-danger ">finished</span>
                                                        </div>
                                                        <div className="col-2 text-end text-light cursor-pointer">
                                                            <i className="bx bx-trash" onClick={ () => deleteScheduledCampaign(campaign.id) } ></i>
                                                        </div>
                                                    </div>
                                                }

                                            </Card>

                                        </>
                                    })
                                }
                            </div>

                            <div className="col-12 text-center d-flex  align-items-end  justify-content-center py-4 border-top border-gray">
                                <button type="reset" className="btn btn-label-secondary me-3"
                                    data-bs-dismiss="modal" aria-label="Close"
                                    disabled={ disableBtn ? true : false }
                                    onClick={ () => dialogHandler("assignScheduleCampaigns") }>
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-primary  me-1" disabled={ disableBtn ? true : false } >
                                    {
                                        disableBtn ?
                                            <Spinner variant="primary" as="span" animation="border" size="sm" role="status" className='mx-3' aria-hidden="true" />
                                            :
                                            <span className='d-flex align-items-center' onClick={ () => setShowAssignForm(true) } > Assign Campaign
                                            </span>
                                    }
                                </button>
                            </div>
                        </>
                }

                {/* Empty Status Scenario */ }
                {
                    (!showAssignForm && data.length == 0 && !loading) &&
                    <>
                        <div className="col-12 text-center d-flex  align-items-end py-5 justify-content-center py-4">
                            <button type="submit" className="btn btn-primary  me-1" disabled={ disableBtn ? true : false } >
                                {
                                    disableBtn ?
                                        <Spinner variant="white" as="span" animation="border" size="sm" role="status" className='mx-3' aria-hidden="true" />
                                        :
                                        <span className='d-flex align-items-center' onClick={ () => setShowAssignForm(true) } >
                                            <i className="fas fa-plus me-2" ></i>   Assign Campaign
                                        </span>
                                }
                            </button>
                        </div>
                    </>
                }

                {/* Add New Scheduled Campaign */ }
                {
                    showAssignForm &&
                    <AssignScheduleCampaign
                        setRerender={ setRerender }
                        deviceID={ deviceID }
                        options={ options } />
                }

            </Dialog>
        </>
    )
}

export default ShowScheduleCampaign