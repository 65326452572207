import React, { useState, useEffect } from 'react';

import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import { Column } from 'primereact/column';

import { useTitle, formatDuration } from '../../../config/global';

import { useDataTableContext } from '../../Contexts/DataTableContext';
import { useGlobalContext } from '../../Contexts/GlobalContext';
import { useCustomToast } from '../../Contexts/ToastContext';
import axiosInstance from '../../../config/Axios';
import { useAppsContext } from '../../Contexts/AppsContext';

function RssDataTable () {
    useTitle('RSS');

    const { totalRecords, setReload, loading, lazyParams, setLazyParams, data, onPage, onSort, onFilter } = useDataTableContext();
    const { setDisableBtn, deleteDialog, deleteDialogHandler, dialogHandler, openDialog } = useGlobalContext();
    const { setIsCreation, setSelectedRss } = useAppsContext();
    const { showToast } = useCustomToast();

    useEffect(() => {
        setLazyParams(prev => ({
            ...prev,
            modelName: "media",
            filters: {
                'file_name': { value: '', matchMode: 'contains' },
                'title': { value: '', matchMode: 'contains' },
                'path': { value: '', matchMode: 'contains' },
                'type': { value: 'RSS', matchMode: 'equals' },
            }
        }))
    }, [])

    // Data Table Body Template
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="d-inline-block text-nowrap">
                {/* Update RSS */ }
                <Tooltip target={ `.update-button-${ rowData.id }` } showDelay={ 100 } className="fs-8" />
                <button className={ `btn btn-sm btn-icon update-button-${ rowData.id }` } data-pr-position="bottom" data-pr-tooltip="
                Update" onClick={ () => editRss(rowData) }><i className="pi pi-pencil"></i></button>

                {/* Delete RSS */ }
                <Tooltip target={ `.delete-button-${ rowData.id }` } showDelay={ 100 } className="fs-8" />
                <button className={ `btn btn-sm btn-icon delete-record delete-button-${ rowData.id }` } data-pr-position="bottom" data-pr-tooltip="Delete" onClick={ () => confirmDeleteUser(rowData) } ><i className="bx bx-trash"></i></button>
            </div>
        );
    }

    const durationBodyTemplate = (rowData) => {
        return <>
            { formatDuration(rowData.duration) }
        </>
    }

    const editRss = (data) => {
        setSelectedRss(data)
        setIsCreation(false)
        dialogHandler("rssFeedAddition")
    }

    const confirmDeleteUser = (data) => {
        axiosInstance.delete(`/media/${ data.id }`)
            .then((result) => {
                setReload(true)
                showToast('success', 'Delete Media', result?.data?.data?.msg);
            })
            .catch((error) => {
                showToast('error', 'Delete Media', error?.response?.data?.msg);
            });
    }

    return (
        <div className='table-responsive text-nowrap'>
            <DataTable value={ data.media } lazy filterDisplay="row" responsiveLayout="scroll" dataKey="id"
                paginator first={ lazyParams.first } rows={ lazyParams.rows } rowsPerPageOptions={ [15, 25, 50, 100] }
                totalRecords={ totalRecords } onPage={ onPage }
                onSort={ onSort } sortField={ lazyParams.sortField } sortOrder={ lazyParams.sortOrder }
                onFilter={ onFilter } filters={ lazyParams.filters } loading={ loading } className="table"
            >
                <Column field="file_name" header="Name" className='text-center' sortable filter filterPlaceholder="Search by Name" />
                {/* <Column field="title" header="title" className='text-center' sortable filter filterPlaceholder="Search by Name" /> */}
                <Column field="duration" className='text-center' header="Total Duration" body={ durationBodyTemplate } />
                <Column field="created_at" header="Created Date" className='text-center' sortable />
                <Column body={ actionBodyTemplate } exportable={ false } ></Column>
            </DataTable>
        </div>
    )
}

export default RssDataTable