import React from 'react'
import Apps from './AppsComponents/Apps'
import BreadCrumb from './AppsComponents/BreadCrumb'

function AppsList () {
    return (
        <div className='w-100 d-flex flex-column'>
            <BreadCrumb />
            <Apps />
        </div>
    )
}

export default AppsList