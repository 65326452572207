import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

import { ProgressSpinner } from 'primereact/progressspinner';
import { Tooltip } from 'primereact/tooltip';
import { Card } from 'primereact/card';

import { useGlobalContext } from '../../Contexts/GlobalContext';
import { useCustomToast } from '../../Contexts/ToastContext';
import MediaSettingPopup from './MediaSettingPopup';
import MediaDuration from './components/MediaDuration';
import axiosInstance from '../../../config/Axios';
import MediaTitle from './components/MediaTitle';
import MediaThumb from './components/MediaThumb';
import { useAppsContext } from '../../Contexts/AppsContext';

function CampaignCard ({ layer, item }) {

    const { campaignDetails, deletedItemId, setDeletedItemId, subLoaders, loadingHandler, setRerenderList } = useGlobalContext();
    const { setRssSettings } = useAppsContext();
    const { showToast } = useCustomToast();

    //Pop up controllers  
    const [mediaSettingPopup, setMediaSettingPopup] = useState(false);
    const [mediaInfoPopup, setMediaInfoPopup] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState({});


    const navigate = useNavigate();

    const previewImage = (e) => {
        loadingHandler("thumbLoading")

        var formData = new FormData();
        formData.append("thumb", e.target.files[0]);
        formData.append("title", item.title.slice(0, 20));

        axiosInstance.post(`/media/interactive/${ item.id }/update`, formData
        ).then((result) => {
            item.thumb = result?.data?.data?.thumb
            setSelectedMedia({})
            loadingHandler("thumbLoading")
            showToast('success', 'Media  Editing', "Media title updated successfully!");
        }).catch((error) => {
            loadingHandler("thumbLoading")
            showToast('error', 'Media  Editing', error?.response?.data?.message);

        });
    }

    // Delete  Handler
    const removeFromCampaign = () => {
        loadingHandler('deleteCampaignMedia')
        setDeletedItemId(item.id)
        axiosInstance.delete(`/campaign/content/${ item.campaign_playlist_media_id }/delete`)
            .then((result) => {
                setRerenderList(true);
                loadingHandler('deleteCampaignMedia')
            }).catch((error) => {
                showToast('error', "Error", error?.response?.data?.message);
            });
    }

    const settingPopupHandler = (data) => {
        setRssSettings(prev => ({
            ...prev,
            bgColor: data?.rss_settings?.bg_color,
            textColor: data?.rss_settings?.text_color,
            position: data?.rss_settings?.position,
            speed: data?.rss_settings?.speed,
            direction: (data?.rss_settings?.direction === "rtl" ? "right" : "left"),
            separator: data?.rss_settings?.separator,
            duration: data?.rss_settings?.duration,
        }))
        setSelectedMedia(data)
        setMediaSettingPopup(true)
    }

    return (
        <>
            <div className='d-flex align-items-center'>
                <Card className='w-100 d-flex row playlist_card'>
                    <div className="d-flex align-items-center  flex-nowrap justify-content-between">
                        {/* ------------Media Thumb and Name-------- */ }
                        {
                            !item.hasOwnProperty("path") ?
                                <>
                                    <div onClick={ () => navigate(`/playlist/${ item.id }/show`) } className=' cursor-pointer col-1 me-2 p-4 rounded-2 bg-secondary d-flex align-items-center justify-content-center'>
                                        <i className="fas fa-list-ul fs-4  text-white"></i>
                                    </div>
                                </>
                                :
                                <>
                                    {
                                        ["HDMI", "CHANNEL", "RSS", "RSS_CUSTOM", "MULTI_3D"].includes(item.type) ?
                                            <div className=' cursor-pointer col-1 me-2 p-4 rounded-2 bg-secondary d-flex align-items-center justify-content-center'>
                                                <span className="text-nowrap fs-6 text-white text-center">{ (item.type).replace(/_/g, " ") }</span>
                                            </div>
                                            :
                                            <div className='col-2 col-md-1 me-2 d-flex align-items-center justify-content-center position-relative'>
                                                {
                                                    subLoaders.thumbLoading && item.id == selectedMedia?.id ?
                                                        <ProgressSpinner style={ { width: '30px', height: '30px' } } strokeWidth="3" fill="var(--surface-ground)" animationDuration="2s" />
                                                        :
                                                        <MediaThumb item={ item } />
                                                }
                                            </div>
                                    }
                                    {
                                        campaignDetails.is_interactive ?
                                            <MediaTitle item={ item } />
                                            :
                                            <div className='col-5 col-md-3 text-truncate pe-3 ps-2'>{ item.file_name }</div>
                                    }

                                </>
                        }

                        {/* -------Duration------- */ }
                        <div className='col-2 d-none d-md-block'>
                            <MediaDuration item={ item } />
                        </div>

                        <div className='cpl-3 col-sm-3 col-md-5 d-flex align-items-center justify-content-end  pe-sm-3'>
                            {
                                campaignDetails.is_interactive && layer == "MAIN" &&
                                <>
                                    <Tooltip target={ `.edit-thumb-${ item.id }` } content="Upload Thumb" position='bottom' className="fs-8" />
                                    <div className="button-wrapper" onClick={ () => setSelectedMedia(item) }>
                                        <label htmlFor={ `${ item.id }-upload` } className={ `edit-thumb-${ item.id } btn p-0 me-2 text-light` } tabIndex={ 0 }>
                                            <i className="bx bx-image-add"></i>
                                            <input onChange={ previewImage } type="file" id={ `${ item.id }-upload` } className="account-file-input" hidden accept="image/png, image/jpeg" />
                                        </label>
                                    </div>
                                </>
                            }
                            {
                                item.hasOwnProperty("path") && !JSON.parse(sessionStorage.getItem('is_default_prayer'))
                                    ?
                                    <>
                                        <Tooltip target={ `.edit-button-${ item.id }` } content="Edit" position='bottom' className="fs-8" />
                                        <button className={ `btn bg-transparent p-0 mx-1 edit-button-${ item.id }  pt-1 d-block d-md-none` } >
                                            <i onClick={ () => setMediaInfoPopup(true) } className="fas fa-pencil text-light"></i>
                                        </button>
                                        <Tooltip target={ `.setting-button-${ item.id }` } content="Setting" position='bottom' className="fs-8" />
                                        <button className={ `btn bg-transparent p-0 mx-1 setting-button-${ item.id } pt-1` }  >
                                            <i onClick={ () => settingPopupHandler(item) } className="fas fa-cog text-light"></i>
                                        </button>
                                    </>
                                    :
                                    ""
                            }
                            {
                                !JSON.parse(sessionStorage.getItem('is_default_prayer')) &&
                                <>
                                    <Tooltip target={ `.delete-button-${ item.id }` } content="Delete" position='bottom' className="fs-8" />
                                    {
                                        deletedItemId == item.id && subLoaders.deleteCampaignMedia ?
                                            <ProgressSpinner style={ { width: '20px', height: '20px' } } strokeWidth="3" fill="var(--surface-ground)" className='mx-1' animationDuration="2s" />
                                            :
                                            <button className={ `btn bg-transparent p-0 mx-1 delete-button-${ item.id }` } onClick={ removeFromCampaign } >
                                                <i className="bx bx-trash  text-light"></i>
                                            </button>
                                    }

                                    <i className="fas fa-bars text-light mx-2"></i>
                                </>
                            }
                        </div>
                    </div>
                </Card>
            </div >
            {
                mediaSettingPopup && selectedMedia?.id == item?.id ?
                    <MediaSettingPopup
                        layer={ layer }
                        removeMedia={ removeFromCampaign }
                        item={ selectedMedia }
                        mediaSettingPopup={ mediaSettingPopup }
                        setMediaSettingPopup={ setMediaSettingPopup } />
                    :
                    <></>
            }
        </>
    )
}

export default CampaignCard