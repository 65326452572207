import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button } from 'primereact/button'
import { Tooltip } from 'primereact/tooltip'
import { Spinner } from 'react-bootstrap'

import CreatePrayerCampaign from './CreatePrayerCampaign'
import PrayerEnableDialog from './PrayerEnableDialog'
import { useAppsContext } from '../../../Contexts/AppsContext'
import axiosInstance from '../../../../config/Axios'
import { useTitle } from '../../../../config/global'
import CampaignCard from './CampaignCard'
import Layout from '../../../Layout/Layout'


function PrayerCampaigns () {
    useTitle("Prayer Campaigns");

    const { isLoading, setIsLoading, createDialog, setCreateDialog, prayerEnableDialog, setPrayerEnableDialog } = useAppsContext();
    const navigate = useNavigate();

    const [prayerCampaigns, setPrayerCampaigns] = useState([]);

    // Created campaigns names 
    const [campaignNames, setCampaignNames] = useState([]);

    useEffect(() => {
        setIsLoading(true);
    }, [])

    useEffect(() => {
        if (isLoading)
            fetchData();
    }, [isLoading])

    const fetchData = () => {
        axiosInstance.get(`/company/prayer/campaigns`)
            .then((result) => {
                setPrayerCampaigns(result?.data?.data)

                result.data.data.map(campaign => {
                    setCampaignNames(current => [...current, campaign.name])
                })
                setIsLoading(false);
            })
        setIsLoading(false);
    }

    return (
        <Layout>
            <div className="d-flex justify-content-between mb-4">
                <div className="col-8 col-md-6 d-flex">
                    <Button label="Back" className='py-2 back-button'
                        data-pr-position="bottom" data-pr-tooltip="Go back"
                        onClick={ () =>window.history.go(-1) } disabled={ isLoading ? true : false } />
                </div>

                <div className='d-none d-md-flex'>
                    <Button label="Enable Prayer" className='py-2 me-2' onClick={ () => setPrayerEnableDialog(true) } disabled={ isLoading ? true : false } />
                    <Button
                        label="Custom Campaign"
                        className='fs_md_6  py-2'
                        disabled={ isLoading || prayerCampaigns.length == 0 || prayerCampaigns.length == 6 ? true : false }
                        onClick={ () => setCreateDialog(true) }
                    />
                </div>

                {/* Responsive actions menu */ }
                {/* <SplitButton label="Option" model={ options } className="p-button-sm mr-2 mb-2 d-flex d-md-none" /> */ }

            </div>

            {
                isLoading ?
                    <div className="d-flex justify-content-center my-5 py-5">
                        <Spinner variant="primary" as="span" size='lg' animation="border" role="status" className='mx-3' aria-hidden="true" />
                    </div>
                    :
                    prayerCampaigns.length == 0 ?
                        <div className='card my-5  py-5 text-center fs-5 '>Enable prayer on your device please!</div>
                        :
                        prayerCampaigns.map((item, index) => {
                            return <CampaignCard item={ item } key={ item.id } />
                        })
            }

            {
                createDialog && <CreatePrayerCampaign campaignNames={ campaignNames } />
            }
            {
                prayerEnableDialog && <PrayerEnableDialog />
            }

        </Layout >
    )
}
export default PrayerCampaigns