import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import { Column } from 'primereact/column';

import { useTitle, formatDuration } from '../../config/global';
import { useDataTableContext } from '../Contexts/DataTableContext';
import { useGlobalContext } from '../Contexts/GlobalContext';
import DeleteConfirmation from '../SharedComponents/DeleteConfirmation';
import { useCustomToast } from '../Contexts/ToastContext';
import axiosInstance from '../../config/Axios';
import Create from './Components/Create';
import Update from './Components/Update';
import Layout from "../Layout/Layout";


const PlaylistDatatable = () => {
	useTitle('PlayLists');

	const { totalRecords, setReload, loading, lazyParams, setLazyParams, data, onPage, onSort, onFilter, } = useDataTableContext();
	const { openDialog, dialogHandler, deleteDialog, deleteDialogHandler, setDisableBtn } = useGlobalContext();
	const { showToast } = useCustomToast();

	const [PlayList, setPlayList] = useState({ name: "" });

	const navigate = useNavigate();

	let emptyPlayList = {
		code: null
	};

	useEffect(() => {
		setLazyParams(prev => ({
			...prev,
			modelName: "playlist",
			filters: {
				'name': { value: '', matchMode: 'contains' },
				'status': { value: '', matchMode: 'contains' },
				'created_at': { value: '', matchMode: 'contains' },
				'updated_at': { value: '', matchMode: 'contains' },
			}
		}))
	}, [])

	// Dialog controls
	const openCreateDialog = () => {
		setPlayList(emptyPlayList);
		dialogHandler("createPlaylist");
	}

	const openEditDialog = (data) => {
		setPlayList(data);
		dialogHandler("updatePlaylist");
 	}

	// Delete playlist handler
	const confirmDeletePlayList = (data) => {
		setPlayList(data);
		deleteDialogHandler("playlist");
	}

	const deletePlayList = () => {
		axiosInstance.delete(`/playlist/${ PlayList.id }/delete`);
		setReload(true);
		deleteDialogHandler("playlist");
		showToast('success', 'Delete Playlist', 'PlayList Deleted Successfully');
		setDisableBtn(false);
	}

	// Data table body columns template
	const actionBodyTemplate = (rowData) => {
		return (
			<div className="d-inline-block text-nowrap">
				{/* Setup page */ }
				<Tooltip target={ `.setup-button-${ rowData.id }` } showDelay={ 100 } className="fs-8" />
				<button className={ `btn btn-sm btn-icon setup-button-${ rowData.id }` } data-pr-position="bottom" data-pr-tooltip="Setup" onClick={ () => navigate('/playlist/' + rowData.id + '/show') }><i className='bx bx-slideshow me-1'></i></button>
				{/* Update playlist */ }
				<Tooltip target={ `.update-button-${ rowData.id }` } showDelay={ 100 } className="fs-8" />
				<button className={ `btn btn-sm btn-icon update-button-${ rowData.id }` } data-pr-position="bottom" data-pr-tooltip="
                Update" onClick={ () => openEditDialog(rowData) }><i className="pi pi-pencil"></i></button>
				{/* Delete playlist */ }
				<Tooltip target={ `.delete-button-${ rowData.id }` } showDelay={ 100 } className="fs-8" />
				<button className={ `btn btn-sm btn-icon delete-button-${ rowData.id }` } data-pr-position="bottom" data-pr-tooltip="Delete" onClick={ () => confirmDeletePlayList(rowData) } ><i className="bx bx-trash"></i></button>
			</div>
		);
	}

	const durationBodyTemplate = (rowData) => {
		var duration = formatDuration(rowData.playlist_duration)
		return (
			duration
		)
	}

	const statusBodyTemplate = (rowData) => {
		return (
			<div className=''>
				<span className={ `text-center badge ${ rowData.status == "INACTIVE" ? "bg-label-danger" : "bg-label-success" }` } >{ rowData.status.toLowerCase() }</span>
			</div>
		);
	}

	return (
		<Layout>
			<div className="card">
				<div className="card-header d-flex align-items-center justify-content-between">
					<h5 className="card-title m-0 me-2">PlayLists</h5>
					<button type="button" className="btn btn-primary me-sm-3 me-1" onClick={ openCreateDialog } disabled={ loading ? true : false }>
						<span className="tf-icons bx bx-PlayList"></span> New PlayList
					</button>
				</div>
				<div className='table-responsive text-nowrap'>
					<DataTable value={ data.playlist } lazy filterDisplay="row" responsiveLayout="scroll" dataKey="id"
						paginator first={ lazyParams.first } rows={ lazyParams.rows } rowsPerPageOptions={ [15, 25, 50, 100] }
						totalRecords={ totalRecords } onPage={ onPage }
						onSort={ onSort } sortField={ lazyParams.sortField } sortOrder={ lazyParams.sortOrder }
						onFilter={ onFilter } filters={ lazyParams.filters } loading={ loading } className="table"
					>
						<Column header="Name" field="name" sortable filter className='text-center' />
						<Column header="Total Duration" field="playlist_duration" className='text-center' body={ durationBodyTemplate } />
						<Column header="status" filterField="status" body={ statusBodyTemplate } />
						<Column header="Created AT" field="created_at" filterField="created_at" dataType="date" />
						<Column header="Updated AT" field="updated_at" filterField="updated_at" dataType="date" />
						<Column body={ actionBodyTemplate } exportable={ false } style={ { minWidth: '8rem' } }></Column>
					</DataTable>
				</div>

				{
					openDialog.createPlaylist && <Create />
				}

				{
					openDialog.updatePlaylist && <Update PlayList={ PlayList } setReload={ setReload } />
				}

				{
					deleteDialog.playlist &&
					<DeleteConfirmation
						deleteHandler={ deletePlayList }
						itemName={ PlayList.name ?? "playlist" }
						dialogKey="playlist"
					/>
				}
			</div>
		</Layout>
	);
}
export default PlaylistDatatable;