import React, { useState, useEffect } from "react";
import {  useParams } from "react-router-dom";

import ChartComponents from "./Setup/ChartComponents";
import AssignCampaign from "./Setup/AssignCampaign";
import axiosInstance from "../../../config/Axios";
import { useTitle } from "../../../config/global";
import LiveScreen from "./Setup/LiveScreen";
import DeviceInfo from "./Setup/DeviceInfo";
import SerialNav from "./Setup/SerialNav";
import Layout from "../../Layout/Layout";
import Rotate from "./Setup/Rotate";
import Map from "./Setup/Map";

const DeviceShow = () => {
  useTitle("Devices");

  const { id } = useParams();
 
  const [deviceInfo, setDeviceInfo] = useState({});

  //assign campaign component states
  const [selectedCampaign, setSelectedCampaign] = useState({});
  const [options, setoptions] = useState([]);

  //rotate  component states
  const [degree, setDegree] = useState(0);

  useEffect(() => {
    axiosInstance.get(`/device/${ id }/info`).then((res) => {
      setDeviceInfo(res.data.data);
      setSelectedCampaign(res.data.data.campaigns[0].id)
      setDegree(res.data.data.rotation)
    }) 

    axiosInstance.get(`/company/campaigns?page=1&per_page=12`)
      .then((result) => {
        result.data.data.map(campaign => {
          let option = {
            name: campaign.name,
            code: campaign.id
          }
          setoptions(current => [...current, option])
        })
      })
  }, []);

  return (
    <Layout>
      <SerialNav deviceInfo={ deviceInfo } />
      <div className="row flex-wrap">
        <div className="col-md-12 col-lg-8 order-md-2 order-lg-1  mb-4">
          <LiveScreen />
        </div>
        <div className="col-md-12 col-lg-4 order-md-1 order-lg-2">
          <div className="row">
            <div className="col-md-12  mb-4">
              <div className="card d-flex   flex-column">
                <div className="col-12 col-sm-8 col-md-12 col-lg-12">
                  <DeviceInfo deviceInfo={ deviceInfo } /></div>
                <hr />
                <div className="d-flex flex-column flex-sm-row flex-lg-column col-12 col-sm-4 col-md-12">
                  <Rotate degree={ degree } setDegree={ setDegree } />
                  {/* <hr />  */ }
                  <AssignCampaign options={ options } selectedCampaign={ selectedCampaign } setSelectedCampaign={ setSelectedCampaign } />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-8 order-md-4 mb-4 order-lg-3">

          <Map />
          {/* <DeviceSetup /> */ }
        </div>
        <div className="col-md-12 col-lg-4 order-md-3 mb-4 order-lg-4">
          <ChartComponents />
        </div>
      </div>
    </Layout>
  );
};
export default DeviceShow;