import React from 'react'

import { Spinner } from 'react-bootstrap';
import { useGlobalContext } from '../../../Contexts/GlobalContext';
import { useProfileContext } from '../../../Contexts/ProfileContext';
import AddScreensDialog from '../Payment/AddScreensDialog';

function ScreenUsageCard() {

    const { subLoaders, subscriptionInfo, dialogHandler, openDialog, companyDetails } = useGlobalContext();
    const {  setAction } = useProfileContext();

    const addScreens = () => {
        setAction("addScreens")
        dialogHandler("addScreens")
    }

    return (
        <>
            <div className="card p-3 h-50 position-relative">
                {
                    subscriptionInfo.isFinished &&
                    <div className='position-absolute w-100 h-100 top-0 left-0 bg-white-opacity'></div>
                }
                {
                    subLoaders.companyDetails ?
                        <div className="d-flex justify-content-center align-items-center">
                            <Spinner variant="primary" as="span" size='lg' animation="border" role="status" className='mx-3 my-5 ' aria-hidden="true" />
                        </div>
                        :
                        <>
                            <div className='d-flex align-items-center'>
                                <span className='fs-4 fw-bold'>
                                    {companyDetails?.total_used_devices}
                                </span>
                                <sub className='fs-8 text-light'>
                                    &nbsp; / {companyDetails?.company?.allowed_device_number > 1000 ? "Unlimited" : companyDetails?.company?.allowed_device_number}
                                </sub>
                            </div>
                            <div className='fs-6 fw-bold mb-3' >Screens</div>
                            <div className='my-3'>
                                <div className="progress">
                                    <div className="progress-bar" role="progressbar" style=
                                        {{ width: `${(companyDetails?.total_used_devices / companyDetails?.company?.allowed_device_number) * 100}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                            <button disabled={companyDetails?.package?.is_free}
                                className='fs-12 btn btn-primary my-4 px-3  mx-auto'
                                onClick={() => addScreens()}
                            >
                                ADD MORE SCREENS
                            </button>
                        </>
                }
            </div>
            {
                openDialog.addScreens && <AddScreensDialog />
            }
        </>
    )
}

export default ScreenUsageCard
