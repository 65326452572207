import React, { useState } from 'react';

import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { useForm } from "react-hook-form";
import { Spinner } from 'react-bootstrap';
import { Dialog } from "primereact/dialog";

import { useGlobalContext } from '../../Contexts/GlobalContext';
import { useCustomToast } from '../../Contexts/ToastContext';
import axiosInstance from '../../../config/Axios';
import { useDeviceContext } from '../../Contexts/DeviceContext';

function Confirm () {
    const { openDialog, dialogHandler, setRerenderSubscription } = useGlobalContext();
    const { setRerender, setLoading } = useDeviceContext();

    const { register, formState: { errors }, handleSubmit } = useForm();
    const { showToast } = useCustomToast();
    const [checkResponse, setCheckResponse] = useState();

    const onSubmit = (data) => {
        setCheckResponse(true);
        axiosInstance.post("/device/confirm",
            {
                "code": data.code.replace(/\s/g, ''),
                "name": data.name
            }
        ).then((result) => {
            setCheckResponse(false);
            dialogHandler("confirmDialog")
            setRerenderSubscription(true)
            setRerender(true)
            setLoading(true)
            showToast('success', 'Device Confirmation', 'Device Confirmed Successfully!');
        }).catch((error) => {
            setCheckResponse(false);
            showToast('error', 'Device Confirmation', error.response?.data?.message);
        });

    };

    const handleFocus = (e) => {
        var val = e.target.value;
        e.target.value = '';
        e.target.value = val;
    }

    return (
        <>
            <Dialog visible={ openDialog.confirmDialog }
                style={ { width: '60%', height: "auto" } }
                header="New Device"
                breakpoints={ { '960px': '95vw' } }
                modal
                className="p-fluid"
                onHide={ () => dialogHandler("confirmDialog") }
            >
                <form onSubmit={ handleSubmit(onSubmit) } className="
                d-flex flex-column align-items-center justify-content-between h-100">
                    <div className="col-12 my-4 d-flex flex-column align-items-center justify-content-center py-4">

                        <div className="d-flex flex-column mb-4 align-items-start mb-3 w-50">
                            <label htmlFor="basic" className='mb-2'>Device Name</label>

                            <InputText
                                { ...register("name", { required: "Device name is required" }) }
                                className={ `w-100   ${ errors.name ? 'form-control is-invalid' : 'form-control' }` }
                                aria-invalid={ errors.name ? "true" : "false" }
                            />
                            { errors.name && <p className='fv-plugins-message-container invalid-feedback text-start'>{ errors.name?.message }</p> }
                        </div>

                        <div className="d-flex flex-column align-items-start w-50">
                            <label htmlFor="basic" className='mb-2'>Enter the 6-character pairing code</label>
                            <InputMask
                                dir='ltr'
                                { ...register("code", { required: "Device code is required" }) }
                                aria-invalid={ errors.code ? "true" : "false" }
                                className={ `w-100 text-center  ${ errors.code ? 'form-control is-invalid' : 'form-control' }` }
                                id='basic'
                                mask="9 9 9 9 9 9"
                                placeholder="9 9 9 9 9 9"
                                style={ { height: "100px", fontSize: "50px", letterSpacing: "2px" } }
                                onFocus={ handleFocus }
                            ></InputMask>

                            { errors.code && <p className='fv-plugins-message-container invalid-feedback text-start'>{ errors.code?.message }</p> }
                        </div>

                    </div>

                    <div className="col-12 text-center d-flex  align-items-end  justify-content-center py-4 border-top border-gray" >
                        <button type="reset" className="btn btn-label-secondary me-3" data-bs-dismiss="modal" aria-label="Close" disabled={ checkResponse ? true : false } onClick={ () => dialogHandler("confirmDialog") }>Cancel</button>
                        <button type="submit" className="btn btn-primary me-3 me-1 d-flex align-items-center" onClick={ () => onSubmit } disabled={ checkResponse ? true : false }>
                            {
                                checkResponse ? <Spinner variant="white" as="span" animation="border" size="sm" role="status" className='mx-3' aria-hidden="true" /> : "Confirm"
                            }

                        </button>
                    </div>
                </form>
            </Dialog>
        </>
    )
}

export default Confirm