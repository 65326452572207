import React, { useRef, useState } from 'react'

import { useParams } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';

import { useCustomToast } from '../../../Contexts/ToastContext';
import axiosInstance from '../../../../config/Axios';

function AssignCampaign ({ options, selectedCampaign, setSelectedCampaign }) {
    const { id } = useParams();
    const { showToast } = useCustomToast();

    const onCampaignChange = (e) => {
        setSelectedCampaign(e.value);
        axiosInstance.post(`/campaign/assign`,
            {
                "device_id": id,
                "campaign_ids": [e.value]
            }
        ).then((result) => {
            showToast('success', 'Assign Campaign', result?.data?.data?.msg);
        }).catch((error) => {
            showToast('success', 'Assign Campaign', error?.response?.data?.message);
        });
    }

    return (
        <>
            <div className="card-body py-2">
                <div className="card-header p-0 mb-2 ">
                    <div className=' d-flex align-items-center justify-content-between mb-2'>
                        <h5 className="m-0 me-2 ">Assign Campaign</h5>
                    </div>

                    <Dropdown className='w-100' value={ selectedCampaign } options={ options } onChange={ onCampaignChange }
                        optionLabel="name"
                        optionValue="code"
                        placeholder="Select campaign" />
                </div>
            </div>
        </>
    )
}

export default AssignCampaign