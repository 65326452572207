
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { ProgressSpinner } from 'primereact/progressspinner';
import { Checkbox } from 'primereact/checkbox';

import { useGlobalContext } from '../../../Contexts/GlobalContext';
import { useCustomToast } from '../../../Contexts/ToastContext';
import axiosInstance from '../../../../config/Axios';
import { isEmpty } from 'lodash';

function RssList ({ mediaType = "" }) {
    const { assign, layer, type, setAssign, dialogHandler, setDisableBtn, setRerenderList } = useGlobalContext();
    const { showToast } = useCustomToast();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageNum, setPageNum] = useState(1);
    const [lastElement, setLastElement] = useState(null);
    const [lastPage, setLastPage] = useState(null);
    const [isUploaded, setIsUploaded] = useState(false);
    const [ids, setIds] = useState([]);

    const { id } = useParams();

    const observer = useRef(
        new IntersectionObserver((entries) => {
            const first = entries[0];
            if (first.isIntersecting) {
                setPageNum((no) => no + 1);
            }
        })
    );

    const callMedia = async () => {
        setLoading(true);
        let response = await axiosInstance.get(`/rss/feeds${ isEmpty(mediaType) ? "" : `/${ mediaType }` }?page=${ pageNum }&per_page=12`);

        let all = new Set([...data, ...response.data.data]);
        setData([...all]);
        setLastPage(response.data.pagination.total_pages);
        setLoading(false);
    };

    useEffect(() => {
        if (pageNum <= lastPage || lastPage == null) {
            callMedia();
        }
    }, [pageNum]);

    useEffect(() => {
        if (isUploaded) {
            setPageNum(1, () => callMedia());
            setIsUploaded(false);
        }
    }, [isUploaded]);

    useEffect(() => {
        const currentElement = lastElement;
        const currentObserver = observer.current;

        if (currentElement) {
            currentObserver.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                currentObserver.unobserve(currentElement);
            }
        };
    }, [lastElement]);

    useEffect(() => {
        if (assign) {
            setDisableBtn(true)

            axiosInstance.post("/campaign/media/assign", {
                "campaign_id": id,
                "layer": layer,
                "list_media_ids": ids
            })
                .then((result) => {
                    setDisableBtn(false)
                    if (result?.data?.data?.error != "") {
                        showToast('error', `Assign Media to ${ type }`, result?.data?.data?.error);
                    }
                    else {
                        showToast('success', `Assign Media to ${ type }`, result?.data?.data?.msg);
                        dialogHandler("addContent")
                    }
                    setRerenderList(true)
                }).catch((error) => {
                    setDisableBtn(false);
                    if (error?.response?.data?.errors)
                        Object.values(error?.response?.data?.errors).forEach(error => {
                            showToast('error', `Assign Media to ${ type }`, error[0]);
                        });
                });
            setAssign(false)
        }

    }, [assign])


    const onMediaChange = (e) => {
        let selectedMedia = [...ids];

        if (e.checked)
            selectedMedia.push(e.value);
        else
            selectedMedia.splice(selectedMedia.indexOf(e.value), 1);

        setIds(selectedMedia);
    }

    return (
        <>
            {
                loading && data.length == 0 ?
                    <ProgressSpinner
                        style={ { width: "60px", height: "60px" } }
                        strokeWidth="3"
                        fill="var(--surface-ground)"
                        animationDuration="1s" />
                    :
                    data.length == 0 && !loading ?
                        <h5 className="py-5 text-center w-100">There were no RSS feeds added!</h5>
                        :
                        <div className="d-flex flex-column scroll_container scroll_div w-100">

                            <div className="d-flex flex-wrap mt-2 mb-4 ">
                                {
                                    data.map((item, index) => {
                                        return index === data.length - 1 && pageNum <= lastPage ?
                                            <div className="px-2 w-100 d-flex align-items-center justify-content-between my-1 pb-2" ref={ setLastElement } key={ `${ item.type }-${ index }` } >
                                                <label htmlFor={ item.id } className="col-11 d-flex ">
                                                    <div className="col-2 mx-2 ">
                                                        <div className={ `p-4 d-flex bg-secondary align-items-center justify-content-center playlist_icon_container w-75` }>
                                                            <span className=" fs-6 text-white"> RSS</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-9 col-md-3 col-sm-6 mx-1 fs-6 d-flex align-items-center "> { item.file_name } </div>
                                                    <div className="col-4 d-flex align-items-center justify-content-center text-capitalize d-none d-sm-block fs-6 text-light fs-6">
                                                        { (item.type).replace(/_/g, ' ') }
                                                    </div>
                                                </label>
                                                <Checkbox inputId={ item.id } name="media" className='me-3' value={ item.id } onChange={ onMediaChange } checked={ ids.indexOf(`${ item.id }`) !== -1 } />
                                            </div>
                                            :
                                            <div className={ `px-2 w-100 d-flex align-items-center justify-content-between my-1 me-3 pb-2 ${ index + 1 === data.length ? "" : "border-bottom" } ` } key={ `${ item.type }-${ index }` } >
                                                <label htmlFor={ item.id } className="col-11 d-flex ">
                                                    <div className={ `col-2 mx-2 ` }>
                                                        <div className={ `p-4 d-flex bg-secondary align-items-center justify-content-center playlist_icon_container w-75` }>
                                                            <span className=" fs-6 text-white"> RSS</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-9 col-sm-6 col-md-3   mx-1 fs-6 d-flex align-items-center text-truncate"> { item.file_name } </div>
                                                    <div className="col-3 d-flex align-items-center justify-content-center d-none d-sm-flex text-capitalize fs-6 text-light fs-6 fst-italic  ">
                                                        <span>{ item.type == "HDMI" ? item.type : item.type.toLowerCase() }</span>
                                                    </div>
                                                </label>
                                                <div className="col-2 col-sm-1 me-3">
                                                    <Checkbox inputId={ item.id } name="media" className='me-3' value={ item.id } onChange={ onMediaChange } checked={ ids.indexOf(`${ item.id }`) !== -1 } />
                                                </div>
                                            </div>
                                    })
                                }
                                {
                                    loading &&
                                    <ProgressSpinner style={ { width: '60px', height: '60px', opacity: .1 } } strokeWidth="3" fill="var(--surface-ground)" animationDuration="1s" />
                                }
                            </div>
                        </div>
            }
        </>
    );
}

export default RssList