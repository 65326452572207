import React, { useEffect } from "react";

import { onValue, ref } from "firebase/database";
import { db } from "../../config/firebase";

function FirebaseDashboardListener ({ path, getData }) {

    useEffect(() => {
        const query = ref(db, process.env.REACT_APP_APP_ENV + "/" + path);
        return onValue(query, (snapshot) => {
            const data = snapshot.val();
            if (snapshot.exists()) {
                getData();
            }
        });

    }, []);

    return (
        <></>
    )
}

export default FirebaseDashboardListener