import React, { useState, useEffect } from "react";
import { SelectButton } from "primereact/selectbutton";

import { useTitle, } from "../../config/global";
import "../Media/css/mediaTable.css";

import UploadPopup from "../Media/components/UploadPopup/UploadPopup";
import BreadCrumb from "../Media/components/MediaLayout/BreadCrumb";
import MediaCard from "../Media/components/MediaLayout/MediaCard";
import Layout from "../Layout/Layout";

import { useGalleryContext } from "../Contexts/GalleryContext";
import { ProgressSpinner } from "primereact/progressspinner";
import FirebaseListener from "../Services/FirebaseMediaListener";
import axiosInstance from "../../config/Axios";

function LinksDataTable () {
  useTitle("Links");

  const { layout, setLayout, rerender, setRerender } = useGalleryContext();

  const [media, setMedia] = useState([]);
  const [loading, setLoading] = useState(true);
  const [breadCrumb, setBreadCrumb] = useState([]);
  const [currentFolder, setCurrentFolder] = useState({ id: "", name: "", });

  //fetch data  when mount component
  useEffect(() => {
    setCurrentFolder({
      id: "",
      name: ""
    });
    getData();
  }, []);

  // Rerender component when update rerender state 
  useEffect(() => {
    if (rerender) {
      getData(currentFolder.id)
    };
  }, [rerender]);

  const getData = (folder_id = "", showLoading = true) => {
    if (showLoading)
      setLoading(true);

    axiosInstance.get(`/folder/media?section=LINKS`)
      .then((res) => {
        setMedia(res?.data?.data);
        setLoading(false);
        setRerender(false);
      })
      .catch((error) => {

      });
  };

  //Layout option
  const justifyOptions = [
    { icon: "pi pi-align-justify", value: "list" },
    { icon: "pi pi-th-large", value: "grid" },
  ];

  const justifyTemplate = (option) => {
    return <i className={ option.icon }></i>;
  };

  return (
    <Layout>
      <div className="dataview-demo ">
        <div className="d-flex flex-column justify-content-center bg-white rounded-3 media_container px-auto mx-1 ">
          <div className="col-12 px-3 d-flex justify-content-between align-items-center btn_container border-bottom">
            <div className="d-flex ">
              <SelectButton
                value={ layout }
                options={ justifyOptions }
                className="mx-1 layout_btn"
                onChange={ (e) => setLayout(prev => e.value == null ? prev : e.value) }
                itemTemplate={ justifyTemplate }
                optionLabel="value" />
              {
                breadCrumb.length != 0 &&
                <BreadCrumb setMedia={ setMedia } getData={ getData } breadCrumb={ breadCrumb } setBreadCrumb={ setBreadCrumb } setCurrentFolder={ setCurrentFolder } />
              }
            </div>
            <div className=" d-flex justify-content-end align-items-center  my-3">
              <UploadPopup currentFolder={ currentFolder } setRerender={ setRerender } content="links" />
            </div>
          </div>

          {/* <DragDrop /> */ }
          <div className="row justify-content-center py-3">
            {
              loading ?
                <ProgressSpinner style={ { width: "60px", height: "60px" } } strokeWidth="3" fill="var(--surface-ground)" animationDuration="1s" />
                :
                media.length == 0
                  ?
                  <h5 className="py-5 text-center w-100">No media assigned yet!</h5>
                  :
                  <>
                    {
                      layout == "list" &&
                      <div className="media_list mb-2">
                        <div className="col-1"></div>
                        <div className="col-md-3 col-sm-3 col-4 text-center text-truncate pe-4"></div>
                        <div className="col-md-1 col-2 fs-6 px-0 text-center fst-italic d-none d-sm-block"> Duration </div>
                        <div className="col-2 fs-6 px-0 text-center fst-italic  d-none d-sm-block">Kind </div>
                        <div className="col-md-2 col-2 fs-6 px-0 text-center fst-italic">Size</div>
                        <div className="col-md-2 col-2 fs-6 px-0 text-center fst-italic d-none d-xl-block">Created-at</div>
                        <div className="col-3"> </div>
                      </div>
                    }
                    <div className="cards_container row justify-content-center align-items-start mx-auto scroll_div">
                      {
                        media.map((item, index) => {
                          return (
                            <div key={ item.id }
                              className={ `w-98 ${ layout == "list"
                                ? `media_list ${ index + 1 != media.length
                                  ? "border-bottom border-gray"
                                  : ""
                                } `
                                : "media_grid"
                                } mx-2 py-2 media position-relative` }
                            >
                              <MediaCard setRerender={ setRerender } item={ item } layout={ layout } />
                            </div>
                          );
                        })
                      }
                    </div>
                  </>
            }
          </div>
        </div>
      </div>
      <FirebaseListener path={ "/Companies/" + localStorage.getItem("company_id") + "/actions" } getData={ getData } currentFolder={ currentFolder } />
    </Layout>
  );
}

export default LinksDataTable;
