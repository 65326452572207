import React, { useEffect, useState } from 'react'

import { ProgressSpinner } from 'primereact/progressspinner';
import { InputNumber } from 'primereact/inputnumber';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';

import { useScrollLoadingContext } from '../../../Contexts/ScrollLoadingContext';
import { useProfileContext } from '../../../Contexts/ProfileContext';
import { useGlobalContext } from '../../../Contexts/GlobalContext'
import DevicesList from './DevicesList';

function AddScreensDialog () {
  const { paymentData, onScreenChange, selectedPackage, setSelectedPackage, selectedPricing, setSelectedPricing, numberOfScreens } = useProfileContext();
  const { ids, setIds, data, pageNum, loading, lastPage, setLastElement, setPath } = useScrollLoadingContext();
  const { openDialog, dialogHandler, companyDetails } = useGlobalContext();

  const [isValid, setIsValid] = useState(true);
  const [showDeviceList, setShowDeviceList] = useState();

  useEffect(() => {
    setPath(`/company/devices/list?page=${ pageNum }&per_page=15&action=addScreens`)
  }, [])

  const onDevicesChange = (e) => {
    let selectedDevices = [...ids];

    if (e.checked)
      selectedDevices.push(e.value);
    else
      selectedDevices.splice(selectedDevices.indexOf(e.value), 1);

    setIds(selectedDevices);
  }


  const addScreens = () => {
    if (isValid) {
      dialogHandler('addScreens')
      dialogHandler('paymentDialog')
    }
  }

  const closeDialog = () => {
    setIds([])
    dialogHandler('addScreens')
  }

  useEffect(() => {
    onScreenChange("onChange", 1);
    setSelectedPackage(companyDetails?.package)
    setSelectedPricing(companyDetails?.subscription_pricing)
  }, [])

  useEffect(() => {
    setShowDeviceList(numberOfScreens < data.length)
  }, [data])

  useEffect(() => {
    if (ids?.length > 0 && ids?.length < numberOfScreens)
      setIsValid(false)
    else {
      setIsValid(true)
    }
  }, [ids])

  useEffect(() => {
    if (numberOfScreens < data.length && ids.length > numberOfScreens) {
      setIds(prev => prev.slice(0, numberOfScreens))
    }
    if (numberOfScreens > data.length)
      setIds([])

    setShowDeviceList(numberOfScreens < data.length)
  }, [numberOfScreens])

  return (
    <Dialog visible={ openDialog.addScreens }
      breakpoints={ { '960px': '95vw' } }
      style={ { width: showDeviceList ? '60%' : '30%' } }
      modal
      className="p-fluid custom-dialog position-relative"
      onHide={ () => closeDialog() }
    >
      {/* Close button  */ }
      <button className='position-absolute close_icon text-primary' onClick={ closeDialog } >
        <i className="fa-regular fa-circle-xmark text-primary"></i>
      </button>

      <div className="d-flex">
        <div className={ `${ showDeviceList ? "col-6" : "col-12" } d-flex flex-column p-5` } >

          <span className=' fs-5 fw-bold mb-2 col-12'>Add More Screens</span>
          <div className='col-12  d-flex align-items-start mt-2'>
            <div className="col-6 fs-8 ms-1">
              <div className="ms-1">Plan Category</div>
              <div className={ `d-flex justify-content-between pe-1` } >
                <div className={ ` field-radiobutton col-12 d-flex align-items-center  mb-3 rounded p-3 border-2 border-primary }` } >
                  <RadioButton
                    name="package"
                    className='d-flex align-items-center justify-content-center radio_bg'
                    value={ selectedPackage?.id }
                    checked={ true }
                  />
                  <label htmlFor={ selectedPackage?.id } className={ `fs-8` } >
                    { selectedPackage?.title }
                  </label>
                </div>
              </div>
            </div>

            <div className="col-6 fs-8 ms-1">
              <div className="ms-1"> Billing Cycle</div>
              <div className={ `d-flex justify-content-between pe-1` }>
                <div className={ `field-radiobutton col-12 d-flex align-items-center mb-1 rounded p-2 border-2 border-primary` } >
                  <RadioButton
                    inputId={ selectedPricing?.id }
                    name="package"
                    className='d-flex align-items-center justify-content-center radio_bg'
                    value={ selectedPricing?.id }
                    checked={ true }
                  />
                  <label htmlFor={ selectedPricing?.id } className={ `fs-8` }>
                    { (paymentData?.period?.replace(/_/g, ' '))?.toLowerCase() }
                    <br />
                    { `$ ${ paymentData?.singleScreenPrice }` }
                    <span className='fs-10 fw-light'>
                      /screen
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className='col-12  d-flex flex-column mb-3'>
            <div className="col-12 fs-8 ms-1">
              Number of Screens
            </div>
            <div className="col-5 d-flex  mt-1">
              <InputNumber inputId="number_of_screens"
                value={ numberOfScreens }
                onValueChange={ (e) => onScreenChange("onChange", e.value) }
                min={ 1 }
                className=''
              />
              <div className='mx-2 arrow_container mt-1'>
                <i className="fas fa-caret-square-up text-primary fs-4 cursor-pointer" onClick={ () => onScreenChange("increment") }></i>
                <button disabled={ numberOfScreens == 1 } onClick={ () => onScreenChange("decrement") } >
                  <i className={ `fas fa-caret-square-down fs-4 ${ numberOfScreens == 1 ? "text-light" : " text-primary cursor-pointer" }` }></i>
                </button>
              </div>
            </div>
          </div>

          <div className='col-12 d-flex flex-column mb-4 py-2 px-4 bg_gray'>
            <div className="col-12 d-flex flex-wrap fs-8 ms-2">
              <div className="col-8 mb-1">Subtotal</div>
              <div className="col-4 text-primary">$ { paymentData?.subtotal }</div>

              <div className="col-8">Tax <span className='fs-10'>( { paymentData?.selectedPackage?.tax }% VAT )</span></div>
              <div className="col-4 text-primary">$ { paymentData?.tax }</div>
            </div>
            <hr className='my-2' />
            <div className="col-12 d-flex ms-2 fs-6 fw-bold">
              <div className="col-8 ">Total</div>
              <div className="col-4 text-primary">$ { paymentData.total }</div>
            </div>
          </div>

          <div className='d-flex justify-content-center '>
            <button type="reset" className="btn btn-label-secondary me-3 px-4 border " onClick={ closeDialog } data-bs-dismiss="modal" aria-label="Close">
              Cancel
            </button>

            <button type="submit" className="btn btn-primary px-4" onClick={ () => { addScreens() } }>
              Add Screens
            </button>
          </div>
        </div>

        <div className={ `col-6 d-flex flex-column p-5 bg_gray ${ showDeviceList ? "" : "d-none" }` }  >
          <span className='fs-5 fw-bold mb-2'>Choose the screens you want to keep:</span>

          {
            data?.length == 0 && loading ?
              <ProgressSpinner style={ { width: "60px", height: "60px" } } strokeWidth="3" fill="var(--surface-ground)" animationDuration="1s" />
              :
              data?.length > 0 ?
                <div className={ `d-flex flex-column scroll_container scroll_div px-2 w-100 ${ numberOfScreens > data.length ? "d-none" : "" }` }  >
                  <div className="popular-movies-api d-flex flex-wrap ">
                    {
                      data?.map((item, index) => {
                        let _disabled = numberOfScreens == ids.length && ids.indexOf(`${ item.id }`) == -1;
                        return index === data?.length - 1 && pageNum <= lastPage ?
                          (
                            <div className="col-12 d-flex align-items-center justify-content-between " ref={ setLastElement } key={ `${ item.type }-${ index }` }>
                              <div className="col-2 col-sm-1 pe-2">
                                <Checkbox inputId={ item.id } name="media" disabled={ _disabled } className='me-3' value={ item.id } onChange={ onDevicesChange } checked={ ids.indexOf(`${ item.id }`) !== -1 } />
                              </div>
                              <label htmlFor={ item.id } className={ `col-10 col-sm-11 d-flex text-truncate ${ _disabled ? "text-light" : "" } ` }>
                                { item.name }
                              </label>
                            </div>
                          ) : (
                            <div className="col-12 d-flex align-items-center justify-content-between my-1 pb-2" key={ `${ item.type }-${ index }` } >
                              <div className="col-2 col-sm-1 pe-2">
                                <Checkbox inputId={ item.id } name="media"
                                  disabled={ _disabled }
                                  className='me-3' value={ item.id }
                                  onChange={ onDevicesChange }
                                  checked={ ids.indexOf(`${ item.id }`) !== -1 }
                                />
                              </div>
                              <label htmlFor={ item.id } className={ `col-10 col-sm-11 d-flex text-truncate ${ _disabled ? "text-light" : "" }` } >
                                { item.name }
                              </label>
                            </div>
                          )
                      })
                    }
                  </div>

                  { loading ?
                    <ProgressSpinner style={ { width: '60px', height: '60px', opacity: .1 } } strokeWidth="3" fill="var(--surface-ground)" animationDuration="1s" />
                    : <></>
                  }

                </div>
                :
                <></>
          }
          {
            !isValid ?
              <div div className="text-danger"> { `You have to select ${ numberOfScreens - ids?.length } more device${ numberOfScreens - ids.length == 1 ? "" : "s" } !` }</div>
              :
              <></>
          }
        </div>

      </div>
    </Dialog>
  )
}

export default AddScreensDialog