import React, { useState, useEffect, useRef } from 'react';

import { Spinner } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';

import { useGlobalContext } from '../../../Contexts/GlobalContext';
import { useAppsContext } from '../../../Contexts/AppsContext';
import { useCustomToast } from "../../../Contexts/ToastContext"
import axiosInstance from '../../../../config/Axios';
import DevicesList from '../../../Campaign/Components/assignPopup/devices/DevicesList';

function PrayerEnableDialog () {

    const { disableBtn, setDisableBtn } = useGlobalContext();
    const { prayerEnableDialog, setPrayerEnableDialog, setIsLoading } = useAppsContext();
    const { showToast } = useCustomToast();

    const [data, setData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [lastElement, setLastElement] = useState(null);
    const [lastPage, setLastPage] = useState(null);

    const [loading, setLoading] = useState(true);
    const [listLoading, setListLoading] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [isUploaded, setIsUploaded] = useState(false);

    const [ids, setIds] = useState([]);

    const observer = useRef(
        new IntersectionObserver((entries) => {
            const first = entries[0];
            if (first.isIntersecting) {
                setPageNum((no) => no + 1);
            }
        })
    );

    useEffect(() => {
        callMedia();
    }, []);

    //Get data 
    const callMedia = () => {
        if (pageNum == 1) {
            setLoading(true);
        } else {
            setListLoading(true)
            setShowLoading(true)
        }

        axiosInstance.get(`/company/devices?page=${ pageNum }&per_page=7`)
            .then((response) => {
                if (pageNum == 1) {
                    setData([...response?.data?.data]);
                    let idsArr = response?.data?.data.filter(item => item.is_prayer_enable === true).map(item => item.id)
                    setIds(idsArr);
                } else {
                    let all = new Set([...data, ...response?.data?.data]);
                    setData([...all]);
                    response?.data?.data.filter(item => item.is_prayer_enable === true).map(item => setIds(prev => [...prev, item.id]))
                    setListLoading(false)
                }
                setLastPage(response.data.pagination.total_pages);
                setLoading(false);
            })
            .catch(errors => {
                setLoading(false);
            });
    };

    // fetch data if not last page (pagination)
    useEffect(() => {
        if (pageNum <= lastPage || lastPage == null) {
            callMedia();
        }
    }, [pageNum]);


    useEffect(() => {
        if (isUploaded) {
            setPageNum(1, () => callMedia());
            setIsUploaded(false);
        }
    }, [isUploaded]);

    //Scroll observer
    useEffect(() => {
        const currentElement = lastElement;
        const currentObserver = observer.current;

        if (currentElement) {
            currentObserver.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                currentObserver.unobserve(currentElement);
            }
        };
    }, [lastElement]);

    // Render Dialog Footer
    const renderFooter = () => {
        return (
            <div>
                <Button
                    label={ loading ?
                        <Spinner as="span" animation="border" size="sm" role="status" className='mx-3' aria-hidden="true" />
                        : "Update"
                    }
                    className='btn fs-6 py-2 px-3'
                    disabled={ disableBtn ? true : false }
                    onClick={ updateDevices } />
            </div>
        );
    }

    // Prayer Enable handler
    const updateDevices = () => {
        setDisableBtn(true);
        setLoading(true)

        axiosInstance.post(`/device/prayer/enable`, { "ids": ids }
        ).then((result) => {
            setIds([]);
            setIsLoading(true)
            showToast('success', "Updating Devices ", "Devices updated successfully!");
            setPrayerEnableDialog(false)
        })
        setDisableBtn(false)
        setLoading(false)
    }

    return (
        <>
            <Dialog header="Devices List" visible={ prayerEnableDialog }
                onHide={ () => setPrayerEnableDialog(false) }
                breakpoints={ { '960px': '95vw' } }
                style={ { width: '40vw' } } footer={ renderFooter }>

                <div className="d-flex device_assign_container border-top border-bottom ">
                    <div className="col-12 d-flex flex-column mt-3 ">
                        <div className="d-flex flex-wrap justify-content-center scroll_container scroll_div px-4">
                            {
                                loading ?
                                    <>
                                        <Spinner variant="primary" as="span" animation="border" size="lg" role="status" className='m-3' aria-hidden="true" />
                                    </>
                                    :
                                    <DevicesList
                                        loading={ loading }
                                        data={ data }
                                        ids={ ids }
                                        setIds={ setIds }
                                        setLastElement={ setLastElement }
                                        pageNum={ pageNum }
                                        lastPage={ lastPage }
                                        listLoading={ listLoading }
                                    />
                            }

                        </div>
                    </div>

                    { showLoading && <ProgressSpinner style={ { width: '60px', height: '60px', opacity: .1 } } strokeWidth="3" fill="var(--surface-ground)" animationDuration="1s" /> }
                </div>
            </Dialog>
        </>
    )
}

export default PrayerEnableDialog