
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { Checkbox } from 'primereact/checkbox';

import { useCustomToast } from '../../Contexts/ToastContext';
import LazyImage from '../ShowMedia/LazyImage'

import { useGlobalContext } from '../../Contexts/GlobalContext';
import { ProgressSpinner } from 'primereact/progressspinner';
import axiosInstance from '../../../config/Axios';

function LinksList ( ) {
    const { assign, layer, setAssign, dialogHandler, setDisableBtn, campaignDetails, setRerenderList } = useGlobalContext();
    const { showToast } = useCustomToast();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageNum, setPageNum] = useState(1);
    const [lastElement, setLastElement] = useState(null);
    const [lastPage, setLastPage] = useState(null);
    const [isUploaded, setIsUploaded] = useState(false);
    const [ids, setIds] = useState([]);

    const { id } = useParams();

    const observer = useRef(
        new IntersectionObserver((entries) => {
            const first = entries[0];
            if (first.isIntersecting) {
                setPageNum((no) => no + 1);
            }
        })
    );

    const callMedia = async () => {
        setLoading(true);
        let response = await axiosInstance.get(
            `/media?page=${ pageNum }&per_page=12&section=links&layer=${ layer }&is_interactive=${ campaignDetails.is_interactive ? 1 : 0 }`
        );
        let all = new Set([...data, ...response.data.data]);
        setData([...all]);
        setLastPage(response.data.pagination.total_pages);
        setLoading(false);
    };

    useEffect(() => {
        if (pageNum <= lastPage || lastPage == null) {
            callMedia();
        }
    }, [pageNum]);

    useEffect(() => {
        if (isUploaded) {
            setPageNum(1, () => callMedia());
            setIsUploaded(false);
        }
    }, [isUploaded]);

    useEffect(() => {
        const currentElement = lastElement;
        const currentObserver = observer.current;

        if (currentElement) {
            currentObserver.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                currentObserver.unobserve(currentElement);
            }
        };
    }, [lastElement]);

    useEffect(() => {
        if (assign) {
            setDisableBtn(true)

            if (window.location.pathname.includes("playlist")) {
                assignHandler(
                    "/playlist/media/assign",
                    "Playlist",
                    {
                        "playlist_id": id,
                        "layer": layer,
                        "list_media_ids": ids
                    }
                )
            } else {
                assignHandler(
                    "/campaign/media/assign",
                    "Campaign",
                    {
                        "campaign_id": id,
                        "layer": layer,
                        "list_media_ids": ids
                    },
                )
            }
        }
    }, [assign])

    const assignHandler = (url, type, data) => {
        axiosInstance.post(url, data)
            .then((result) => {
                setDisableBtn(false)
                if (result?.data?.data?.error != "") {
                    showToast('error', `Assign Media to ${ type }`, result?.data?.data?.error);
                }
                else {
                    showToast('success', `Assign Media to ${ type }`, result?.data?.data?.msg);
                    dialogHandler("addContent")
                }
                setRerenderList(true)
            }).catch((error) => {
                setDisableBtn(false);
                if (error?.response?.data?.errors)
                Object.values(error?.response?.data?.errors).forEach(error => {
                    showToast('error', `Assign Media to ${ type }`, error[0]);
                });
            });
        setAssign(false)
    }

    const onMediaChange = (e) => {
        let selectedMedia = [...ids];

        if (e.checked)
            selectedMedia.push(e.value);
        else
            selectedMedia.splice(selectedMedia.indexOf(e.value), 1);

        setIds(selectedMedia);
    }

    return (
        <>
            {
                loading && data.length == 0 ?
                    <ProgressSpinner
                        style={ { width: "60px", height: "60px" } }
                        strokeWidth="3"
                        fill="var(--surface-ground)"
                        animationDuration="1s" />
                    :
                    data.length == 0 && !loading ?
                        <h5 className="py-5 text-center w-100">There were no compatible links with this campaign  uploaded!</h5>
                        :
                        <div className="d-flex flex-column scroll_container scroll_div px-3 w-100">
                            <div className="row my-3">
                                <div className="col-2"></div>
                                <div className="col-3 mx-2 text-light fs-6  ps-5"> name</div>
                                <div className="col-3 fs-6 text-center text-light d-none d-sm-block" >type</div>
                            </div>
                            <div className="d-flex flex-wrap  mb-4">
                                {
                                    data.length > 0 &&
                                    data.map((item, index) => {
                                        let imgEl = null;
 
                                        if (item.type != "HDMI") {
                                            imgEl = <LazyImage media={ item } popupType={ "media_preview" } index={ index } styleClass={ "me-2 image_container  w-75" } />;
                                        } else {
                                            imgEl = <div className={ `p-4 d-flex bg-secondary align-items-center justify-content-center playlist_icon_container w-75` }>
                                                <span className=" fs-6 text-white"> HDMI</span>
                                            </div>
                                        }

                                        return index === data.length - 1 && pageNum <= lastPage ?
                                            <div className=" w-100 d-flex align-items-center justify-content-between my-1 pb-2" ref={ setLastElement } key={ `${ item.type }-${ index }` } >
                                                <label htmlFor={ item.id } className="col-11 d-flex ">
                                                    <div className="col-2 mx-2 "> { imgEl }  </div>
                                                    <div className="col-9 col-md-3 col-sm-6 mx-1 fs-6 d-flex align-items-center "> { item.file_name } </div>
                                                    <div className="col-4 d-flex align-items-center justify-content-center text-capitalize d-none d-sm-block fs-6 text-light fs-6">
                                                        { item.type }
                                                    </div>
                                                </label>
                                                <Checkbox  inputId={ item.id } name="media" className='me-3' value={ item.id } onChange={ onMediaChange } checked={ ids.indexOf(`${ item.id }`) !== -1 } />
                                            </div>
                                            :
                                            <div className={ `w-100 d-flex align-items-center justify-content-between my-1 me-3 pb-2 ${ index + 1 === data.length ? "" : "border-bottom" } ` } key={ `${ item.type }-${ index }` } >
                                                <label htmlFor={ item.id } className="col-11 d-flex ">
                                                    <div className={ `col-2 mx-2 ` }> { imgEl }</div>
                                                    <div className="col-9 col-sm-6 col-md-3   mx-1 fs-6 d-flex align-items-center text-truncate"> { item.file_name } </div>
                                                    <div className="col-3 d-flex align-items-center justify-content-center d-none d-sm-flex text-capitalize fs-6 text-light fs-6 fst-italic  ">
                                                        <span>{ item.type == "HDMI" ? item.type : item.type.toLowerCase() }</span>
                                                    </div>
                                                </label>
                                                <div className="col-2 col-sm-1 me-3">
                                                    <Checkbox inputId={ item.id } name="media" className='me-3' value={ item.id } onChange={ onMediaChange } checked={ ids.indexOf(`${ item.id }`) !== -1 } />
                                                </div>
                                            </div>
                                    })
                                }
                                {
                                    loading &&
                                    <ProgressSpinner style={ { width: '60px', height: '60px', opacity: .1 } } strokeWidth="3" fill="var(--surface-ground)" animationDuration="1s" />
                                }
                            </div>
                        </div>
            }
        </>
    );
}

export default LinksList