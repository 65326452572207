import React, { useEffect, useState, useRef } from 'react'
import { useForm, Controller } from "react-hook-form";

import { useScrollLoadingContext } from '../../Contexts/ScrollLoadingContext';
import { useGlobalContext } from '../../Contexts/GlobalContext';
import { useAppsContext } from '../../Contexts/AppsContext';
 
import { Dialog } from 'primereact/dialog';
import FeedsList from './FeedsList';

import FeedForm from './FeedForm';

function AddFeedDialog () {
    const { openDialog, dialogHandler } = useGlobalContext();
    const { selectedRss, resetStates } = useAppsContext();
    const { setPath, setPageNum } = useScrollLoadingContext();

    useEffect(() => {
        setPageNum(1)
        setPath(`/rss/feeds/${ selectedRss?.id }/contents`)
        return () => {
            setPath("")
            resetStates()
        }
    }, [])

    const header = () => {
        return (
            <div className='d-flex'>
                <span className="avatar-initial rounded bg-label-primary py-2  px-3 me-2">
                    <i className="fa-solid fa-rss fs-4"></i>
                </span>
                <h3 className='my-2 me-2'>{ selectedRss?.file_name }</h3>
            </div>
        )
    }

    return (
        <Dialog visible={ openDialog.addCustomFeed }
            style={ { width: '60%' } }
            breakpoints={ { '960px': '95vw' } }
            header={ header }
            modal className="p-fluid"
            maximizable={ true }
            onHide={ () => dialogHandler("addCustomFeed") }
        >
            <div className="d-flex flex-wrap ">
                <div className="col-7 border-end p-3">
                    <h5 className='col-12 mb-2'>Created News Content</h5>
                    <div className="d-flex flex-column scroll_container scroll_div">
                        <FeedsList />
                    </div>
                </div>
                <div className="col-5 d-flex flex-column">
                    <FeedForm />
                </div>
            </div>
        </Dialog>
    )
}

export default AddFeedDialog