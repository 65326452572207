import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { getFormErrorMessage } from '../../../config/global';

import { ProgressSpinner } from "primereact/progressspinner";
import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
import { Message } from 'primereact/message';

import FirebaseChannelsListener from '../../Services/FirebaseChannelsListener';
import DeviceLastLoginListener from '../../Services/DeviceLastLoginListener';
import { useGlobalContext } from '../../Contexts/GlobalContext';
import { useDeviceContext } from '../../Contexts/DeviceContext';
import { useCustomToast } from '../../Contexts/ToastContext';
import axiosInstance from '../../../config/Axios';

const ChannelsList = ( ) => {

    const { selectedDevice, setSelectedDevice, onlineDevices, setOnlineDevices } = useDeviceContext();
    const { layer, assign, setAssign, dialogHandler, setDisableBtn, type, setRerenderList } = useGlobalContext();
    const { showToast } = useCustomToast();

    const { formState: { errors }, control, handleSubmit, reset } = useForm();

    const [selectedChannel, setSelectedChannel] = useState({});
    const [channelsList, setChannelsList] = useState([]);
    const [devicesList, setDevicesList] = useState([]);

    const [showDevices, setShowDevices] = useState(false);
    const [loading, setLoading] = useState(true);
    const [counter, setCounter] = useState(10);

    const { id } = useParams();

    const getData = () => {
        setLoading(true);
        axiosInstance.get(`/campaign/device/${ id }/check/satellite`)
            .then((res) => {
                if (res?.data == 0) {
                    getDevices();
                    setShowDevices(true);
                }
                setLoading(false);
            })
    };

    // Get company's devices which support satellite
    const getDevices = () => {
        axiosInstance.get(`/company/satellite/devices`)
            .then((res) => {
                setDevicesList(res?.data?.data);
                // Set default value for dropdown if there are no selected device
                if (selectedDevice.id == "" && devicesList.length != 0) {
                    setSelectedDevice({
                        serial_name: res?.data?.data[0].serial_name,
                        name: res?.data?.data[0].name,
                        id: res?.data?.data?.[0].id,
                        online: 0,
                    })
                    fetchChannels(res?.data?.data?.[0].id);
                }
            })
    };

    // Fetch selected device channels 
    const fetchChannels = (id = null) => {
        setTimeout(() => {
            axiosInstance.get(`/device/${ id ?? selectedDevice.id }/channels`)
        }, 1000);
    }

    const onSubmit = () => {
        setDisableBtn(true);

        let formData = {
            "channel_name": channelsList[selectedChannel - 1].serviceName,
            "channel_id": selectedChannel,
            "layer": layer
        }

        if (window.location.pathname.includes("campaign")) {
            var url = `/campaign/channels/assign`;
            formData.campaign_id = id;

        } else {
            var url = `/playlist/channel/assign`;
            formData.playlist_id = id;
        }

        axiosInstance.post(url, formData)
            .then((res) => {
                dialogHandler("addContent")
                setRerenderList(true);
            }).catch(error => {
                setDisableBtn(false);
                showToast('error', `Assign Media to ${ type }`, error?.response?.data?.message);
            })

        setDisableBtn(false);
        setAssign(false);
    }

    const deviceHandler = (id) => {
        devicesList.map(item => {
            if (item.id == id)
                setSelectedDevice({
                    id: id,
                    name: item.name,
                    online: 0,
                    serial_name: item.serial_name
                })
        })
    }

    useEffect(() => {
        reset({ "device": selectedDevice.id })
        setDevicesList([])
        getData();
    }, []);

    useEffect(() => {
        if (selectedDevice.id != "" && !onlineDevices[selectedDevice.serial_name]) {
            setOnlineDevices(prev => ({ ...prev, [selectedDevice.serial_name]: 0 }))
        }
    }, [selectedDevice.id]);

    //  Send notification to device to send channels
    useEffect(() => {
        if (selectedDevice.id != "")
            fetchChannels();
    }, [selectedDevice.id]);

    useEffect(() => {
        if (assign) {
            handleSubmit(onSubmit)()
            setAssign(false)
        }
    }, [assign]);

    useEffect(() => {
        if (channelsList.length != 0) {
            axiosInstance.post(`/device/channels/${ selectedDevice.id }`, { "channels": channelsList })
        }
    }, [channelsList]);

    useEffect(() => {
        if (counter === 0) {
            setTimeout(function () {
                setOnlineDevices(prev => ({ ...prev, [selectedDevice.serial_name]: 0 }))
                setSelectedDevice(prev => ({ ...prev, online: 0 }))
            }, 1000);
        }

        if (!counter) return;

        var intervalId = setInterval(() => {
            setCounter(counter - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [counter]);


    useEffect(() => {
        if (onlineDevices[selectedDevice.serial_name]) {
            setCounter(13)
        }
    }, [onlineDevices[selectedDevice.serial_name]])

    return (
        <>
            {
                loading ?
                    <ProgressSpinner
                        style={ { width: "60px", height: "60px" } }
                        strokeWidth="3"
                        fill="var(--surface-ground)"
                        animationDuration="1s" />
                    :
                    devicesList.length == 0 ?
                        <h5 className="py-3 text-center w-50">There are no devices support satellite!</h5>
                        :
                        showDevices ?
                            <div className="d-flex flex-column scroll_container scroll_div px-3 w-100">
                                <form className="d-flex flex-column align-items-center">
                                    {/* Devices input */ }
                                    <div className={ `col-8 my-5 px-4` }>
                                        <div className="field">
                                            <label className="form-label" htmlFor="basic-default-company">  Device </label>
                                            <span className="p-float-label">
                                                <Controller name="device" control={ control }
                                                    rules={ { required: 'Device is required.' } }
                                                    render={ ({ field, fieldState }) =>
                                                    (
                                                        <Dropdown id={ field.name } { ...field }
                                                            onChange={ (e) => {
                                                                field.onChange(e.value); deviceHandler(e.value)
                                                            } }
                                                            options={ devicesList }
                                                            value={ selectedDevice.id }
                                                            optionLabel="name"
                                                            optionValue="id"
                                                            inputRef={ field.ref }
                                                            className={ `w-100  ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                                            placeholder="Select Device"
                                                        />
                                                    ) }
                                                />
                                                { getFormErrorMessage('device', errors) }
                                            </span>
                                        </div>
                                    </div>

                                    {/* Channels input */ }
                                    <div className={ `col-8 mb-5 px-4` }>
                                        {
                                            selectedDevice.online != 0 &&
                                            <div className="field">
                                                <label className="form-label" htmlFor="basic-default-company">Channels </label>
                                                <span className="p-float-label">
                                                    <Controller name="channels" control={ control }
                                                        rules={ { required: 'Channel is required.' } }
                                                        render={ ({ field, fieldState }) => {
                                                            return (
                                                                <Dropdown id={ field.name } { ...field }
                                                                    onChange={ (e) => {
                                                                        field.onChange(e.value);
                                                                        setSelectedChannel(e.value)
                                                                    } }
                                                                    options={ channelsList }
                                                                    optionLabel="serviceName"
                                                                    optionValue="chanNub"
                                                                    className={ `w-100  ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                                                    placeholder="Select Device"
                                                                    inputRef={ field.ref }
                                                                    filter
                                                                    filterBy="serviceName"
                                                                />
                                                            )
                                                        } } />
                                                    { getFormErrorMessage('channels', errors) }
                                                </span>
                                            </div>
                                        }
                                        {
                                            onlineDevices[selectedDevice.serial_name] == 0 &&
                                            <Message
                                                severity="error"
                                                className='text-center'
                                                text={ `The selected device 
                                                      "${ selectedDevice.name }"
                                             is offline, please turn the device on! ` }
                                            />
                                        }
                                    </div>
                                </form>

                                {
                                    loading &&
                                    <ProgressSpinner
                                        style={ { width: '60px', height: '60px', opacity: .1 } }
                                        fill="var(--surface-ground)"
                                        animationDuration="1s"
                                        strokeWidth="3"
                                    />
                                }
                            </div>
                            :
                            <h3 className="py-3 text-center w-50">This campaign has been assigned to devices don't support satellite. And assign channel to it will cause problem!</h3>
            }

            <FirebaseChannelsListener
                setChannelsList={ setChannelsList }
                path={ "Devices/" + selectedDevice.serial_name + "/DeviceSource" } />


            <DeviceLastLoginListener
                path={ "Devices/" + selectedDevice.serial_name + "/lastLogin" }
                serialName={ selectedDevice.serial_name } />

        </>
    );
}

export default ChannelsList