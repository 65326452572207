import React from 'react'
import { isEmpty } from 'lodash';
import { useGlobalContext } from '../../../Contexts/GlobalContext';

function BreadCrumb ( ) {
    const { selectedApp, setSelectedApp } = useGlobalContext();

    return (
        <div className='col-12 p-3 d-flex border-bottom'>
            <span className= {`d-flex align-items-center ${isEmpty(selectedApp) ? "fw-bold" : "text-light "}  cursor-pointer`}  onClick={ () => setSelectedApp({}) } >
                Apps
             </span>
            {
                !isEmpty(selectedApp) ?
                    <span className='d-flex align-items-center ms-2 fw-bold ' >
                        <i className="fas fa-angle-right me-2"></i>
                        { selectedApp?.title }
                    </span>
                    :
                    <></>
            }
        </div>
    )
}

export default BreadCrumb