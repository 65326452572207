import React, { useRef, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';

import { ProgressSpinner } from 'primereact/progressspinner';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';

import { getFormErrorMessage } from '../../../../config/global';
import { useGlobalContext } from '../../../Contexts/GlobalContext';
import { useCustomToast } from '../../../Contexts/ToastContext';
import axiosInstance from '../../../../config/Axios';

function MediaTitle ({ item }) {

  const { control, formState: { errors }, reset, handleSubmit } = useForm({ title: item.title });
  const { subLoaders, loadingHandler } = useGlobalContext();
  const { showToast } = useCustomToast();

  const previousTitle = useRef(item.title);

  const [editName, setEditName] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState("");

  const updateNameHandler = (data) => {
    setEditName(false)

    // Update name if it was changed
    if (previousTitle.current != data.title) {
      loadingHandler("titleLoader")

      axiosInstance.post(`/media/interactive/${ item.media_id }/update`, data
      ).then((result) => {
        setSelectedMedia("")
        previousTitle.current = data.title
        item.title = data.title
        loadingHandler("titleLoader")
        showToast('success', 'Media  Editing', "Media title updated successfully!");
      })
    }
  }

  // Set default value for title input
  useEffect(() => { reset({ title: item.title }) }, [reset])

  return <>
    {
      editName ?
        <form onSubmit={ handleSubmit(updateNameHandler) } className="d-flex align-items-center">
          <div className={ `field  ${ errors.name ? "mx-2 mt-2" : "m-2" }` }>
            <span className="p-float-label">
              <Controller name="title" control={ control }
                rules={ { required: 'Title is required!' } }
                render={ ({ field, fieldState }) => {
                  return (
                    <InputText
                      id={ field.name } { ...field } autoFocus defaultValue={ item.title }
                      className={ `w-100 p-1 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
                  )
                } } />
            </span>

            { getFormErrorMessage("title", errors) }
          </div>

          {/* Save changes */ }
          <Tooltip target={ `.save-button-${ item.id }` } showDelay={ 100 } className="fs-8" />
          <button className={ `btn btn-sm btn-icon save-button-${ item.id }` } data-pr-position="bottom" type='submit' data-pr-tooltip="Update" >
            <i className="far fa-check-circle  text-light fs-6" ></i>
          </button>
        </form>
        :
        subLoaders.titleLoader && selectedMedia == item.id ?
          <div className='col-4 col-md-3 text-truncate ps-2 text-center'>
            <ProgressSpinner style={ { width: '30px', height: '30px' } } strokeWidth="3" fill="var(--surface-ground)" animationDuration="2s" />
          </div>
          :
          <>
            <Tooltip target={ `.title-button-${ item.id }` } content="Edit Title" position='bottom'  className="fs-8" />

            <div className={ `col-4 col-md-3 text-truncate ps-2 cursor-pointer title-button-${ item.id }` }
              onClick={ () => { setEditName(true); setSelectedMedia(item.id) } }>
              { item.title == "" ? item.file_name : item.title }
            </div>
          </> }
  </>
}

export default MediaTitle