import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';


function BreadCrumb ({ getData, breadCrumb, setBreadCrumb, setCurrentFolder }) {

    const [folderName, setFolderName] = useState();

    const { id } = useParams();
    const navigate = useNavigate();

    const goHome = () => {
        if (window.location.pathname.includes("/media/gallery"))
            navigate(`/media/gallery`)
        else
            getData()

        localStorage.setItem("bread_crumb", JSON.stringify([]));
        localStorage.setItem("folder_id", "");
        setCurrentFolder({
            id: "",
            name: ""
        });
        setBreadCrumb([]);
    }

    const getFolderContent = (folder_id) => {
        if (window.location.pathname.includes("/media/gallery"))
            navigate(`/media/gallery/${ folder_id }`)
        else
            getData(folder_id)

        breadCrumb.map((currentItem, index) => {
            if (currentItem.id == folder_id) {
                localStorage.setItem("bread_crumb", JSON.stringify(breadCrumb.slice(0, index + 1)));
                localStorage.setItem("folder_id", currentItem.id);
                localStorage.setItem("current_folder", currentItem.name);
                setBreadCrumb(breadCrumb.slice(0, index + 1));
                setFolderName(currentItem.name);
            }
        });

        setCurrentFolder({
            id: folder_id,
            name: folderName
        });
    }

    return (
        <div className='px-3 d-flex'>
            <span className='d-flex align-items-center text-light' >
                <i className="fas fa-home me-1 cursor-pointer" onClick={ goHome }></i>
                <i className="fas fa-angle-right me-2"></i>
            </span>

            { breadCrumb.map((item, index) => {
                return (
                    <span className='d-flex align-items-center text-light' key={ item.id }>
                        {
                            breadCrumb.length - 1 != index ?
                                <>
                                    <span className='me-1 cursor-pointer' onClick={ () => { getFolderContent(item.id) } }>{ item.label }</span>
                                    <i className="fas fa-angle-right me-2"></i>
                                </>
                                :
                                <>
                                    <span className='me-1 fw-bold text-secondary'>{ item.label }</span>
                                </>
                        }
                    </span>
                )
            }) }
        </div>
    )
}

export default BreadCrumb