import React from 'react';

import CompanyProfile from './Account/CompanyProfile';
import { useTitle } from '../../../config/global';
import UserProfile from './Account/UserProfile';

const Account = () => {
    useTitle("Profile");

    return (
        <div className="d-flex">
            <div className="col-12 pe-2">
                <UserProfile />
            </div>
            {/* <div className="col-6 ps-2">
                <CompanyProfile />
            </div> */}
        </div>

    );
}

export default Account;