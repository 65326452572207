import React, { useState, useEffect, useRef } from 'react';


import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { useDataTableContext } from '../../../Contexts/DataTableContext';
import { useGlobalContext } from '../../../Contexts/GlobalContext';
import InvoicePreview from './InvoicePreview';
import moment from 'moment';

function SubscriptionsDatatable () {
    const { totalRecords, loading, lazyParams, setLazyParams, data, onPage } = useDataTableContext();
    const { openDialog, dialogHandler, subscriptionInfo, companyDetails } = useGlobalContext();

    const [invoiceInfo, setInvoiceInfo] = useState({});

    useEffect(() => {
        setLazyParams(prev => ({
            ...prev,
            rows: 10,
            modelName: "subscriptions",
            filters: {}
        }))
    }, [])

    const periodBodyTemplate = (rowData) => {
        let startDate = moment(rowData?.start_date);
        let endDate   = moment(rowData?.end_date);
        let period    = endDate.diff(startDate, 'days');

        return (
            <div className=''>
                <span className="text-center text-capitalize" >
                    { `${period}   Day${period > 1 ? "s" : ""}` }
                </span>
            </div>
        );
    }

    // Table body columns templates 
    const statusBodyTemplate = (rowData) => {
        return (
            <span className={ `text-center badge ${ rowData.status == "INACTIVE" ? "bg-label-danger" : "bg-label-success" } ` }>
                { rowData.status.toLowerCase() }
            </span>
        );
    }

    const amountBodyTemplate = (rowData) => {
        return rowData.paid_price == 0 ? "$ Free" : "$ " + rowData.paid_price
    }

    const receiptBodyTemplate = (rowData) => {
        return <>
            {
                !rowData.is_free ?
                    <i className="fas fa-eye text-light cursor-pointer" onClick={ () => { open(rowData) } }></i>
                    :
                    <></>
            }
        </>
    }

    const open = (data) => {
        setInvoiceInfo(data)
        dialogHandler("invoicePreview")
    }

    return (
        <>
            <div className='table-responsive text-nowrap stream_datatable position-relative'>
                { subscriptionInfo.isFinished &&
                    <div className='position-absolute w-100 h-100 top-0 left-0 bg-white-opacity zindex-1'></div>
                }

                <DataTable value={ data?.subscriptions } lazy responsiveLayout="scroll" dataKey="id"
                    paginator first={ lazyParams.first } rows={ lazyParams.rows }
                    totalRecords={ totalRecords } onPage={ onPage }
                    loading={ loading } className="table text-light"
                >
                    <Column field="title" header="Plan Name" className='text-center fs-8' />
                    <Column field="paid_price" header="Amount" className='text-center fs-8' body={ amountBodyTemplate } />
                    <Column field="period" header="period" className='text-center fs-8' body={ periodBodyTemplate } />
                    <Column field="number_of_devices" header="no. of devices" className='text-center fs-8' />
                    <Column field="status" header="status" className='text-center fs-8' body={ statusBodyTemplate } />
                    <Column field="start_date" header="Start Date" className='text-center fs-8' />
                    <Column field="end_date" header="End Date" className='text-center fs-8' />
                    <Column field="" header="Receipt" className='text-center fs-8' body={ receiptBodyTemplate } />
                </DataTable>
            </div>

            { openDialog.invoicePreview && <InvoicePreview invoiceInfo={ invoiceInfo } setInvoiceInfo={ setInvoiceInfo } /> }
        </>
    )
}

export default SubscriptionsDatatable