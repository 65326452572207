import React, { useRef } from 'react'
import { Controller } from "react-hook-form";
import Marquee from "react-fast-marquee";
import isEmpty from 'lodash/isEmpty';

import { SelectButton } from 'primereact/selectbutton';
import { FileUpload } from 'primereact/fileupload';
import { classNames } from 'primereact/utils';

import { directionOptions, speedOptions } from "../../../../config/constants"
import { useAppsContext } from '../../../Contexts/AppsContext';

const Bar = ({ feeds }) => {
  const { rssSettings } = useAppsContext();

  return (
    <Marquee
      direction={ rssSettings?.direction }
      speed={ `${ 50 * rssSettings?.speed }` }
      style={ {
        backgroundColor: `${ rssSettings?.bgColor }`,
        color: `${ rssSettings?.textColor } !important`,
      } }
    >
      {
        feeds.map((item, index) => {
          return (
            <div className='d-flex align-items-center py-1' key={ index }>
              <h4 className='mb-0 ' style={ { color: `${ rssSettings?.textColor }` } }>{ item?.title }</h4>
              <img src={ isEmpty(rssSettings?.newSeparator) ? rssSettings?.separator : URL.createObjectURL(rssSettings?.newSeparator) }
                style={ { width: "40px" } }
                className="w-px-40 mx-5 rounded-circle"
              />
            </div>
          )
        })
      }
    </Marquee >
  )
}

const BottomOption = ({ control, classes = {} }) => {
  const { rssSettingsHandler, rssSettings } = useAppsContext();
  const fileUploadRef = useRef(null);

  const chooseOptions = {
    className: 'upload_separator',
    label: 'click to upload image...',
    style: {
      background: 'transparent',
      color: 'gray',
      width: "100%",
      border: "2px dashed #D9DEE3",
      fontWeight: "normal",
      fontSize: classes?.separator && "13px",
      padding: classes?.separator && "5px",

    }
  };

  const onFileSelect = (event) => {
    const file = event.files && event.files[0];

    if (file) {
      rssSettingsHandler("newSeparator", file)
    }
  };

  const onCancelUpload = () => {
    rssSettingsHandler("newSeparator", {})
    fileUploadRef.current.clear();
  };

  return (
    <>
      <div className={ `col-6 p-1 ` }>
        <label htmlFor="direction" className={ `${ classes?.mb ?? "mb-2" } form-label text-capitalize ` } >Direction</label>
        <Controller name="direction" control={ control } className=""
          rules={ { required: false } }
          render={ ({ field, fieldState }) => (
            <SelectButton
              id={ field.name } { ...field }
              value={ rssSettings?.direction }
              className={ `custom_select d-flex ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
              inputRef={ field.ref }
              onChange={ (e) => { field.onChange(e.value); rssSettingsHandler("direction", e.value) } }
              options={ directionOptions }
              // itemTemplate={ directionTemplate }
              optionLabel="icon" />
          ) } />
      </div>

      <div className={ `col-6  p-1 mb-2` } >
        <label htmlFor="speed" className={ `${ classes?.mb ?? "mb-2" } form-label text-capitalize ` } >Speed</label>
        <Controller name="speed" control={ control }
          rules={ { required: false } }
          render={ ({ field, fieldState }) => (
            <SelectButton
              id={ field.name } { ...field }
              value={ rssSettings?.speed }
              onChange={ (e) => { field.onChange(e.value); rssSettingsHandler("speed", e.value) } }

              className={ `custom_select d-flex ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
              inputRef={ field.ref }
              options={ speedOptions }
              optionLabel="label"
            />
          ) } />
      </div>

      {/* Separator Input */ }
      <div className={ `col-12 ${ classes?.mb ?? "mb-3" } d-flex flex-column` } >
        <label htmlFor="separator" className={ `${ classes?.mb ?? "mb-2" } form-label text-capitalize ` } >Separator</label>
        <Controller name="separator" control={ control }
          rules={ { required: false } }
          render={ ({ field, fieldState }) => (
            <FileUpload
              mode="basic"
              name="separator"
              accept="image/*"
              maxFileSize={ 1000000 }
              customUpload
              onSelect={ onFileSelect }
              ref={ fileUploadRef }
              onClick={ onCancelUpload }
              chooseOptions={ chooseOptions }
            />
          ) } />

      </div>
    </>
  )
}

const BreakingNews = ({ feeds }) => {
  const { rssSettings } = useAppsContext();

  return (
    <Marquee
      direction={ rssSettings?.direction }
      speed={ `${ 50 * rssSettings?.speed }` }
      style={ {
        backgroundColor: `${ rssSettings?.bgColor }`,
        color: `${ rssSettings?.textColor } !important`,
      } }
    >
      {
        feeds.map((item, index) => {
          return (
            <div className='d-flex align-items-center py-1' key={ index }>
              <h4 className='mb-0 ' style={ { color: `${ rssSettings?.textColor }` } }>{ item?.title }</h4>
              <img src={ isEmpty(rssSettings?.newSeparator) ? rssSettings?.separator : URL.createObjectURL(rssSettings?.newSeparator) }
                style={ { width: "40px" } }
                className="w-px-40 mx-5 rounded-circle"
              />
            </div>
          )
        })
      }
    </Marquee >
  )
}

export {
  Bar,
  BottomOption,
  BreakingNews
}