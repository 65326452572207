import React, { useEffect } from 'react'

import { db } from "../../config/firebase";
import { onValue, ref } from "firebase/database";
import { useDeviceContext } from '../Contexts/DeviceContext';

function FirebaseChannelsListener ({ setChannelsList, path }) {
    const { selectedDevice } = useDeviceContext();

    useEffect(() => {

        const query = ref(db, process.env.REACT_APP_APP_ENV + "/" + path);
        return onValue(query, (snapshot) => {
            const data = snapshot.val();

            if (snapshot.exists()) {
                 setChannelsList(data.Channels);
            }
        });

    }, [selectedDevice.id]);

    return (
        <></>
    )
}

export default FirebaseChannelsListener