import React, { useEffect, useState } from 'react'

import { useDataTableContext } from '../../Contexts/DataTableContext';
import { useGlobalContext } from '../../Contexts/GlobalContext';
import { useCustomToast } from '../../Contexts/ToastContext';
import { useAppsContext } from '../../Contexts/AppsContext';
import axiosInstance from '../../../config/Axios';

import icon from '../../../assets/img/3d_primary.png'
import Layout from '../../Layout/Layout'
import Add3DMedia from './Add3DMedia';
import { useTitle } from '../../../config/global';

import { FiEdit2, FiTrash2, FiPlusCircle } from "react-icons/fi";
import CreateEditCollection from './CreateEditCollection';
import { Image, Tooltip } from 'react-bootstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

function Multi3DViewerIndex () {
  useTitle('Multi-3d Viewer');

  const { totalRecords, setReload, loading, lazyParams, setLazyParams, data, onPage, onSort, onFilter } = useDataTableContext();
  const {   dialogHandler, openDialog } = useGlobalContext();
  const { setIsCreation, isLoading } = useAppsContext();
  const { showToast } = useCustomToast();

  const [selectedCollection, setSelectedCollection] = useState({});

  useEffect(() => {
    setLazyParams(prev => ({
      ...prev,
      modelName: "media",
      filters: {
        'file_name': { value: '', matchMode: 'contains' },
        'type': { value: 'MULTI_3D', matchMode: 'equals' },
      }
    }))
  }, [])

  // Data Table Body Template
  const actionBodyTemplate = (rowData) => {
    return (
      <div className="d-inline-block text-nowrap">
        {/* Add Content RSS */ }
        <Tooltip target={ `.add-button-${ rowData.id }` } showDelay={ 100 } className="fs-8" />
        <button className={ `btn btn-sm btn-icon delete-record me-2 add-button-${ rowData.id }` }
          data-pr-position="bottom"
          data-pr-tooltip="Add Content"
          onClick={ () => addContent(rowData) } >
          <FiPlusCircle className="fs-5 fw-bold " />
        </button>

        {/* Update RSS */ }
        {/* <Tooltip target={ `.update-button-${ rowData.id }` } showDelay={ 100 } className="fs-8" /> */ }
        <button className={ `btn btn-sm btn-icon  me-2  update-button-${ rowData.id }` } data-pr-position="bottom" data-pr-tooltip="
              Update" onClick={ () => editRss(rowData) }>
          <FiEdit2 className="fs-5 fw-bold " />
        </button>

        {/* Delete RSS */ }
        {/* <Tooltip target={ `.delete-button-${ rowData.id }` } showDelay={ 100 } className="fs-8" /> */ }
        <button className={ `btn btn-sm btn-icon delete-record me-2  delete-button-${ rowData.id }` } data-pr-position="bottom" data-pr-tooltip="Delete" onClick={ () => confirmDeleteUser(rowData) } >
          <FiTrash2 className="fs-5 fw-bold " />
        </button>
      </div >
    );
  }

  const confirmDeleteUser = (data) => {
    axiosInstance.delete(`/media/${ data.id }`)
      .then((result) => {
        setReload(true)
        showToast('success', 'Delete Media', result?.data?.data?.msg);
      })
      .catch((error) => {
        showToast('error', 'Delete Media', error?.response?.data?.msg);
      });
  }

  const editRss = (rowData) => {
    setSelectedCollection(rowData)
    setIsCreation(false)
    dialogHandler("createEditCollection")
  }

  const addContent = (rowData) => {
    setSelectedCollection(rowData)
    dialogHandler("addCollectionContent")
  }

  return (
    <Layout>
      <div className="card">
        <div className="card-header d-flex align-items-center justify-content-between">
          <h5 className="card-title m-0 me-2 d-flex align-items-center">
            <div className="avatar-initial rounded bg-label-primary p-2 me-2">
              { <Image src={ icon } width="30" /> }
            </div>
            Multi-3d Viewer</h5>
          <div className=' d-flex align-items-center  mb-4'>
            <button className="btn btn-primary mx-1" disabled={ isLoading ? true : false } onClick={ () => window.history.go(-1) } > Back</button>
            <button className="btn btn-primary mx-1 d-flex align-items-center"
              disabled={ isLoading ? true : false }
              onClick={ () => dialogHandler("createEditCollection") } >
              <i class="fa-solid fa-plus me-1 fw-lighter fs-5 "></i>
              Add New 3D Media Collection
            </button>
          </div>
        </div>


        <div className='table-responsive text-nowrap'>
          <DataTable value={ data.media } lazy filterDisplay="row" responsiveLayout="scroll" dataKey="id"
            paginator first={ lazyParams.first } rows={ lazyParams.rows } rowsPerPageOptions={ [15, 25, 50, 100] }
            totalRecords={ totalRecords } onPage={ onPage }
            onSort={ onSort } sortField={ lazyParams.sortField } sortOrder={ lazyParams.sortOrder }
            onFilter={ onFilter } filters={ lazyParams.filters } loading={ loading } className="table"
          >
            <Column field="file_name" header="Name" className='text-center' sortable filter filterPlaceholder="Search by Name" />
            {/* <Column field="title" header="title" className='text-center' sortable filter filterPlaceholder="Search by Name" /> */ }
            <Column field="created_at" header="Created Date" className='text-center' sortable />
            <Column body={ actionBodyTemplate } exportable={ false } ></Column>
          </DataTable>
        </div>
      </div>
      {
        openDialog?.createEditCollection ? <CreateEditCollection selectedCollection={ selectedCollection } /> : <></>
      }
      {
        openDialog?.addCollectionContent ? <Add3DMedia selectedCollection={ selectedCollection} /> : <></>
      }

    </Layout >
  )
}

export default Multi3DViewerIndex