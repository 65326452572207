import React, { useState, useEffect } from 'react'

import { Dialog } from 'primereact/dialog'

import { useGlobalContext } from '../../../Contexts/GlobalContext'
import axiosInstance from '../../../../config/Axios'
import { Spinner } from 'react-bootstrap'
import { replace } from 'lodash'

function StreamPreview ({ streamInfo }) {
    const { dialogHandler, openDialog } = useGlobalContext()

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axiosInstance.get(`/streams/${ streamInfo.id }/devices`)
            .then((result => {
                setData(result?.data?.data)
                setLoading(false)
            }))
    }, [])

    function capitalizeFirstLetter (sentence) {
        return sentence.replace(/\b\w/g, function (match) {
            return match.toUpperCase();
        });
    }

    return (
        <Dialog visible={ openDialog.streamPreview }
            style={ { width: '30%' } }
            breakpoints={ { '960px': '95vw' } }
            header={ capitalizeFirstLetter(streamInfo?.name) }
            modal className="p-fluid"
            onHide={ () => dialogHandler("streamPreview") }
        >
            <div className="d-flex py-3 px-5 flex-wrap mb-3 justify-content-center ">
                {
                    data.length == 0 ?
                        <Spinner variant="primary" as="span" animation="border" size="lg" role="status" className='m-3' aria-hidden="true" />
                        :
                        <>
                            <div className="col-12 d-flex my-2">
                                <div className='col-4 fw-bolder'>Type: </div>
                                <div className="col-8 d-flex flex-wrap text-capitalize">
                                    {
                                        streamInfo?.type.replace(/_/g, ' ').toLowerCase()
                                    }
                                </div>
                            </div>
                            <div className="col-12 d-flex my-2">
                                <div className='col-4 fw-bolder'>Status: </div>
                                <div className={ ` col-8 d-flex flex-wrap text-capitalize 
                                ${ streamInfo?.status == "FINISHED" ? "text-danger" : streamInfo?.status == "PUBLISHED" ? "text-warning" : "text-success" }` } >
                                    {
                                        streamInfo?.status.toLowerCase()
                                    }
                                </div>
                            </div>
                            <div className="col-12 d-flex my-2">
                                <div className='col-4 fw-bolder'>Creation Date: </div>
                                <div className={ `col-8 d-flex flex-wrap text-capitalize` } >
                                    {
                                        streamInfo?.created_at
                                    }
                                </div>
                            </div>
                            <div className="col-12 d-flex my-2">
                                <div className='col-4 fw-bolder'>Transmitter: </div>
                                <div className="col-8 d-flex flex-wrap">
                                    <span className="badge bg-label-primary p-2 rounded">
                                        {
                                            data?.senders?.map(device => {
                                                return device?.name
                                            })
                                        }
                                    </span>
                                </div>
                            </div>
                            <div className="col-12 d-flex my-2">
                                <span className='col-4 fw-bolder '>Receivers: </span>
                                <div className="col-8 d-flex flex-wrap">
                                    <span className="badge bg-label-primary p-2 rounded">
                                        {
                                            data?.receivers?.map(device => {
                                                return device?.name
                                            })
                                        }
                                    </span>
                                </div>
                            </div>
                        </>
                }
            </div>
        </Dialog>
    )
}

export default StreamPreview