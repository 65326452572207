import axiosInstance from "./Axios";
 
const joinedDataTable = ["campaign", "subscriptions"]

export class NetworkManager {
    datatable (table, params) {
        let ordered_col = params.sortField ? params.sortField : 'id';
        let ordered_type = params.sortOrder == 1 ? "desc" : 'asc';

        const pushed = {
            "order_by": {
                [ordered_col]: ordered_type
            },
            "filters": params.filters,
            "filters_date": params.filters_date
        };

        if (joinedDataTable.indexOf(table) !== -1) {
            pushed.isInteractive = window.location.pathname.includes("interactive") ? 1 : 0;
            return axiosInstance.post(`/datatable/` + table + `?page=` + (params.page + 1) + `&per_page=` + params.rows, pushed).then(res => res.data)
        } else
            return axiosInstance.post(`/datatable/` + table + `/view?page=` + (params.page + 1) + `&per_page=` + params.rows, pushed).then(res => res.data)
    }

    deleteRow (table, params) {
        axiosInstance.delete(`/datatable/` + table + `/` + params.id + `/delete`);
    }


}
