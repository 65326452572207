import React, { useState, useRef, useEffect } from "react";
 
import { ProgressSpinner } from "primereact/progressspinner";
import { InputNumber } from "primereact/inputnumber";
import { Tooltip } from "primereact/tooltip";
import { Card } from "primereact/card";

import { formatSize, formatDuration } from "../../../config/global";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import { useCustomToast } from "../../Contexts/ToastContext";
import MediaSettingPopup from "./MediaSettingPopup";
import axiosInstance from "../../../config/Axios";
import LazyImage from "../ShowMedia/LazyImage";

function PlaylistCard ({ layer, item }) {
  const { showToast } = useCustomToast();
  const { setRerenderList } = useGlobalContext();

  // Duration Controls
  const [durationLoading, setDurationLoading] = useState(false);
  const [duration, setDuration] = useState(item.total_duration);
  const [editDuration, setEditDuration] = useState(false);
  const [send, setSend] = useState(false);

  //Dialog  handlers
  const [mediaSettingPopup, setMediaSettingPopup] = useState(false);

  const previousValue = useRef(duration);
 
  // Delete handler
  const removeFromPlaylist = () => {
    axiosInstance.delete(`/playlist/${ item.playlist_id }/media/${ item.playlist_media_id }/delete`)
      .then((result) => {
        setRerenderList(true);
      })
      .catch((error) => {
        showToast('error', "Delete Playlist Media", error?.response?.data?.message);
      });
  };

  // Update playlist-media setting
  useEffect(() => {
    if (send && previousValue.current != duration) {
      setDurationLoading(true);
      axiosInstance.put(`/playlist/media/setting`,
          {
            playlist_media_id: item.playlist_media_id,
            duration: duration,
          }
        )
        .then((result) => {
          setRerenderList(true);
          showToast('success', "PlayList Setting", result?.data?.data?.msg);
          setDurationLoading(false);
        })
        .catch((error) => {
          if (error?.response?.data?.errors)
          Object.values(error?.response?.data?.errors).forEach((error) => {
            showToast('error', "PlayList Setting", error[0]);
          });
        });
    }

    setSend(false);
  }, [send]);

  // Update duration on enter key press
  const sendOnkeypress = () => {
    setEditDuration(false);
    setSend(true);
  };

  //retrieve duration default value
  const retrieveDefaultValue = () => {
    setDurationLoading(true);

    axiosInstance.put(`/playlist/media/${ item.playlist_media_id }/duration`, {})
      .then((result) => {
        setRerenderList(true);
        setEditDuration(false);
        setDurationLoading(false);
        showToast('success', "Retrieve Media duration ", result?.data?.data?.msg);
      })
      .catch((error) => {
        showToast('error', "Retrieve Media duration ", error?.response?.data?.message);
      });
  };
  return (
    <>
      <div className="d-flex align-items-center" id="media_setting_container">
        <Card className=" w-100 d-flex row playlist_card">
          <div className="d-flex align-items-center  flex-nowrap justify-content-between">

            {
              item.type == "HDMI" || item.type == "CHANNEL" ?
                <div className='col-2 col-md-1 me-2 p-4  bg-secondary d-flex align-items-center justify-content-center'>
                  <span className=" fs-6 text-white">{ item.type == "HDMI" ? "HDMI" : "Channel" }</span>
                </div>
                :
                <div className="col-2 col-md-1 me-2  d-flex align-items-center justify-content-center">
                  <LazyImage media={ item } setMediaSettingPopup={ setMediaSettingPopup } popupType={ "playlist_media_setting" } index={ item.id } styleClass={ "me-2 image_container" } />
                </div>
            }

            <div className="col-5 col-md-2 text-truncate">{ item.file_name }</div>
            <div className="col-2 d-none d-md-block">
              { editDuration ? (
                <div className=" d-flex align-items-center">
                  <InputNumber
                    inputId="minmax"
                    mode="decimal"
                    value={ item.custom_duration }
                    onValueChange={ (e) => setDuration(e.value) }
                    onChange={ (e) => setDuration(e.value) }
                    onKeyDown={ (e) =>
                      e.key === "Enter" ? sendOnkeypress() : ""
                    }
                    min={ 0 }
                    className="duration_input"
                  />

                  <div className="d-flex flex-column">
                    <i className="col-1 mx-2 fas fa-check-circle mx-1 text-lighter mb-2"
                      onClick={ () => {
                        setEditDuration(false);
                        setSend(true);
                      } }
                    ></i>

                    <Tooltip target={ `.retrieve_duration-${ item.id }` } content="Retrieve duration" position="bottom" className="fs-8" />
                    <i className={ `col-1 mx-2 fas fa-redo mx-1 text-lighter cursor-pointer retrieve_duration-${ item.id }` }
                      onClick={ retrieveDefaultValue }  >  </i>
                  </div>
                </div>
              ) : (
                <>
                  { durationLoading ? (
                    <ProgressSpinner
                      style={ { width: "30px", height: "30px" } }
                      strokeWidth="3"
                      fill="var(--surface-ground)"
                      animationDuration="2s"
                    />
                  ) : (
                    <>
                      <Tooltip target={ `.edit_duration-${ item.id }` } content="Edit duration" position="bottom" className="fs-8" />
                      <div
                        className={ `cursor-pointer edit_duration-${ item.id }` }
                        onClick={ () => setEditDuration(true) }
                      >

                        { formatDuration(item.custom_duration) }
                      </div>
                    </>
                  ) }
                </>
              ) }
            </div>

            <div className="col-1 d-flex align-items-center flex-nowrap fs-6 d-none d-md-block">
              { item.type }
            </div>

            <div className="col-1 d-flex align-items-center flex-nowrap fs-6 d-none d-md-block">
              { formatSize(item.size) }
            </div>

            <div className="cpl-4 col-sm-3 col-md-1 d-flex align-items-center justify-content-end pe-1 pe-sm-3">
              {/* Update  */ }
              <Tooltip target={ `.update-button-${ item.id }` } content="Update" position="bottom" />
              <button className={ `btn bg-transparent p-0 mx-2 pt-1 update-button-${ item.id }` }>
                <i onClick={ () => setMediaSettingPopup(true) } className="fas fa-pencil text-light" ></i>
              </button>

              {/* Delete  */ }
              <Tooltip target={ `.delete-button-${ item.id }` } content="Delete" position="bottom" className="fs-8" />
              <button className={ `btn bg-transparent p-0 me-1 delete-button-${ item.id }` } onClick={ removeFromPlaylist } >
                <i className="bx bx-trash text-light"></i>
              </button>
              <i className="fas fa-bars text-light mx-2"></i>
            </div>
          </div>
        </Card>

        <MediaSettingPopup
          layer={ layer }
          removeMedia={ removeFromPlaylist }
          item={ item }
          mediaSettingPopup={ mediaSettingPopup }
          setMediaSettingPopup={ setMediaSettingPopup }
        />
      </div>
    </>
  );
}
export default PlaylistCard;
