import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';

import { ProgressSpinner } from 'primereact/progressspinner';

import { useCustomToast } from '../../Contexts/ToastContext';
import axiosInstance from '../../../config/Axios';
import { setProfile } from '../../../config/global';

function VerifyEmailProcess () {
    const [loading, setLoading] = useState(true);
    const { payload } = useParams();
    const { showToast } = useCustomToast();
    const navigate = useNavigate();

    useEffect(() => {
        axiosInstance.post(`/users/verify/email`, { "payload": payload })
            .then((result) => {
                setProfile(result?.data?.data)
                showToast('success', 'Success', result?.data?.msg);
                navigate("/dashboard")

            })
            .catch((error) => {
                navigate("/login")
                if (error?.response?.data?.errors)
                    Object.values(error?.response?.data?.errors).forEach((error) => {
                        showToast('error', 'Fail', error[0]);
                    });
            });

    }, [])

    return (
        <>
            {
                loading &&
                <div className='d-flex justify-content-center align-items-center'>
                    <ProgressSpinner style={ { width: '60px', height: '60px', opacity: .1 } } strokeWidth="3" fill="var(--surface-ground)" animationDuration="1s" />
                </div>
            }
        </>
    )
}

export default VerifyEmailProcess