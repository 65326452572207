import React, { useEffect } from 'react'

import { useScrollLoadingContext } from '../../Contexts/ScrollLoadingContext';
import { useGlobalContext } from '../../Contexts/GlobalContext';
import { useCustomToast } from '../../Contexts/ToastContext';
import axiosInstance from '../../../config/Axios';

import { Spinner } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import MediaList from './MediaList';

function Add3DMedia ({ selectedCollection }) {
    const { openDialog, dialogHandler, disableBtn, setDisableBtn } = useGlobalContext();
    const { showToast } = useCustomToast();
    const { ids, setIds } = useScrollLoadingContext();

    useEffect(() => {
        axiosInstance.get(`/media/${ selectedCollection?.id }/multi-3d`)
            .then(result => {
                let _ids = [];
                result?.data?.data?.map(element => {
                    _ids = [..._ids, element?.pivot?.related_media_id]
                });
                setIds(_ids)
            })
        return () => {
            setIds([])
        }
    }, [])

    const assignHandler = () => {
        setDisableBtn(true);

        axiosInstance.post(`/media/multi-3d/${ selectedCollection?.id }/assign`, {
            "media_ids": ids
        })
            .then((result) => {
                setDisableBtn(false)
                showToast('success', `Add Collection Content`, result?.data?.data?.msg);
                dialogHandler("addCollectionContent")
            }).catch((error) => {
                setDisableBtn(false);
                showToast('error', `Add Collection Content`, error?.response?.data?.message);
            });
    }

    return (
        <Dialog visible={ openDialog.addCollectionContent }
            style={ { width: '40%' } }
            breakpoints={ { '960px': '95vw' } }
            header={ `Add 3D Media` }
            modal className="p-fluid"
            onHide={ () => dialogHandler("addCollectionContent") }
        >
            <div className="d-flex flex-column">

                <div className="col-12 d-flex flex-wrap p-4 ">
                    <MediaList path={ "/media/3D" } />
                </div>

                <div className="col-12 text-center d-flex  align-items-end  justify-content-center py-4 border-top border-gray">
                    <button type="reset" className="btn btn-label-secondary me-3" disabled={ disableBtn ? true : false }
                        data-bs-dismiss="modal" aria-label="Close" onClick={ () => dialogHandler("addCollectionContent") }>
                        Cancel
                    </button>
                    <button type="submit"
                        className="btn btn-primary  me-1"
                        disabled={ disableBtn || ids?.length == 0 }
                        onClick={ () => assignHandler() } >
                        {
                            disableBtn ?
                                <Spinner variant="primary" as="span" animation="border" size="sm" role="status" className='mx-3' aria-hidden="true" />
                                :
                                "Add Media"
                        }
                    </button>
                </div>
            </div>
        </Dialog >
    )
}

export default Add3DMedia