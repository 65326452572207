import React from 'react'

import sec5Icon1 from "../../../assets/img/Landing/sec5-icon1.png";
import sec5Icon2 from "../../../assets/img/Landing/sec5-icon2.png";
import sec5Icon3 from "../../../assets/img/Landing/sec5-icon3.png";
import sec5Icon4 from "../../../assets/img/Landing/sec5-icon4.png";

function ContentManagement () {
    return (
        <div className="container sec5">
            <div className="row sec5-head">
                <div className="col-12">
                    <h2 className="mt-4">Content Management</h2>
                    <p className="lead mt-4">
                        Get your content out on your screens simultaneously & schedule
                        your campaigns easily
                    </p>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-md-3">
                    <div className="card mb-3 sec5-card">
                        <div className="row no-gutters container sec5-align">
                            <div className="col-md-3 sec5-card-image">
                                <img className="card-img" src={ sec5Icon1 } alt="Card image cap" />
                            </div>
                            <div className="col-md-9">
                                <div className="card-body sec5-card-content">
                                    <h5 className="card-title">Gallery</h5>
                                    <p className="card-text">
                                        Add, delete & filter media in your own media gallery
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card mb-3 sec5-card">
                        <div className="row no-gutters container sec5-align">
                            <div className="col-md-3 sec5-card-image">
                                <img className="card-img" src={ sec5Icon2 } alt="Card image cap" />
                            </div>
                            <div className="col-md-9">
                                <div className="card-body sec5-card-content">
                                    <h5 className="card-title">Playlists</h5>
                                    <p className="card-text">
                                        Group multiple media files in one playlist for an easy
                                        media usage
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card mb-3 sec5-card">
                        <div className="row no-gutters container sec5-align">
                            <div className="col-md-3 sec5-card-image">
                                <img className="card-img" src={ sec5Icon3 } alt="Card image cap" />
                            </div>
                            <div className="col-md-9">
                                <div className="card-body sec5-card-content">
                                    <h5 className="card-title">Campaigns</h5>
                                    <p className="card-text">
                                        Simply choose media playlists, dates & locations and
                                        publish your campaigns easily
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card mb-3 sec5-card">
                        <div className="row no-gutters container sec5-align">
                            <div className="col-md-3 sec5-card-image">
                                <img className="card-img" src={ sec5Icon4 } alt="Card image cap" />
                            </div>
                            <div className="col-md-9">
                                <div className="card-body sec5-card-content">
                                    <h5 className="card-title">Schedule</h5>
                                    <p className="card-text">
                                        Automate your campaigns and playlists daily, weekly or
                                        even monthly
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContentManagement