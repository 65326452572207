import React, { useContext, createContext, useEffect, useState, useRef } from "react";

import { NetworkManager } from "../../config/NetworkManager";

const DataTableContext = createContext({});

export const DataTableProvider = (props) => {
    const Service = new NetworkManager();

    let loadLazyTimeout = null;
    const didMountRef = useRef(null)

    const [isInteractiveCampaign, setIsInteractiveCampaign] = useState(0)
    const [reload, setReload] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [data, setData] = useState({
        playlist: [],
        campaign: [],

    });

    const [lazyParams, setLazyParams] = useState({
        modelName: "",
        first: 0,
        rows: 15,
        page: 0,
        sortField: null,
        sortOrder: 1,
        filters: {},
        is_interactive: isInteractiveCampaign
    });

    const onPage = (event) => {
        event['modelName'] = lazyParams.modelName;
        event['is_interactive'] = isInteractiveCampaign;
        if (lazyParams.modelName == "subscriptions")
            event['filters'] = {}
        setLazyParams(event);
    }

    const onSort = (event) => {
         event['modelName'] = lazyParams.modelName;
        event['is_interactive'] = isInteractiveCampaign;
        setLazyParams(event);
    }

    const onFilter = (event) => {
        event['first'] = 0;
        event['page'] = 0;
        event['modelName'] = lazyParams.modelName;
        event['is_interactive'] = isInteractiveCampaign;

        setLazyParams(event);
    }

    const loadLazyData = () => {
        let dataKey = lazyParams.modelName;
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }

        //imitate delay of a backend call
        loadLazyTimeout = setTimeout(() => {
            Service.datatable(lazyParams.modelName, lazyParams).then(data => {
                setTotalRecords(data.pagination.total);
                if (window.location.pathname.includes("interactive")) {
                    dataKey = "interactiveCampaign"
                }
                setData(prev => ({ ...prev, [dataKey]: data.data }));
                setLoading(false);
            });
        }, Math.random() * 1000 + 250);
    }

    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
        } else {
            loadLazyData();
        }
    }, [lazyParams])

    useEffect(() => {
        if (reload) {
            loadLazyData()
        }
        setReload(false)
    }, [reload])

    return (
        <DataTableContext.Provider value={ {
            isInteractiveCampaign, setIsInteractiveCampaign,

            totalRecords, setTotalRecords,
            lazyParams, setLazyParams,
            data, setData,
            loading, setLoading,
            setReload,

            onPage,
            onSort,
            onFilter
        } }>
            { props.children }
        </DataTableContext.Provider>
    )
}

export const useDataTableContext = () => {
    return useContext(DataTableContext);
}