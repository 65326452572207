
import { createContext, useContext, useEffect, useState } from "react";
import { defaultRssSetting } from '../../config/constants';
import { isEmpty } from "lodash";

const Apps = createContext({});

export const AppsProvider = (props) => {
    /*------------ Prayer States-------------- */
    //Dialog Controls
    const [createDialog, setCreateDialog] = useState(false);
    const [prayerEnableDialog, setPrayerEnableDialog] = useState(false);

    /*------------------- Data ------------------- */
    // Prayer
    const [nextPrayerIndex, setNextPrayerIndex] = useState(4);
    const [prayerTimes, setPrayerTimes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({
        "next": 0,
        "location": ""
    });

    // RSS
    const [selectedFeed, setSelectedFeed] = useState({});
    const [newSeparator, setNewSeparator] = useState("");
    const [rssSettings, setRssSettings] = useState(defaultRssSetting);
    const [selectedRss, setSelectedRss] = useState({});
    const [isCreation, setIsCreation] = useState(true);
    const [rssName, setRssName] = useState("Instance Name");

    const rssSettingsHandler = (key, value) => {
        setRssSettings(prev => ({ ...prev, [key]: value }))
    }

    const resetStates = () => {
        setSelectedRss({})
        setIsCreation(true)
        setRssSettings(defaultRssSetting)
        setRssName("Instance Name")
        setSelectedFeed({})
    }

    const formDataHandler = (formData, data) => {
        let rss_settings = {
            play_video_full_length: data.play_video_full_length ?? false,
            display_video_content: data.display_video_content ?? false,
            image_fullscreen: data.image_fullscreen ?? false,
            original_layout: data.original_layout ?? false,
            posts_per_page: data.posts_per_page ?? false,
            image_blending: data.image_blending ?? false,
            stretch_image: data.stretch_image ?? false,
            show_image: data.show_image ?? false,
            mute_video: data.mute_video ?? false,
            text_color: rssSettings?.textColor,
            direction: rssSettings?.direction == "left" ? "ltr" : "rtl",
            duration: rssSettings?.duration ?? 20,
            position: rssSettings?.position,
            bg_color: rssSettings?.bgColor,
            design: data.design ?? "original",
            speed: rssSettings?.speed,
        };

        if (!isEmpty(rssSettings?.newSeparator))
            formData.append("separator", rssSettings?.newSeparator);

        for (var key in rss_settings) {
            formData.append(`rss_settings[${ key }]`, rss_settings[key]);
        }
    }

    useEffect(() => {
        if (!isCreation) {
            setRssSettings(prev => ({
                ...prev,
                bgColor: selectedRss?.rss_settings?.bg_color,
                textColor: selectedRss?.rss_settings?.text_color,
                position: selectedRss?.rss_settings?.position,
                speed: selectedRss?.rss_settings?.speed,
                direction: (selectedRss?.rss_settings?.direction === "rtl" ? "right" : "left"),
                separator: selectedRss?.rss_settings?.separator,
                duration: selectedRss?.rss_settings?.duration ?? 20,
            }))
        }
    }, [isCreation])

    return (
        <Apps.Provider value={ {
            nextPrayerIndex, setNextPrayerIndex,
            prayerTimes, setPrayerTimes,
            isLoading, setIsLoading,
            data, setData,

            selectedFeed, setSelectedFeed,
            selectedRss, setSelectedRss,
            isCreation, setIsCreation,
            newSeparator, setNewSeparator,
            rssSettings, setRssSettings, rssSettingsHandler,
            formDataHandler,

            prayerEnableDialog, setPrayerEnableDialog,
            createDialog, setCreateDialog,
            rssName, setRssName,
            resetStates
        } }>
            { props.children }
        </Apps.Provider>
    );
};

export const useAppsContext = () => {
    return useContext(Apps);
};
