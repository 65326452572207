import React from 'react';
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from "react-hook-form";
import { getFormErrorMessage } from '../../../config/global';

import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Spinner } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';

import { useDataTableContext } from '../../Contexts/DataTableContext';
import { useGlobalContext } from '../../Contexts/GlobalContext';
import { useCustomToast } from '../../Contexts/ToastContext';
import axiosInstance from '../../../config/Axios';

const Create = () => {
    const { showToast } = useCustomToast();

    const { disableBtn, setDisableBtn, setIsPrayerCampaign, dialogHandler, openDialog, layer } = useGlobalContext();
    const { control, formState: { errors }, handleSubmit } = useForm();
    const { isInteractiveCampaign } = useDataTableContext();

    const navigate = useNavigate();

    const hideDialog = () => {
        dialogHandler("createCampaign");;
    }

    const onSubmit = (data) => {
        setDisableBtn(true)

        axiosInstance.post("/campaign/create",
            {
                "name": data.name,
                "is_interactive": isInteractiveCampaign
            }
        ).then((result) => {
            setDisableBtn(false)
            showToast('success', 'Campaign Creation', "Campaign created successfully!");
            hideDialog()
            setIsPrayerCampaign(false)
            sessionStorage.setItem('is_default_prayer', JSON.stringify(false));
            if (isInteractiveCampaign)
                navigate(`/interactive/campaign/${result.data?.data?.id}/setup`)
            else
                navigate(`/campaigns/${result.data?.data?.id}/setup` )
        }).catch((error) => {
            setDisableBtn(false);
            if (error?.response?.data?.errors)
                Object.values(error?.response?.data?.errors).forEach(error => {
                    showToast('error', 'Campaign Creation', error[0]);
                });
        });
    };

    const validateNotEqual = (value) => {
        return value.toLowerCase() !== 'default' ||`"default" is a reserved name!`
    };


    return (

        <Dialog visible={ openDialog.createCampaign } breakpoints={ { '960px': '95vw' } } style={ { width: '600px' } } header="Create Campaign" modal className="p-fluid" onHide={ hideDialog }>
            <form onSubmit={ handleSubmit(onSubmit) } className=" d-flex flex-column align-items-center">
                <div className={ `col-12 px-3 my-5  w-75` }>
                    <div className="field">
                        <label className="form-label">
                            Name
                        </label>
                        <span className="p-float-label">
                            <Controller name="name" control={ control }
                                rules={ { required: 'Name is required.', validate: validateNotEqual } }
                                render={ ({ field, fieldState }) => (
                                    <InputText id={ field.name } { ...field } autoFocus
                                        className={ classNames({ 'p-invalid': fieldState.invalid }) } />
                                ) } />
                        </span>
                        { getFormErrorMessage('name', errors) }
                    </div>
                </div>

                <div className="col-12 mt-3 d-flex justify-content-center py-3 border-top ">
                    <button type="reset" className="btn btn-label-secondary me-3" data-bs-dismiss="modal" aria-label="Close" disabled={ disableBtn ? true : false } onClick={ hideDialog }>Cancel</button>
                    <button href='/campaigns/list' type="submit" className="btn btn-primary me-2" disabled={ disableBtn ? true : false }>
                        { disableBtn ?
                            <Spinner variant="white" as="span" animation="border" size="sm" role="status" className='mx-3' aria-hidden="true" />
                            :
                            "Create"
                        }
                    </button>
                </div>
            </form>
        </Dialog>
    );
};
export default Create;
