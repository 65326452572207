import React from 'react'
import sec2_1 from "../../../assets/img/Landing/sec2-1.png";
import sec2_2 from "../../../assets/img/Landing/sec2-2.png";
import sec2_3 from "../../../assets/img/Landing/sec2-3.png";

function CoreFeature () {
    return (
        <div className="container sec2" id="feature">
            <div className="row">
                <div className="col-md-8">
                    <span className="sec2-span">Core Feature</span>
                    <h2 className="sec2-title mt-4">Easy Software Set Up</h2>
                    <p className="sec2-paragraph mt-4">
                        Simple 3-step installation and you’re ready to operate the system
                        on any hardware.
                    </p>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-md-4 sec2-content">
                    <img src={ sec2_1 } className="img-fluid mb-3" alt="Install POPUPPUSH APP" />
                    <p className="lead">
                        Install POPUPPUSH APP on your device from the PlayStore &amp; copy
                        the generated code
                    </p>
                </div>
                <div className="col-md-4 sec2-content">
                    <img src={ sec2_2 } className="img-fluid mb-3" alt="Login to your account" />
                    <p className="lead">
                        Login to your account on the website or create an account
                    </p>
                </div>
                <div className="col-md-4 sec2-content">
                    <img src={ sec2_3 } className="img-fluid mb-3" alt="Add the device" />
                    <p className="lead">Add the device by entering the generated code!</p>
                </div>
            </div>
        </div>
    )
}

export default CoreFeature