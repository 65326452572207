import React, { useState, useEffect, useRef } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import CampaignCard from "./CampaignCard";
import PlaylistCard from "./PlaylistCard";

import { useGlobalContext } from "../../Contexts/GlobalContext";
import { useCustomToast } from "../../Contexts/ToastContext";
import { onDragEnd } from "../../../config/global";
import axiosInstance from "../../../config/Axios";

const taskStatus = {
    layer: {
        name: "Main layer",
        items: [],
    }
};

function DragDrop ({ data, setOrderLoading, updateStatus }) {
    const { setIsPublished, layer } = useGlobalContext();
    const { showToast } = useCustomToast();

    taskStatus.layer.items = data;
    const [columns, setColumns] = useState(taskStatus);

    const didMountRef = useRef(false);

    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
        } else {
            setOrderLoading(true);
            let pathName = window.location.pathname

            if ((pathName.includes("campaigns") || pathName.includes("campaign")) && columns.layer.items != []) {
                let campaignPlaylistMediaIds = Object.entries(columns.layer.items).map(item => item[1].campaign_playlist_media_id);
                order(
                    "/campaign/content/order",
                    { "campaign_playlist_ids": campaignPlaylistMediaIds ?? [] },
                    "Campaign"
                );
            } else if (columns.layer.items != []) {
                let PlaylistMediaIds = Object.entries(columns.layer.items).map(item => item[1].playlist_media_id);
                order(
                    "/playlist/media/orders",
                    { "playlistmedia_ids": PlaylistMediaIds },
                    "PlayList"
                );
            }
        }
    }, [columns])

    const order = (url, data, type) => {
        axiosInstance.put(url, data)
            .then((result) => {
                setOrderLoading(false);
                setIsPublished(false)
            }).catch((error) => {
                showToast('error', `${ type } Order`, error?.response?.data?.message);
            });

        if (window.location.pathname.includes("playlist"))
            updateStatus("INACTIVE")
        else
            updateStatus("DRAFT")
    }

    return (
        <>
            <div className="d-flex flex-column justify-content-center dragdrop_scroll"  >
                <DragDropContext onDragEnd={ (result) => onDragEnd(result, columns, setColumns) } className="w-100" >
                    { Object.entries(columns).map(([columnId, column], index) => {
                        return (
                            <div className="d-flex flex-column align-items-center" key={ columnId }>
                                <Droppable droppableId={ columnId } key={ columnId }>
                                    { (provided, snapshot) => {
                                        return (
                                            <div { ...provided.droppableProps }
                                                ref={ provided.innerRef }
                                                className="p-3 droppable_container dragdrop_scroll"
                                            >
                                                { column.items.map((item, index) => {
                                                    return (
                                                        <Draggable key={ `${ item.id }-${ index }` } draggableId={ `${ item.id }-${ index }` } index={ index } >
                                                            { (provided, snapshot) => {
                                                                return (
                                                                    <div
                                                                        ref={ provided.innerRef }
                                                                        { ...provided.draggableProps }
                                                                        { ...provided.dragHandleProps }
                                                                        style={ {
                                                                            userSelect: "none",
                                                                            ...provided.draggableProps.style,
                                                                        } }
                                                                        className="draggable_container px-0 px-sm-2 ">

                                                                        { !window.location.pathname.includes("playlist") ?
                                                                            <CampaignCard layer={ layer } updateStatus={ updateStatus } item={ item } />
                                                                            :
                                                                            <PlaylistCard layer={ layer } updateStatus={ updateStatus } item={ item } />
                                                                        }
                                                                    </div>
                                                                );
                                                            } }
                                                        </Draggable>
                                                    );
                                                }) }
                                                { provided.placeholder }
                                            </div>
                                        );
                                    } }
                                </Droppable>
                            </div>
                        );
                    }) }
                </DragDropContext>
            </div>

        </>
    )
}

export default DragDrop