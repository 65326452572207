
import { createContext, useContext, useState } from "react";

const Device = createContext({});

export const DeviceProvider = (props) => {
    const [selectedDevice, setSelectedDevice] = useState({
        id: '',
        serial_name: "",
        online: false,
        name: ""
    });
    const [showAssignForm, setShowAssignForm] = useState(false);
    const [companyCampaigns, setCompanyCampaigns] = useState({});
    const [onlineDevices, setOnlineDevices] = useState({});
    const [isFiltered, setIsFiltered] = useState(false);
    const [rerender, setRerender] = useState(false);
    const [loading, setLoading] = useState(true);
    const [editRotation, setEditRotation] = useState(false);

    // Data table
    const [device, setDevice] = useState({
        name: null,
        serial_name: ""
    });

    // Assign to device dropdown control "campaigns"
    const [selectedCampaign, setSelectedCampaign] = useState("");

    return (
        <Device.Provider value={ {
            companyCampaigns, setCompanyCampaigns,
            selectedCampaign, setSelectedCampaign,
            showAssignForm, setShowAssignForm,
            selectedDevice, setSelectedDevice,
            onlineDevices, setOnlineDevices,
            isFiltered, setIsFiltered,
            rerender, setRerender,
            loading, setLoading,
            device, setDevice,
            
            editRotation, setEditRotation,
        } }>
            { props.children }
        </Device.Provider>
    );
};

export const useDeviceContext = () => {
    return useContext(Device);
};
